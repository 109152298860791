import React, {Component} from 'react';
import {Link} from '@reach/router'
import {CommonMethod} from "./common.service";
import Constant from "../constants";

class ForgotPasswordComponent extends Component {
    state = {
        email: '',
        errors: {
            required: {},
            other: {}
        },
        formName: {
            email: 'Email'
        },
        apiError: null,
        apiSuccess: null,
    };

    Validate() {
        var flag = false;
        for (let key in this.state.formName) {
            if (key) {
                if (!this.state[key]) {
                    const json = {errors: {}};
                    json.errors = this.state.errors;
                    json.errors[key] = this.state.formName[key] + ' is required.';
                    this.setState(json);
                    flag = true;
                }
                if (!this.ValidateEmail(this.state.email)) {
                    const json = {errors: {}};
                    json.errors = this.state.errors;
                    json.errors[key] = 'Email format is incorrect.';
                    this.setState(json);
                    flag = true;
                }
            }
        }
        return flag;
    }

    ForgotMyPassword(event) {
        event.preventDefault();
        if (this.Validate()) {
            return;
        }
        this.ForgotPasswordService(JSON.stringify(this.state)).then((data) => {
            this.setState({
                apiSuccess: data.message
            });
            this.ResetForm();
            console.log(data);
            if (window.ga) {
                window.ga('send', 'event', 'Forgot Password', 'success', 'No-Auth');
            }
        }).catch(err => {
            this.setState({
                apiError: err.message
            });
            console.log(err.message);
            if (window.ga) {
                window.ga('send', 'event', 'Forgot Password', 'error', 'No-Auth');
            }
        });
    }

    ValidateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    ResetForm() {
        this.setState({
            email: ''
        });
    }

    componentDidMount() {
        CommonMethod();
    }

    ChangeEvent(event) {
        const json = {};
        const val = this.CheckErrors(event.target.name, event.target.value);
        if (val) {
            json['errors'] = {}
            json.errors = this.state.errors;
            json.errors[event.target.name] = val[event.target.name];
        } else {
            json['errors'] = {}
            json.errors = this.state.errors;
            json.errors[event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        json[event.target.name] = event.target.value;
        this.setState(json);
    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return {[targetName]: this.state.formName[targetName] + ' is required.'};
        }
    }

    render() {
        return (
            <div className="row">
                <div className="signup-block">
                    <div className="col-md-4"></div>
                    <div className="col-sm-12 col-md-4">
                        <div className="signup-form-cat">
                            <form className="signup-form">
                                {this.state.apiError &&
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.apiError}
                                        </div>
                                    </div>
                                </div>
                                }
                                {this.state.apiSuccess &&
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="alert alert-success" role="alert">
                                            {this.state.apiSuccess}
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="row">
                                    <div className='col-md-12'>
                                        <h2 className='text-center' style={{color: '#004265'}}>Forgot Password</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Email Address <span className='required'>*</span></label>
                                            <input name='email' type="email" placeholder="mail@email.com"
                                                   value={this.state.email}
                                                   onFocus={this.ChangeEvent.bind(this)}
                                                   onChange={this.ChangeEvent.bind(this)}
                                                   className={"form-control" + (this.state.errors.email ? ' error-border' : '')}/>
                                            {this.state.errors.email &&
                                            <span className='error'>{this.state.errors.email}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row center-block">
                                    <div className="col-md-12 col-sm-12 col-xs-12 custombutton">
                                        <button onClick={this.ForgotMyPassword.bind(this)}
                                                className="btn full-button">Forgot Password
                                        </button>
                                    </div>
                                </div>
                                <p className="text-center">Already have an account? &nbsp; &nbsp;
                                    <Link to='/login'>
                                        Login
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

    ForgotPasswordService(data) {
        return fetch(Constant.BASE_URL + '/forgot-password', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: data
        }).then(res => res.json()).then(response => {
            if (response.code >= 200 && response.code < 300) {
                return Promise.resolve(response)
            } else {
                return Promise.reject(new Error(response.error))
            }
        })
            /*.then((data) => {
            this.setState({
                apiSuccess: 'Successfully login'
            });
            console.log(data);
        })*/;
    }
}

export default ForgotPasswordComponent;
