import { Component } from "react";
import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { HTTPRequest } from "../noauth/common.service";
import Constant from "../constants";

class CareTakerManageCareReceiver extends Component {

    constructor(props) {
        super(props)

        this.state = {
            careTakerAccount: {},
            care_receiver: [],
            open: false,
            receiverIndex: '',
            groupedObj: {}
        }
    }

    async componentDidMount() {
        if (!(window.localStorage.getItem('account_details') || window.localStorage.getItem('token'))) {
            window.setTimeout(() => {
                this.props.navigate('/');
            }, 200);
            return;
        }

        var careTakerAccount = JSON.parse(window.localStorage.getItem('account_details'));

        let crArr = careTakerAccount.care_receiver_account;
        for (let i = 0; i < crArr.length; i++) {
            let element = crArr[i];
            let result = await this.fetchCRAccount(element.id)
            let crObj = result.data.care_receiver
            for (let j = 0; j < crArr.length; j++) {
                let cr = crArr[j];
                if (cr.id == element.id) {
                    crObj.selected = cr.selected ? true : false
                    crObj.care_taker = cr.care_taker

                    careTakerAccount.care_receiver_account[i] = crObj
                    window.localStorage.setItem('account_details', JSON.stringify(careTakerAccount));
                }
            }
        }

        if (careTakerAccount.care_receiver_account) {
            let result = careTakerAccount.care_receiver_account.reduce(function (r, a) {
                r[`${a.care_taker.email}`] = r[`${a.care_taker.email}`] || [];
                r[`${a.care_taker.email}`].push(a);
                return r;
            }, Object.create(null));
            this.setState({ groupedObj: result })

            Object.keys(result).map(item => console.log(item))

            for (let i = 0; i < careTakerAccount.care_receiver_account.length; i++) {
                if (careTakerAccount.selected_care_receiver.id === careTakerAccount.care_receiver_account[i].id) {
                    careTakerAccount.care_receiver_account[i].selected = true;
                    break;
                }
            }
            debugger
            this.setState({ care_receiver: careTakerAccount.care_receiver_account, careTakerAccount })
        } else {
            debugger
        }
    }

    fetchCRAccount(cr_id) {
        return HTTPRequest(Constant.BASE_URL + '/care-receiver/' + cr_id, null, 'get', null);
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    };

    handleClose = () => {
        this.setState({
            open: false
        })
    };

    onPressDelete = () => {
        console.log(this.state.receiverIndex);

        if (this.state.careTakerAccount.care_receiver_account.length === 1) {
            window.$('#snackbar').html('Care Receiver can\'t be remove.');
            // Get the snackbar DIV
            var x = document.getElementById("snackbar");
            // Add the "show" class to DIV
            x.className = "show";
            // After 3 seconds, remove the show class from DIV
            setTimeout(() => {
                x.className = x.className.replace("show", "");
            }, 3000);
            this.handleClose();
            return;
        }
        var body = {
            id: this.state.careTakerAccount.care_receiver_account[this.state.receiverIndex].id,
            email: this.state.careTakerAccount.care_receiver_account[this.state.receiverIndex].email
        }
        this.handleClose();
        // this.parent().remove();
        this.DeleteCareReceiverService(JSON.stringify(body)).then((data) => {
            this.setState({
                apiSuccess: data.message
            });
            if (this.state.careTakerAccount.care_receiver_account[this.state.receiverIndex].id === this.state.careTakerAccount.selected_care_receiver.id) {
                if (this.state.careTakerAccount.care_receiver_account.length === 1) {
                    window.$('#snackbar').html('Care Receiver can\'t be remove.');
                    // Get the snackbar DIV
                    var x = document.getElementById("snackbar");
                    // Add the "show" class to DIV
                    x.className = "show";
                    // After 3 seconds, remove the show class from DIV
                    setTimeout(() => {
                        x.className = x.className.replace("show", "");
                    }, 3000);
                    return;
                }
            }
            var careTakerAccount = JSON.parse(window.localStorage.getItem('account_details'));
            careTakerAccount.care_receiver_account = data.data.care_receiver_account;
            var flag = true;
            for (let i = 0; i < careTakerAccount.care_receiver_account.length; i++) {
                if (careTakerAccount.care_receiver_account[i].id === this.state.careTakerAccount.selected_care_receiver.id) {
                    flag = false;
                    careTakerAccount.care_receiver_account[i].selected = true;
                    careTakerAccount.selected_care_receiver = careTakerAccount.care_receiver_account[i];
                    break;
                }
            }
            if (flag) {
                careTakerAccount.care_receiver_account[0].selected = true;
                careTakerAccount.selected_care_receiver = careTakerAccount.care_receiver_account[0];
            }
            window.localStorage.setItem('account_details', JSON.stringify(careTakerAccount));
            this.setState({ care_receiver: careTakerAccount.care_receiver_account, careTakerAccount });
            window.$('#snackbar').html('Care Receiver has been removed.');
            // Get the snackbar DIV
            var x = document.getElementById("snackbar");
            // Add the "show" class to DIV
            x.className = "show";
            // After 3 seconds, remove the show class from DIV
            setTimeout(() => {
                x.className = x.className.replace("show", "");
            }, 3000);
        });
    };

    fetchNameOfCA(email) {
        debugger
        let crArr = this.state.care_receiver;
        let ca = crArr.filter(item => item.care_taker.email == email)
        if (ca.length > 0) {
            return `${ca[0].care_taker.firstName} ${ca[0].care_taker.lastName}`
        }
        return ""
    }

    fetchEmailOfCA(email) {
        let crArr = this.state.care_receiver;

        let ca = crArr.filter(item => item.care_taker.email == email)
        if (ca.length > 0) {
            return ca[0].care_taker.email
        }
        return ""
    }

    fetchNumberOfCA(email) {
        let crArr = this.state.care_receiver;
        let ca = crArr.filter(item => item.care_taker.email == email)
        if (ca.length > 0) {
            return ca[0].care_taker.phoneNumber
        }
        return ""
    }

    ChangeCareReceiver(receiverIndex, email, event) {
        debugger
        let num = 0
        for (let i = 0; i < this.state.careTakerAccount.care_receiver_account.length; i++) {
            if (this.state.careTakerAccount.care_receiver_account[i].email !== email) {
                this.state.careTakerAccount.care_receiver_account[i].selected = false;
            } else {
                this.state.careTakerAccount.care_receiver_account[i].selected = true;
                num = i
            }
        }
        // this.state.careTakerAccount.care_receiver_account[num].selected = true;
        this.state.careTakerAccount.selected_care_receiver = this.state.careTakerAccount.care_receiver_account[num];
        this.setState({
            careTakerAccount: this.state.careTakerAccount,
            care_receiver: this.state.careTakerAccount.care_receiver_account
        });
        window.localStorage.setItem('account_details', JSON.stringify(this.state.careTakerAccount));
    }

    EditCareReceiver(receiverIndex, email, event) {

        for (let i = 0; i < this.state.careTakerAccount.care_receiver_account.length; i++) {
            if (this.state.careTakerAccount.care_receiver_account[i].email === email) {
                this.state.careTakerAccount.editable_care_receiver = this.state.careTakerAccount.care_receiver_account[i];
                this.setState({
                    careTakerAccount: this.state.careTakerAccount,
                    care_receiver: this.state.careTakerAccount.care_receiver_account,
                    page: 'edit-care-receiver'
                });
                window.localStorage.setItem('account_details', JSON.stringify(this.state.careTakerAccount));
                this.props.navigate('/auth/care-admin/edit-care-receiver');
                break;
            }
        }
    }

    DeleteCareReceiver(receiverIndex, event) {
        this.setState({
            open: true,
            receiverIndex: receiverIndex
        });
    }

    DeleteCareReceiverService(data) {
        return HTTPRequest(Constant.BASE_URL + '/care-taker/delete-receiver', 'application/json; charset=UTF-8', 'delete', data);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="notes-block">
                        {/*<h3 className="card-title">Task List</h3>*/}
                        <div className="">
                            <div className="">
                                <div className="contents row sep10"></div>
                                {this.state.apiError &&
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.apiError}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.apiSuccess &&
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className="alert alert-success" role="alert">
                                                {this.state.apiSuccess}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {false && this.state.care_receiver.map((data, index) => {
                                    return (
                                        <a className="contents col-md-4 col-sm-12 col-xs-12">
                                            <div className="" key={index}>
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    {data.nickName}
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-xs-12" style={{ marginTop: 2 }}>
                                                    <span className="notes-text">{data.firstName} {data.lastName}</span>
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-xs-12" style={{ marginTop: 2 }}>
                                                    <span className="notes-text">{data.email}</span>
                                                </div>
                                            </div>
                                        </a>
                                    )
                                })}
                                {Object.keys(this.state.groupedObj).map(item => {
                                    return (
                                        <>
                                            <p style={{ marginTop: 20 }}>
                                                Care Giver Name: <span style={{ fontWeight: '900' }}>{this.fetchNameOfCA(item)}</span>
                                                <a
                                                    style={{
                                                        border: '1px solid #8bc34a',
                                                        width: 100,
                                                        marginLeft: 10,
                                                        padding: 5,
                                                        color: '#689f38',
                                                        borderRadius: 5,
                                                        cursor: 'pointer',
                                                    }}
                                                    href={`mailto:${this.fetchEmailOfCA(item)}`}
                                                >
                                                    Email
                                                <i
                                                        className="fa fa-envelope-o"
                                                        style={{
                                                            color: '#689f38',
                                                            marginLeft: 10
                                                        }}
                                                    />
                                                </a>
                                                <a
                                                    style={{
                                                        border: '1px solid #ffa000',
                                                        width: 100,
                                                        marginLeft: 10,
                                                        padding: 5,
                                                        color: '#ffa000',
                                                        borderRadius: 5,
                                                        cursor: 'pointer',
                                                    }}
                                                    href={`tel:${this.fetchNumberOfCA(item)}`}
                                                >
                                                    Call
                                                <i
                                                        className="fa fa-phone"
                                                        style={{
                                                            color: '#ffa000',
                                                            marginLeft: 10
                                                        }}
                                                    />
                                                </a>
                                            </p>
                                            {this.state.groupedObj[item].map((data, index) => {
                                                debugger
                                                return (
                                                    <div key={index} onClick={this.ChangeCareReceiver.bind(this, index, data.email)} style={{ cursor: 'pointer' }}>

                                                        <div className="contents row" style={data.selected ? {
                                                            paddingBottom: 12,
                                                            background: '#f1f8fb',
                                                            border: "1px solid #00acde",
                                                        } : { paddingBottom: 12 }}>
                                                            {/*<button className="button-null-style"
                                                                onClick={this.ChangeCareReceiver.bind(this, index)}
                                                                style={{color: 'inherit'}}>*/}
                                                            <div onClick={this.ChangeCareReceiver.bind(this, index, data.email)}
                                                                className="col-md-3 col-sm-12 col-xs-12"
                                                                style={{ paddingTop: 12, cursor: 'pointer' }}>
                                                                {data.nickName}

                                                            </div>

                                                            <div onClick={this.ChangeCareReceiver.bind(this, index, data.email)}
                                                                className="col-md-3 col-sm-12 col-xs-12"
                                                                style={{ marginTop: 2, paddingTop: 12, cursor: 'pointer' }}>
                                                                <span
                                                                    className="notes-text">{data.firstName} {data.lastName}</span>
                                                            </div>
                                                            <div onClick={this.ChangeCareReceiver.bind(this, index, data.email)}
                                                                className="col-md-3 col-sm-12 col-xs-12"
                                                                style={{
                                                                    marginTop: 2,
                                                                    paddingTop: 12,
                                                                    paddingBottom: 6,
                                                                    cursor: 'pointer'
                                                                }}>
                                                                <span className="notes-text">{data.email}</span>
                                                            </div>
                                                            {/* </button>*/}

                                                            <div className="col-md-3 col-sm-12 col-xs-12"
                                                                style={{ marginTop: 2, paddingTop: 12, paddingBottom: 6 }}>
                                                                <button className="btn-remove-bg"
                                                                    onClick={this.EditCareReceiver.bind(this, index, data.email)}><img
                                                                        className="notes-img" src='/assets/images/edit.png' />Edit
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        {/* <button
                                                                    className={this.state.careTakerAccount.care_receiver_account.length === 1 ? "btn-remove-bg" : "btn-remove-bg"}
                                                                    // disabled={this.state.careTakerAccount.care_receiver_account.length === 1}
                                                                    onClick={() => this.props.navigate('/auth/care-admin/cr-profile-setup')}
                                                                >
                                                                    <i class="fa fa-cog" style={{ marginRight: 5, color: '#a2a2a2', fontSize: 16, marginTop: 2 }}></i>Manage
                                                        </button> */}
                                                                <button className="btn-remove-bg"
                                                                    onClick={() => this.props.navigate('/auth/care-admin/cr-profile-setup')}

                                                                >
                                                                    <img
                                                                        className="notes-img" src='/assets/images/settings.png' />Manage
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })}
                                        </>



                                    )
                                })}
                                {
                                    this.state.care_receiver.length === 0 && <div className="text-center">
                                        No care receivers added yet.
                                    </div>
                                }
                                <div className="contents row sep10"></div>
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose.bind(this)}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Confirm delete
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose.bind(this)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.onPressDelete.bind(this)} color="primary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default CareTakerManageCareReceiver;
