exports.CommonMethod = () => {
    if (window.$) {
        window.$('.cloading').fadeIn();
        window.$('body, html').animate({
            scrollTop: window.$('body').offset().top
        }, 1000);
        setTimeout(() => {
            window.$('.cloading').fadeOut();
        }, 200);
    } else {
        setTimeout(() => {
            window.$('body, html').animate({
                scrollTop: window.$('body').offset().top
            }, 1000);
        }, 1000);
    }
}

exports.HTTPRequest = (url, contentType, methodType, data) => {
    window.$('.cloading').fadeIn();
    var header = {}
    if (contentType) {
        header['Content-type'] = contentType
    }
    if (window.localStorage.getItem('token')) {
        header['Authorization'] = window.localStorage.getItem('token')
    }
    return fetch(url, {
        method: methodType,
        headers: header,
        body: data
    }).then(res => res.json()).then(response => {
        window.$('.cloading').fadeOut();
        
        if (response.code) {
            if (response.code === 402 || (response.code >= 200 && response.code < 300)) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response && response.error ? response.error: response);
            }
        } else {
            return Promise.resolve(response);
        }
    }).catch(e => {
        window.$('.cloading').fadeOut();
        return Promise.reject(e);
    });
}

exports.AXIOS = (url, contentType, methodType, data, auth) => {
    window.$('.cloading').fadeIn();
    var header = {}
    if (contentType) {
        header['Content-type'] = contentType
    }
    if (window.localStorage.getItem('token')) {
        header['Authorization'] = auth
    }
    return fetch(
        url,
        {
            method: methodType,
            headers: header,
            body: data
        }).then(res => res.json()).then(response => {
            window.$('.cloading').fadeOut();
            if (response.code) {
                if (response.code >= 200 && response.code < 300) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            } else {
                return Promise.resolve(response);
            }
        })
        .catch(e => {
            window.$('.cloading').fadeOut();
            return Promise.reject(JSON.stringify(e));
        });
}
