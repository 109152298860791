import React, {Component} from 'react'
import {AXIOS} from "../noauth/common.service"
import constant from '../constants';

export class CareReceiverHealthComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isSignedIn: false,
            userId: '',
            access_token: '',
            refresh_token: '',
            base64Value: '',
            fitBitAuthCode: '',
            heartrate: [],
            activitySummary: [],
            // sleepData: [],
            foodIntakeData: [],
            todayDate: '',
            alreadyLoggedInWithFitBit: false,
            calories: '-',
            footSteps: '-',
            floorSteps: '-',
            sleepData: '-',
            milesData: '-',
            userType: '',
            account_details: {}
        }
    }

    parseQueryString = () => {

        var str = window.location.search;
        var objURL = {};

        str.replace(
            new RegExp("([^?=&]+)(=([^&]*))?", "g"),
            function ($0, $1, $2, $3) {
                objURL[$1] = $3;
            }
        );

        return objURL;
    };

    async componentDidMount() {
        if (!window.localStorage.getItem('token')) {
            window.setTimeout(() => {
                this.props.navigate('/');
            }, 200);
            return;
        }

        // get today's date
        var date = new Date();
        var todayDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        this.state.todayDate = todayDate;
        var is_old_token_exist = false;

        

        if (window.localStorage.getItem('account_details')) {

            var account_details = window.localStorage.getItem('account_details');
            account_details = JSON.parse(window.localStorage.getItem('account_details'))

            // check user type
            if (account_details.account_type) {

                this.setState({
                    userType: account_details.account_type,
                    account_details: account_details
                });

                // care taker
                if (account_details.account_type === 'Care Taker') {
                    if (account_details.selected_care_receiver.fitbit_refresh_token) {

                        is_old_token_exist = true;
                        var old_refresh_token = account_details.selected_care_receiver.fitbit_refresh_token;
                    } else {
                        
                        await this.getFitBitData();
                        return
                    }
                } else if (account_details.account_type === 'Care Receiver') {
                    // care receiever
                    if (account_details.fitbit_refresh_token) {
                        var old_refresh_token = account_details.fitbit_refresh_token;
                        is_old_token_exist = true;
                    }
                }
            }

            // check if fit-bit refresh token exist?
            if (is_old_token_exist) {

                this.state.fitBitAuthCode = old_refresh_token
                this.state.alreadyLoggedInWithFitBit = true;

                this.setState({
                    isSignedIn: true,
                });

                // get data from fit bit backend
                console.log('token already exist')
                await this.getFitBitData();

            } else {
                

                console.log('generating new token')

                this.setState({
                    isSignedIn: false
                });

                // get new token
                var url = window.location.href;
                if (this.state.fitBitAuthCode === '' && url.indexOf('code') > -1) {
                    

                    var encodedData = window.btoa(constant.CLIENT_ID + ":" + constant.CLIENT_SECRET); // encode a string
                    var param = this.parseQueryString();

                    this.setState({
                        isSignedIn: true
                    })

                    this.state.encodedData = encodedData;
                    this.state.fitBitAuthCode = param.code;
                    this.state.alreadyLoggedInWithFitBit = false;

                    await this.getToken();
                    await this.getFitBitData();
                }
            }
        }
    }

    getToken = async () => {
        var url = 'https://api.fitbit.com/oauth2/token';
        var body = "clientId=" + constant.CLIENT_ID + "&grant_type=authorization_code&code=" + this.state.fitBitAuthCode + "&redirect_uri=" + constant.FIT_BIT_REDIRECT_URL

        try {
            var getToken = await AXIOS(url, 'application/x-www-form-urlencoded', 'post', body, 'Basic ' + this.state.encodedData);

            if (getToken) {
                if (getToken.errors) {
                    console.log('error occured!')
                    return;
                }

                console.log('new refresh token => ', getToken.refresh_token)

                this.setState({
                    access_token: getToken.access_token,
                    fitBitAuthCode: getToken.refresh_token
                });

            }
        } catch (e) {
            console.log('something went wrong while generating new token!')
        }
    }

    getFitBitData = async () => {

        var careTakerAccount = JSON.parse(window.localStorage.getItem('account_details'));

        var url = constant.BASE_URL + '/fit-bit-data';
        var token = window.localStorage.getItem('token');

        
        if (careTakerAccount.account_type === 'Care Taker') {
            var careReceiverId = careTakerAccount.selected_care_receiver.id
        } else {
            var careReceiverId = careTakerAccount.id
        }

        var body = {
            cr_id: careReceiverId,
            refresh_token: this.state.fitBitAuthCode,
            todayDate: this.state.todayDate
        }

        try {
            var getFitBitData = await AXIOS(url, 'application/json', 'post', JSON.stringify(body), token);

            if (getFitBitData) {
                if (getFitBitData.errors) {
                    
                    return;
                }
                ;

                careTakerAccount.fitbit_refresh_token = getFitBitData.data.refresh_token;
                window.localStorage.setItem('account_details', JSON.stringify(careTakerAccount));

                let caloriesOut = 0;
                let walkSteps = 0;
                let floorSteps = '-';
                let sleepData = '-';
                let distance = 0;

                if (getFitBitData.data.activity_data.summary) {
                    try {
                        sleepData = getFitBitData.data.sleep_data.summary.totalSleepRecords;
                    } catch (e) {
                        sleepData = '-'
                    }
                    caloriesOut = getFitBitData.data.activity_data.summary.caloriesOut;
                    distance = (getFitBitData.data.activity_data.summary.distances[0].distance) * 0.621371;
                    walkSteps = getFitBitData.data.activity_data.summary.steps;
                    if (getFitBitData.data.activity_data.summary.floors) {
                        floorSteps = getFitBitData.data.activity_data.summary.floors
                    }

                    this.setState({
                        calories: caloriesOut,
                        footSteps: walkSteps,
                        floorSteps: floorSteps,
                        sleepData: sleepData,
                        milesData: distance,
                        isSignedIn: true,
                    });
                }
            }
        } catch (e) {
        }
    }

    render() {

        const {isSignedIn, calories, footSteps, floorSteps, sleepData, milesData, userType} = this.state;

        if (!isSignedIn) {
            if (userType === "Care Taker") {

                return (
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center" style={{
                            marginRight: 7,
                            marginLeft: 7,
                            marginTop: 25,
                            marginBottom: 10,
                            paddingBottom: 30,
                            paddingTop: 30,
                        }}>
                            <p>Care receiver have not sync Fit Bit account</p>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center" style={{
                            marginRight: 7,
                            marginLeft: 7,
                            marginTop: 25,
                            marginBottom: 10,
                            paddingBottom: 30,
                            paddingTop: 30,
                        }}>
                            <button
                                onClick={() => {
                                    window.open(
                                        constant.FIT_BIT_BUTTON_LINK, "_self")
                                }}
                                style={{marginTop: 10}}
                                className={"btn-margin12 createTaskButton text-uppercase" + (window.innerWidth < 450 ? " createTaskButton-special-iphone567" : "")}>
                                Sync data from Fit Bit
                            </button>
                        </div>
                    </div>
                )
            }

        } else {
            return (
                <>
                    {/* footsteps */}
                    <div className="row activityindicatorcontainerstyle">
                        <div className="col-lg-5 col-md-4 col-sm-4 col-xs-4"></div>

                        <div className="col-lg-2 col-md-4 col-sm-4 col-xs-4 text-center">
                            <div className="activityiconstyle">
                                <img className="img-responsive" src="/assets/images/Health_heart.png"/>
                            </div>
                            <div className="footStepTextContainerStyle">
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-4 col-sm-4 col-xs-4"></div>
                    </div>

                    {/* health activity indicator */}
                    <div className="row activityindicatorcontainerstyle">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2 col-sm-3 col-xs-3 text-center">
                            <div className="activityiconstyle">
                                <div><img src="/assets/images/step_gray@3x.png" className="img-responsive"/></div>
                                <div className="activityindicatorvaluestyle">
                                    {footSteps}
                                </div>
                                <div className="activityindicatorsubvaluestyle">
                                    Steps
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-3 col-xs-3 text-center">
                            <div className="activityiconstyle">
                                <div>
                                    <img src="/assets/images/sleep_grey@3x.png" className="img-responsive"/>
                                </div>
                                <div className="activityindicatorvaluestyle">
                                    {sleepData}
                                </div>
                                <div className="activityindicatorsubvaluestyle">
                                    Sleep
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-3 col-xs-3 text-center">
                            <div className="activityiconstyle">
                                <div><img src="/assets/images/miles@3x.png" className="img-responsive"/></div>
                                <div className="activityindicatorvaluestyle">
                                    {milesData === '-' ? milesData : milesData.toFixed(2)}
                                </div>
                                <div className="activityindicatorsubvaluestyle">
                                    Miles
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-3 col-xs-3 text-center">
                            <div className="activityiconstyle">
                                <div><img src="/assets/images/cal@3x.png" className="img-responsive"/></div>
                                <div className="activityindicatorvaluestyle">
                                    {calories}
                                </div>
                                <div className="activityindicatorsubvaluestyle">
                                    Cals
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>

                    {/* other activity indicator */}
                    <div className="row activityindicatorcontainerstyle">
                        <div className="col-lg-3"></div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-center ">
                            <div>
                                <img src="/assets/images/exercises@3x.png" className="otherActivityImageStyle"/>
                            </div>
                            <div>
                                2 or 4 Day
                            </div>
                            <div>
                                Weekly Exercise
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-center ">
                            <div>
                                <img src="/assets/images/sleep@3x.png" className="otherActivityImageStyle"/>
                            </div>
                            <div>
                                2 or 4 Day
                            </div>
                            <div>
                                Weekly Exercise
                            </div>
                        </div>
                        <div className="col-lg-3"></div>

                    </div>

                    <div className="row activityindicatorcontainerstyle" style={{marginBottom: 40}}>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-center ">
                            <div>
                                <img src="/assets/images/resting@3x.png" className="otherActivityImageStyle"/>
                            </div>
                            <div>
                                2 or 4 Day
                            </div>
                            <div>
                                Weekly Exercise
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-center">
                            <div>
                                <img src="/assets/images/step_hours@3x.png" className="otherActivityImageStyle"/>
                            </div>
                            <div>
                                2 or 4 Day
                            </div>
                            <div>
                                Weekly Exercise
                            </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </>
            )
        }
    }
}

export default CareReceiverHealthComponent;
