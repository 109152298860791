import React, {Component} from 'react';
import {Link} from '@reach/router'
import {CommonMethod} from "./common.service";
import Constant from "../constants";

class ResetPasswordComponent extends Component {
    state = {
        password: '',
        confirm_password: '',
        errors: {
        },
        formName: {
            password: 'Password',
            confirm_password: 'Confirm Password',
        },
        apiError: null,
        apiSuccess: null,
    };

    ResetForm() {
        this.setState({
            confirm_password: '',
            password: ''
        });
    }

    Validate() {
        var flag = false;
        for (let key in this.state.formName) {
            if (key) {
                if (!this.state[key]) {
                    const json = {errors: {}};
                    json.errors.required = this.state.errors.required;
                    json.errors.required[key] = this.state.formName[key] + ' is required.';
                    this.setState(json);
                    flag = true;
                }
            }
        }
        return flag;
    }

    ResetMyPassword(event) {
        event.preventDefault();
        if (this.Validate()) {
            return;
        }
        var body = {
            confirm_password: this.state.confirm_password,
            password: this.state.password,
            code: this.state.code,
        }
        this.ResetPasswordService(JSON.stringify(body)).then((data) => {
            this.setState({
                apiSuccess: data.message
            });
            this.ResetForm();
            console.log(data);
            if (window.ga) {
                window.ga('send', 'event', 'Reset password', 'success', 'No-Auth');
            }
        }).catch(err => {
            this.setState({
                apiError: err.message
            });
            console.log(err.message);
            if (window.ga) {
                window.ga('send', 'event', 'Add Care reciver', 'error', 'No-Auth');
            }
        });
    }

    componentDidMount() {
        console.log(this.props.id)
        this.setState({
            code: this.props.id
        });
        CommonMethod();
    }

    ChangeEvent(event) {
        const json = {};
        const val = this.CheckErrors(event.target.name, event.target.value);
        if (val) {
            json['errors'] = {}
            json.errors = this.state.errors;
            json.errors[event.target.name] = val[event.target.name];
        } else {
            json['errors'] = {}
            json.errors = this.state.errors;
            json.errors[event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        json[event.target.name] = event.target.value;
        this.setState(json);
    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return {[targetName]: this.state.formName[targetName] + ' is required.'};
        }
    }

    render() {
        return (
            <div className="row">
                <div className="signup-block">
                    <div className="col-md-4"></div>
                    <div className="col-sm-12 col-md-4">
                        <div className="signup-form-cat">
                            <form className="signup-form">
                                {this.state.apiError &&
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.apiError}
                                        </div>
                                    </div>
                                </div>
                                }
                                {this.state.apiSuccess &&
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="alert alert-success" role="alert">
                                            {this.state.apiSuccess}
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="row">
                                    <div className='col-md-12'>
                                        <h2 className='text-center' style={{color: '#004265'}}>Reset Password</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Password <span className='required'>*</span></label>
                                            <input name='password' type="password" placeholder="Password"
                                                   value={this.state.password}
                                                   onFocus={this.ChangeEvent.bind(this)}
                                                   onChange={this.ChangeEvent.bind(this)} className={"form-control" + (this.state.errors.password ? ' error-border' : '')} />
                                            {this.state.errors.password &&
                                            <span className='error'>{this.state.errors.password}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Confirm Password <span className='required'>*</span></label>
                                            <input name='confirm_password' type="password" placeholder="Confirm Password"
                                                   value={this.state.confirm_password}
                                                   onFocus={this.ChangeEvent.bind(this)}
                                                   onChange={this.ChangeEvent.bind(this)} className={"form-control" + (this.state.errors.confirm_password ? ' error-border' : '')} />
                                            {this.state.errors.confirm_password &&
                                            <span className='error'>{this.state.errors.confirm_password}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row center-block">
                                    <div className="col-md-12 col-sm-12 col-xs-12 custombutton">
                                        <button onClick={this.ResetMyPassword.bind(this)}
                                                className="btn full-button">Reset Password
                                        </button>
                                    </div>
                                </div>
                                <p className="text-center">New to Sahaaya?
                                    <Link to='/signup'>
                                        Register New Account
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

    ResetPasswordService(data) {
        return fetch(Constant.BASE_URL + '/reset-password', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: data
        }).then(res => res.json()).then(response => {
            if (response.code >= 200 && response.code < 300) {
                return Promise.resolve(response)
            } else {
                return Promise.reject(new Error(response.error))
            }
        })
            /*.then((data) => {
            this.setState({
                apiSuccess: 'Successfully login'
            });
            console.log(data);
        })*/;
    }
}

export default ResetPasswordComponent;
