import React from 'react';
import './App.css';
import { Router } from '@reach/router'
import NoAuthTemplateComponent from "./components/noauth/template/no.auth.template.component";
import LoginComponent from "./components/noauth/login.component";
import SignupComponent from "./components/noauth/signup.component";
import SignupCareAdminComponent from "./components/noauth/signup.care.admin.component";
import AddCareReceiverComponent from "./components/noauth/add.care.receiver.component";
import PaymentComponent from "./components/noauth/payment.component";
import ResetPasswordComponent from "./components/noauth/reset.password.component";
import TrustedCircleComponent from "./components/noauth/trusted.circle.component";
import ForgotPasswordComponent from "./components/noauth/forgot.password.component";
// import {Elements, StripeProvider} from 'react-stripe-elements';
import AuthTemplateComponent from "./components/auth/template/auth.template.component";
import DashboardComponent from "./components/auth/care.receiver.dashboard.component";
import CareTakerDashboardComponent from "./components/auth/care.taker.dashboard";
import CareAdminDashboardComponent from "./components/auth/care.admin.dashboard.component";
import CareTakerInsightComponent from "./components/auth/care.taker.insight";
import CareTakerListCareReceiver from "./components/auth/care.taker.list.care.receiver";
import CareAdminListCareReceiver from "./components/auth/care.admin.list.care.receiver";
import CareTakerManageCareReceiver from "./components/auth/care.taker.manage.care.receiver";
import AddCareReceiverInsideComponent from "./components/auth/add.care.receiver.inside.component";
import EditCareReceiverInsideComponent from "./components/auth/edit.care.receiver.inside.component";
import CAEditCareReceiverInsideComponent from "./components/auth/ca.edit.care.receiver.inside.component";
import CareReceiverHealthComponent from './components/auth/care.receiver.health.component';
import CareReceiverInsightComponent from './components/auth/care.receiver.insight'
import EditProfileComponent from './components/auth/edit.profile';
import ViewProfileComponent from './components/auth/view.profile';
import ProgressiveDashboardComponent from './components/auth/progressive.dashboard.component';
import CareReceiverMedMinderComponent from './components/auth/care.receiver.med.minder';
import ProfileSetup from "./components/noauth/profile.setup";
import ProfileSetupCA from "./components/noauth/profile.setupCA";
import CgProfileSetup from "./components/noauth/cg.profile.setup";
import ProfileAssignCA from './components/noauth/ProfileAssignCA';
import ProfileSetupCT from './components/noauth/profile.setupCT';
import CAAssignCRByCG from './components/noauth/CAAssignCRByCG';

function App() {
    return (
        <div>
            <div className="cloading">Loading</div>
            <div className="cloading anotherloder">Loading</div>
            {/*<StripeProvider apiKey="pk_test_tozHXXW4kZ2f4z0dV34qKFIp003I1YvN11">*/}
            {/*<StripeProvider apiKey="pk_test_7n8v6oeBW81ksiy4MiuZllLX00W2rrOvka">*/}
            {/*<Elements>*/}
            <Router>
                <NoAuthTemplateComponent path='/'>
                    <LoginComponent path='/' />
                    <LoginComponent path='/login' />
                    <SignupComponent path='/signup/:orderId' />
                    <SignupCareAdminComponent path='/signup-care-admin' />
                    <AddCareReceiverComponent path='/add-care-receiver' />
                    <CgProfileSetup path='/profile-setup' />
                    <ProfileSetup path='/cr-profile-setup' />
                    <ProfileAssignCA path='/cr-add-ca' />
                    <PaymentComponent path='/payment' />
                    <ResetPasswordComponent path='/reset-password/:id' />
                    <ForgotPasswordComponent path='/forgot-password' />
                    <TrustedCircleComponent path='/trusted-circle/:trusted_circle_id/:care_receiver_id' />
                </NoAuthTemplateComponent>
                <AuthTemplateComponent path='/auth'>
                    <DashboardComponent path='/dashboard' />
                    <CareReceiverHealthComponent path='/health' />
                    <EditProfileComponent path='/edit/profile' />
                    <ViewProfileComponent path='/view/profile' />
                    <ProgressiveDashboardComponent path='/progressive-dashboard' />
                    <CareReceiverMedMinderComponent path='/med-minder' />
                    <AddCareReceiverInsideComponent path='/care-taker/add-care-receiver' />
                    <EditCareReceiverInsideComponent path='/care-taker/edit-care-receiver' />
                    <CAEditCareReceiverInsideComponent path='/care-admin/edit-care-receiver' />
                    <CareTakerDashboardComponent path='/care-taker/dashboard' />
                    <CareAdminDashboardComponent path='/care-admin/dashboard' />
                    <CareTakerInsightComponent path='/care-taker/insight' />
                    <CareReceiverInsightComponent path='/care-receiver/insight' />
                    <CareTakerListCareReceiver path='/care-taker/care-receivers' />
                    <CareAdminListCareReceiver path='/care-admin/care-receivers' />
                    <CareTakerManageCareReceiver path='/care-admin/manage-care-receivers' />
                    <PaymentComponent path='/care-taker/payment' />
                    <ProfileSetupCA path='/care-admin/cr-profile-setup' />
                    <ProfileSetupCT path='/care-taker/cr-profile-setup' />
                    <CAAssignCRByCG path='/care-taker/choose-ca' />
                    {/*<CareReceiverInsightComponent path='/care-receiver/insight'/>*/}
                </AuthTemplateComponent>
            </Router>
            {/*</Elements>*/}
            {/*</StripeProvider>*/}
        </div>
    );
}

export default App;
