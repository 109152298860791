import { Component } from "react";
import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { HTTPRequest } from "../noauth/common.service";
import Constant from "../constants";


class CareTakerListCareReceiver extends Component {


    state = {
        careTakerAccount: {},
        care_receiver: [],
        open: false,
        receiverIndex: ''
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    };

    handleClose = () => {
        this.setState({
            open: false
        })
    };

    onPressDelete = () => {
        console.log(this.state.receiverIndex);

        if (this.state.careTakerAccount.care_receiver_account.length === 1) {
            window.$('#snackbar').html('Care Receiver can\'t be remove.');
            // Get the snackbar DIV
            var x = document.getElementById("snackbar");
            // Add the "show" class to DIV
            x.className = "show";
            // After 3 seconds, remove the show class from DIV
            setTimeout(() => {
                x.className = x.className.replace("show", "");
            }, 3000);
            this.handleClose();
            return;
        }
        var body = {
            id: this.state.careTakerAccount.care_receiver_account[this.state.receiverIndex].id,
            email: this.state.careTakerAccount.care_receiver_account[this.state.receiverIndex].email
        }
        console.log("data to delete", JSON.stringify(body));
        this.handleClose();
        // this.parent().remove();
        this.DeleteCareReceiverService(JSON.stringify(body)).then((data) => {
            this.setState({
                apiSuccess: data.message
            });
            if (this.state.careTakerAccount.care_receiver_account[this.state.receiverIndex].id === this.state.careTakerAccount.selected_care_receiver.id) {
                if (this.state.careTakerAccount.care_receiver_account.length === 1) {
                    window.$('#snackbar').html('Care Receiver can\'t be remove.');
                    // Get the snackbar DIV
                    var x = document.getElementById("snackbar");
                    // Add the "show" class to DIV
                    x.className = "show";
                    // After 3 seconds, remove the show class from DIV
                    setTimeout(() => {
                        x.className = x.className.replace("show", "");
                    }, 3000);
                    return;
                }
            }
            var careTakerAccount = JSON.parse(window.localStorage.getItem('account_details'));
            careTakerAccount.care_receiver_account = data.data.care_receiver_account;
            var flag = true;
            for (let i = 0; i < careTakerAccount.care_receiver_account.length; i++) {
                if (careTakerAccount.care_receiver_account[i].id === this.state.careTakerAccount.selected_care_receiver.id) {
                    flag = false;
                    careTakerAccount.care_receiver_account[i].selected = true;
                    careTakerAccount.selected_care_receiver = careTakerAccount.care_receiver_account[i];
                    break;
                }
            }
            if (flag) {
                careTakerAccount.care_receiver_account[0].selected = true;
                careTakerAccount.selected_care_receiver = careTakerAccount.care_receiver_account[0];
            }
            window.localStorage.setItem('account_details', JSON.stringify(careTakerAccount));
            this.setState({ care_receiver: careTakerAccount.care_receiver_account, careTakerAccount });
            window.$('#snackbar').html('Care Receiver has been removed.');
            // Get the snackbar DIV
            var x = document.getElementById("snackbar");
            // Add the "show" class to DIV
            x.className = "show";
            // After 3 seconds, remove the show class from DIV
            setTimeout(() => {
                x.className = x.className.replace("show", "");
            }, 3000);
        });
    };

    async componentDidMount() {
        //;
        try {
            if (!(window.localStorage.getItem('account_details') || window.localStorage.getItem('token'))) {
                window.setTimeout(() => {
                    this.props.navigate('/');
                }, 200);
                return;
            }

            var careTakerAccount = JSON.parse(window.localStorage.getItem('account_details'));
            for (let i = 0; i < careTakerAccount.care_receiver_account.length; i++) {
                let body = {
                    cr_id: careTakerAccount.care_receiver_account[i].id
                }
                let result = await this.fetchCAForCR(JSON.stringify(body))
                if (result.code == 200) {
                    careTakerAccount.care_receiver_account[i] = result.data
                }
                if (careTakerAccount.selected_care_receiver.id === careTakerAccount.care_receiver_account[i].id) {
                    careTakerAccount.care_receiver_account[i].selected = true;
                }

            }
            this.setState({ care_receiver: careTakerAccount.care_receiver_account, careTakerAccount })
        } catch (e) {
            window.$('#snackbar').html(e ? e.message : 'Something went wrong!');
            // Get the snackbar DIV
            var x = document.getElementById("snackbar");
            // Add the "show" class to DIV
            x.className = "show";
            // After 3 seconds, remove the show class from DIV
            setTimeout(() => {
                x.className = x.className.replace("show", "");
            }, 3000);
        }
    }

    ChangeCareReceiver(receiverIndex, event) {

        for (let i = 0; i < this.state.careTakerAccount.care_receiver_account.length; i++) {
            if (this.state.careTakerAccount.selected_care_receiver.id === this.state.careTakerAccount.care_receiver_account[i].id) {
                this.state.careTakerAccount.care_receiver_account[i].selected = false;
            }
        }
        this.state.careTakerAccount.care_receiver_account[receiverIndex].selected = true;
        this.state.careTakerAccount.selected_care_receiver = this.state.careTakerAccount.care_receiver_account[receiverIndex];
        this.setState({
            careTakerAccount: this.state.careTakerAccount,
            care_receiver: this.state.careTakerAccount.care_receiver_account
        });
        window.localStorage.setItem('account_details', JSON.stringify(this.state.careTakerAccount));
    }

    EditCareReceiver(receiverIndex, event) {

        this.state.careTakerAccount.editable_care_receiver = this.state.careTakerAccount.care_receiver_account[receiverIndex];
        this.setState({
            careTakerAccount: this.state.careTakerAccount,
            care_receiver: this.state.careTakerAccount.care_receiver_account,
            page: 'edit-care-receiver'
        });
        window.localStorage.setItem('account_details', JSON.stringify(this.state.careTakerAccount));
        this.props.navigate('/auth/care-taker/edit-care-receiver');
    }

    DeleteCareReceiver(receiverIndex, event) {
        this.setState({
            open: true,
            receiverIndex: receiverIndex
        });
    }

    DeleteCareReceiverService(data) {
        return HTTPRequest(Constant.BASE_URL + '/care-taker/delete-receiver', 'application/json; charset=UTF-8', 'delete', data);
    }

    fetchCAForCR(data) {
        return HTTPRequest(Constant.BASE_URL + '/care-receiver/care-admin', 'application/json; charset=UTF-8', 'post', data);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="notes-block">
                        {/*<h3 className="card-title">Task List</h3>*/}
                        <div className="">
                            <div className="">
                                <div className="contents row sep10"></div>
                                {this.state.apiError &&
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.apiError}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.apiSuccess &&
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className="alert alert-success" role="alert">
                                                {this.state.apiSuccess}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {false && this.state.care_receiver.map((data, index) => {
                                    return (
                                        <a className="contents col-md-4 col-sm-12 col-xs-12">
                                            <div className="" key={index}>
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    {data.nickName}
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-xs-12" style={{ marginTop: 2 }}>
                                                    <span className="notes-text">{data.firstName} {data.lastName}</span>
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-xs-12" style={{ marginTop: 2 }}>
                                                    <span className="notes-text">{data.email}</span>
                                                </div>
                                            </div>
                                        </a>
                                    )
                                })}
                                {this.state.care_receiver.map((data, index) => {
                                    return (
                                        <div key={index} onClick={this.ChangeCareReceiver.bind(this, index)} style={{ cursor: 'pointer' }}>

                                            <div className="contents row" style={data.selected ? {
                                                paddingBottom: 12,
                                                background: '#f1f8fb',
                                                border: "1px solid #00acde",
                                            } : { paddingBottom: 12 }}>
                                                {/*<button className="button-null-style"
                                                            onClick={this.ChangeCareReceiver.bind(this, index)}
                                                            style={{color: 'inherit'}}>*/}
                                                <div onClick={this.ChangeCareReceiver.bind(this, index)}
                                                    className="col-md-3 col-sm-12 col-xs-12"
                                                    style={{ paddingTop: 12, cursor: 'pointer' }}>
                                                    {data.nickName}

                                                </div>

                                                <div onClick={this.ChangeCareReceiver.bind(this, index)}
                                                    className="col-md-3 col-sm-12 col-xs-12"
                                                    style={{ marginTop: 2, paddingTop: 12, cursor: 'pointer' }}>
                                                    <span
                                                        className="notes-text">{data.firstName} {data.lastName}</span>
                                                </div>
                                                <div onClick={this.ChangeCareReceiver.bind(this, index)}
                                                    className="col-md-3 col-sm-12 col-xs-12"
                                                    style={{
                                                        marginTop: 2,
                                                        paddingTop: 12,
                                                        paddingBottom: 6,
                                                        cursor: 'pointer'
                                                    }}>
                                                    <span className="notes-text">{data.email}</span>
                                                </div>
                                                {/* </button>*/}

                                                <div className="col-md-3 col-sm-12 col-xs-12"
                                                    style={{ marginTop: 2, paddingTop: 12, paddingBottom: 6 }}>
                                                    <button className="btn-remove-bg"
                                                        onClick={this.EditCareReceiver.bind(this, index)}><img
                                                            className="notes-img" src='/assets/images/edit.png' />Edit
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button className={this.state.careTakerAccount.care_receiver_account.length === 1 ? "btn-remove-bg cross-cursor" : "btn-remove-bg"}
                                                        disabled={this.state.careTakerAccount.care_receiver_account.length === 1}
                                                        onClick={this.DeleteCareReceiver.bind(this, index)}><img
                                                            className="notes-img" src='/assets/images/rubbish-bin.png' />Delete
                                                    </button>
                                                    <button className="btn-remove-bg"
                                                        onClick={() => this.props.navigate('/auth/care-taker/cr-profile-setup')}
                                                        style={{ marginLeft: 5 }}
                                                    >
                                                        <img
                                                            className="notes-img" src='/assets/images/settings.png' />Manage
                                                    </button>
                                                </div>

                                                {data.care_admin
                                                    ?
                                                    <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                        <span style={{ fontWeight: '900' }}>Care Admin:</span> {`${data.care_admin.firstName} ${data.care_admin.lastName}`}
                                                        <a
                                                            style={{
                                                                border: '1px solid #8bc34a',
                                                                width: 100,
                                                                marginLeft: 10,
                                                                padding: 5,
                                                                color: '#689f38',
                                                                borderRadius: 5,
                                                                cursor: 'pointer',
                                                            }}
                                                            href={`mailto:${data.care_admin.email}`}
                                                        // href={`mailto:${this.fetchEmailOfCA(item)}`}
                                                        >
                                                            Email
                                                        <i
                                                                className="fa fa-envelope-o"
                                                                style={{
                                                                    color: '#689f38',
                                                                    marginLeft: 10
                                                                }}
                                                            />
                                                        </a>
                                                        <a
                                                            style={{
                                                                border: '1px solid #ffa000',
                                                                width: 100,
                                                                marginLeft: 10,
                                                                padding: 5,
                                                                color: '#ffa000',
                                                                borderRadius: 5,
                                                                cursor: 'pointer',
                                                            }}
                                                            href={`tel:${data.care_admin.phoneNumber}`}
                                                        // href={`tel:${this.fetchNumberOfCA(item)}`}
                                                        >
                                                            Call
                                                        <i
                                                                className="fa fa-phone"
                                                                style={{
                                                                    color: '#ffa000',
                                                                    marginLeft: 10
                                                                }}
                                                            />
                                                        </a>
                                                    </div>
                                                    :
                                                    <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                        {data.request_status != "NA" ?
                                                            <>
                                                                {data.request_status == "Requested" ?
                                                                    <p>
                                                                        <span style={{ color: 'green' }}>{'Request sent to Care Admin'}</span>
                                                                    </p>
                                                                    :
                                                                    <p>
                                                                        <span style={{ color: 'red' }}>{'Rejected'}</span>
                                                                    </p>
                                                                }
                                                            </>
                                                            :
                                                            <p>
                                                                <span style={{ fontWeight: '900' }}>Care Admin:</span>
                                                                <a
                                                                    style={{
                                                                        border: '1px solid #8bc34a',
                                                                        width: 100,
                                                                        marginLeft: 10,
                                                                        padding: 5,
                                                                        color: '#689f38',
                                                                        borderRadius: 5,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => { this.props.navigate('/auth/care-taker/choose-ca') }}
                                                                // href={`mailto:${this.fetchEmailOfCA(item)}`}
                                                                >
                                                                    Assign Care Admin
                                                                    <i
                                                                        className="fa fa-user"
                                                                        style={{
                                                                            color: '#689f38',
                                                                            marginLeft: 10
                                                                        }}
                                                                    />
                                                                </a>
                                                            </p>
                                                        }
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    )
                                })}
                                {
                                    this.state.care_receiver.length === 0 && <div className="text-center">
                                        No care receivers added yet.
                                    </div>
                                }
                                <div className="contents row sep10"></div>
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose.bind(this)}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Confirm delete
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose.bind(this)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.onPressDelete.bind(this)} color="primary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default CareTakerListCareReceiver;
