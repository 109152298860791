import React, {Component} from 'react';
import {CommonMethod, HTTPRequest} from './common.service';
import Constant from '../constants';
import {Initialize} from "../AnalyticsEvent";


class CgProfileSetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account_detail: {},
            daily_checkup_frequency: [{
                name: 'SMS',
                time: '06:00 AM',
                frequency: 'Daily',
                day: '',
                value: false,
            }, {
                name: 'Phone',
                time: '06:00 AM',
                frequency: 'Daily',
                day: '',
                value: false,
            }, {
                name: 'WhatsApp',
                time: '06:00 AM',
                frequency: 'Daily',
                day: '',
                value: false,
            },],
            formName: {
                old_password: 'Old Password',
                new_password: 'New Password',
                c_password: 'Confirm New Password',
                name: 'Name',
                phoneNumber: 'Phone number',
                email: 'Email',
            },
            trustedCircle: [],
            trustedCErrors: [],
            selected_care_receiver: {},
            parentControl: [],
            errors: {}
        };
    }

    updateDimensions() {
        this.setState({
            deviceWidth: window.innerWidth,
            deviceHeight: window.innerHeight,
        });
    }

    async initParentControl(account) {
        let planData = await HTTPRequest(Constant.BASE_URL + '/myplan', 'application/json', 'post', JSON.stringify({planName: account.plan_type,account_type: 'Care Taker'}));
        
        let myavailableplans = [];
        if (planData) {
            myavailableplans = planData.data.apps;
        }
        
        this.setState({myavailableplans})
        if (account.account_type === 'Care Receiver') {
        } else {
            this.state.parentControl = [
                {name: 'Daily Checkin', displayName: 'Pulse Check', allow: false, disable: !myavailableplans.includes('Pulse Check')},
                {name: 'Medicine', displayName: 'Meds Minder', allow: false, disable: !myavailableplans.includes('Meds Reminder')},
                {name: 'Task', displayName: 'To Do List', allow: true, disable: !myavailableplans.includes('To Do List')},
                {name: 'Activity', displayName: 'Activity Tracker', allow: false, disable: !myavailableplans.includes('Activity Tracker')},
                {name: 'Memory Bucket', displayName: 'Memory Bucket', allow: false, disable: !myavailableplans.includes('Memory Bucket')},
                {name: 'My Offers', displayName: 'My Offers', allow: true, disable: !myavailableplans.includes('My Offers')},
                {name: 'Community Insights', displayName: 'Get Social', allow: false, disable: !myavailableplans.includes('Get Social')}
            ];
        }
        
        if (!account.parent_control) {
            account.parent_control = this.state.parentControl;
            this.setState({parentControl: this.state.parentControl});
            /*for (let i = 0; i < account.parent_control.length; i++) {
                account.parent_control[i].allow = true;
            }*/
        } else {
            /*for (var i = 0; i < account.parent_control.length; i++) {
                for (var j = 0; j < this.state.parentControl.length; j++) {
                    if (account.parent_control[i].name === this.state.parentControl[j].name) {
                        account.parent_control[i].displayName = this.state.parentControl[j].displayName;
                        this.state.parentControl[j].name = account.parent_control[i].name;
                        this.state.parentControl[j].displayName = account.parent_control[i].displayName;
                        if (this.state.parentControl[j].disable) {
                            this.state.parentControl[j].allow = false;
                        } else {
                            this.state.parentControl[j].allow = account.parent_control[i].allow;
                        }
                        /!*this.state.parentControl[j].disable = account.parent_control[i].disable;*!/
                        break;
                    }
                }
            }
            this.setState({parentControl: this.state.parentControl});*/
            for (var i = 0; i < account.parent_control.length; i++) {
                for (var j = 0; j < this.state.parentControl.length; j++) {
                    if (account.parent_control[i].name === this.state.parentControl[j].name) {
                        account.parent_control[i].displayName = this.state.parentControl[j].displayName;
                        /*this.state.parentControl[j] = account.parent_control[i];*/
                        this.state.parentControl[j].name = account.parent_control[i].name;
                        this.state.parentControl[j].displayName = account.parent_control[i].displayName;
                        this.state.parentControl[j].disable = !myavailableplans.includes(this.state.parentControl[j].displayName)
                        if (this.state.parentControl[j].disable) {
                            this.state.parentControl[j].allow = false;
                        } else {
                            this.state.parentControl[j].allow = account.parent_control[i].allow;
                        }
                        break;
                    }
                }
            }
            this.setState({parentControl: this.state.parentControl})
        }

        var parent_control = {}
        if (this.state.parentControl) {
            for (let i = 0; i < this.state.parentControl.length; i++) {
                parent_control[this.state.parentControl[i].name] = this.state.parentControl[i].allow
            }
            
            this.state.parent_control = parent_control;
            this.setState({parent_control});
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        Initialize();
        CommonMethod();
        this.updateDimensions();

        if (!(window.localStorage.getItem('account_details') || window.localStorage.getItem('token'))) {
            window.setTimeout(() => {
                this.props.navigate('/');
            }, 200);
            return;
        }
        var account = JSON.parse(window.localStorage.getItem('account_details'));
       /* if (account.account_type === 'Care Receiver') {
        } else {
            this.state.parentControl = [
                {name: 'Daily Checkin', displayName: 'Pulse Check', allow: false},
                {name: 'Medicine', displayName: 'Meds Reminder', allow: false},
                {name: 'Task', displayName: 'To Do List', allow: true},
                {name: 'Activity', displayName: 'Activity Tracker', allow: false},
                {name: 'Memory Bucket', displayName: 'Memory Bucket', allow: false},
                {name: 'My Offers', displayName: 'My Offers', allow: true},
                {name: 'Community Insights', displayName: 'Get Social', allow: false}]
        }
        if (!account.parent_control) {
            account.parent_control = this.state.parentControl;
            // for (let i = 0; i < account.parent_control.length; i++) {
                // account.parent_control[i].allow = true;
            // }
        } else {
            
            for (var i = 0; i < account.parent_control.length; i++) {
                for (var j = 0; j < this.state.parentControl.length; j++) {
                    if (account.parent_control[i].name === this.state.parentControl[j].name) {
                        
                        account.parent_control[i].displayName = this.state.parentControl[j].displayName;
                        this.state.parentControl[j] = account.parent_control[i];
                        break;
                    }
                }
            }
            this.setState({parentControl: this.state.parentControl})
        }*/
        this.initParentControl(account);
        this.setState({account_detail: account})
    }


    render() {
        return (
            <>
                <p className="myProfileText text-center">Set Details for your profile</p>
                {/*{this.CareReceiverIdentitySection()}*/}
                <div className="row myProfile" style={{marginLeft: 0, marginRight: 0}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left'}}>
                        <div className="col-md-8 col-lg-8">
                            <p className="myProfileText">Select your Apps</p>
                        </div>
                        <div className="col-md-4 col-lg-4 pulse-check" style={{textAlign: 'right'}}>
                        </div>
                        <div className={'clearfix'}/>
                        <hr/>
                    </div>
                    <div className='row' style={{textAlign: 'center', margin: 30}}>
                        {this.state.parentControl.map((item, index) => {
                            return (
                                <div className="" key={index} style={{marginBottom: 20}}>
                                    <div className="col-md-3 col-sm-6 col-xs-6 text-left">
                                        <div
                                            className="form-group">
                                            <label>{item.displayName}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-6 text-left">
                                        <div className="form-group">
                                            <label className="switch">
                                                <input type="checkbox"
                                                       disabled={this.state.parentControl[index].disable}
                                                       checked={this.state.parentControl[index].allow}
                                                       onChange={this.ChangeParentControl.bind(this, index)}/>
                                                {this.state.parentControl[index].disable && <span className="slider-profile round" style={{cursor: 'not-allowed'}}></span>}
                                                {!this.state.parentControl[index].disable && <span className="slider-profile round"></span>}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    <hr/>
                    {/*{this.renderOtherAlerts()}*/}
                </div>
                <div style={{textAlign: 'center', paddingBottom: 30}}>
                    <button
                        onClick={this.Continue.bind(this)}
                        className={"btn-margin12 createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}>
                        <span style={{paddingLeft: 10}}>Continue</span>
                    </button>
                </div>
            </>
        )
    }

    CareReceiverIdentitySection() {
        var account_detail = this.state.selected_care_receiver;
        return (
            <div className="row myProfile" style={{marginLeft: 0, marginRight: 0, paddingTop: 15, paddingBottom: 30}}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left'}}>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{textAlign: 'left', paddingTop: 15}}>
                        <b>Nick Name : </b> {account_detail && account_detail.nickName ? account_detail.nickName : '-'}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{textAlign: 'left', paddingTop: 15}}>
                        <b>First Name
                            : </b> {account_detail && account_detail.firstName ? account_detail.firstName : '-'}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{textAlign: 'left', paddingTop: 15}}>
                        <b>Last Name : </b> {account_detail && account_detail.lastName ? account_detail.lastName : '-'}
                    </div>
                </div>
            </div>
        );
    }

    Continue() {
        // window.localStorage.clear();
        window.$('#snackbar').html('You have successfully updated your profile settings');
        // Get the snackbar DIV
        var x = document.getElementById("snackbar");

        // Add the "show" class to DIV
        x.className = "show";

        // After 3 seconds, remove the show class from DIV
        setTimeout(() => {
            x.className = x.className.replace("show", "");
        }, 3000);
        let backup_accounts = JSON.parse(window.localStorage.getItem('account_details_backup'));
        let account_details = JSON.parse(window.localStorage.getItem('account_details'));
        backup_accounts.parent_control = account_details.parent_control;
        window.localStorage.setItem('account_details_backup', JSON.stringify(backup_accounts))
        account_details.parent_control = null;
        window.localStorage.setItem('account_details', JSON.stringify(account_details))
        this.props.navigate('/cr-profile-setup');
    }

    async ChangeParentControl(index, event) {
        if (!this.state.account_detail.parent_control) {
            this.state.account_detail.parent_control = this.state.parentControl;
        }
        this.state.parentControl[index].allow = event.target.checked;
        this.state.account_detail.parent_control = this.state.parentControl;
        this.setState({account_detail: this.state.account_detail, parentControl: this.state.parentControl});
        var parent_control = await HTTPRequest(Constant.BASE_URL + '/parent-control', 'application/json; charset=UTF-8', 'post', JSON.stringify(this.state.account_detail.parent_control));
        this.state.account_detail.parent_control = parent_control.data;
        this.setState({account_detail: this.state.account_detail, parentControl: this.state.parentControl});
        window.localStorage.setItem('account_details', JSON.stringify(this.state.account_detail));
    }

    SetMedMinderSelectionService(body) {
        return HTTPRequest(Constant.BASE_URL + '/medicine-parent-control', 'application/json; charset=UTF-8', 'post', body);
    }

    renderOtherAlerts() {
        return (
            <div className="row" style={{textAlign: 'center', margin: 30}}>
                <div className="col-md-12 col-sm-12 col-xs-12 mobile-display" style={{paddingLeft: 0, paddingRight: 0}}>
                    {this.otherAlertContents()}
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 mobile-display" style={{paddingLeft: 0, paddingRight: 0}}>
                    {this.otherMedicineContens()}
                </div>
                {this.otherAlertContents(true)}
                {this.otherMedicineContens(true)}
            </div>
        );
    }

    otherAlertContents(isDesktop) {
        return (
            <>
                <div className={"col-md-3 col-sm-6 col-xs-6 text-left " + (isDesktop ? "desktop-display" : "")}>
                    <div
                        className="form-group">
                        <label>Alert if you don't update Meds Minder on time</label>
                    </div>
                </div>
                <div className={"col-md-3 col-sm-6 col-xs-6 text-left " + (isDesktop ? "desktop-display" : "")}>
                    <div className="form-group">
                        <label className="switch">
                            <input type="checkbox"
                                   checked={this.state.account_detail.medicine_minder ? this.state.account_detail.medicine_minder : false}
                                   onChange={(event) => {
                                       var account_detail = this.state.account_detail;
                                       
                                       account_detail.medicine_minder = event.target.checked;
                                       this.setState({
                                           account_detail
                                       })
                                       if (account_detail.med_minder) {
                                           for (let i = 0; i < account_detail.med_minder.length; i++) {
                                               account_detail.med_minder[i].cg_notification = event.target.checked;
                                           }
                                       }
                                       window.localStorage.setItem('account_details', JSON.stringify(account_detail));
                                       this.SetMedMinderSelectionService(JSON.stringify({
                                           type: 'med_minder',
                                           allow: event.target.checked,
                                           care_receiver_id: this.state.selected_care_receiver.id
                                       }))
                                   }
                                   }
                            />
                            <span className="slider-profile round"></span>
                        </label>
                    </div>
                </div>
            </>
        );
    }

    otherMedicineContens(isDesktop) {
        return (
            <>
                <div className={"col-md-3 col-sm-6 col-xs-6 text-left " + (isDesktop ? "desktop-display" : "")}>
                    <div
                        className="form-group">
                        <label>Alert your Trust Circle</label>
                    </div>
                </div>
                <div className={"col-md-3 col-sm-6 col-xs-6 text-left " + (isDesktop ? "desktop-display" : "")}>
                    <div className="form-group">
                        <label className="switch">
                            <input type="checkbox"
                                   checked={this.state.account_detail.trusted_circle_control ? this.state.account_detail.trusted_circle_control : false}
                                   onChange={(event) => {
                                       var account_detail = this.state.account_detail;
                                       
                                       account_detail.trusted_circle_control = event.target.checked;
                                       this.setState({
                                           account_detail
                                       })
                                       window.localStorage.setItem('account_details', JSON.stringify(account_detail));
                                       this.SetMedMinderSelectionService(JSON.stringify({
                                           type: 'trusted_circle',
                                           allow: event.target.checked,
                                           care_receiver_id: this.state.selected_care_receiver.id
                                       }))
                                   }
                                   }
                            />
                            <span className="slider-profile round"></span>
                        </label>
                    </div>
                </div>
            </>
        );
    }
}

export default CgProfileSetup;
