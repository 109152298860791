import React, { Component } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
/*import DialogContentText from "@material-ui/core/DialogContentText";*/
import DialogActions from "@material-ui/core/DialogActions";
/*import Button from "@material-ui/core/Button";*/
import Dialog from "@material-ui/core/Dialog";
import { HTTPRequest } from "../noauth/common.service";
import Constant from "../constants";
import { AddEvents, Initialize } from "../AnalyticsEvent";

class AddQuestionImageModal extends Component {
    state = {
        question: 'Hello! Do you remember who captured the photo?',
        category: 'Health',
        imageFile: '',
        imageMeta: null,
        errors: {},
        taskId: '',
        modelTitle: 'Send Photo Memory',
        formName: {
            imageFile: 'imageFile'
        },
        smsChecked: true,
        whatsappChecked: true,
    };

    componentDidMount() {
        Initialize();
        console.log(this.props)
        console.log(this.state)
        if (window.$) {
            window.setTimeout(() => {
                window.$('.sandbox-container input').datepicker({
                    autoclose: true,
                    startDate: (new Date())
                });
            }, 200);
            window.$('#root').css('filter', 'blur(5px)')
        }
        console.log(this.props.careReceiverId)
    }

    Validate() {
        var flag = false;
        debugger
        for (let key in this.state.formName) {
            if (key) {
                if (!this.state[key]) {
                    const json = {};
                    json.errors = this.state.errors;
                    json.errors[key] = this.state.formName[key] + ' is required.';
                    this.setState(json);
                    flag = true;
                }
            }
        }
        return flag;
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        })
    }

    async submitForm(event) {
        if (this.Validate()) {
            return;
        }
        debugger
        try {
            var careTakerAccount = JSON.parse(window.localStorage.getItem('account_details'));
            let to_user;
            if (this.props.isCr) {
                to_user = careTakerAccount.care_taker_account;
            } else {
                to_user = careTakerAccount.selected_care_receiver;
            }
            let files = this.state.imageMeta
            var fileName = files.name
            var fileType = files.type
            var to_user_id = to_user.id
            var data = await this.getBase64(files);
            window.$('.cloading').fadeIn();
            await HTTPRequest(Constant.BASE_URL + '/cg-cr/question', 'application/json; charset=UTF-8', 'post',
                JSON.stringify({
                    file: data,
                    fileName,
                    fileType,
                    to_user_id: to_user_id,
                    question: this.state.question,
                    is_cr: this.props.isCr ? true : false
                }))
            window.$('.cloading').fadeOut();
            this.props.onClose('Task has been updated.')
        } catch (e) {
            console.log(e)
            alert(e)
        }
    }

    componentWillUnmount() {
        if (window.$) {
            window.$('#root').css('filter', 'none')
        }
    }

    async UploadImage(event) {
        let error = this.state.errors
        let obj;
        if (error) {
            obj = { question: this.state.errors.question }
        } else {
            obj = {}
        }
        this.setState({ errors: obj })
        debugger
        event.preventDefault()
        debugger
        var files = event.target.files;
        if (files.length > 0) {
            this.setState({
                imageFile: files[0].name,
                imageMeta: files[0],
            })
        }
    }

    ChangeEvent(event) {
        debugger
        let json = {};
        const val = this.CheckErrors(event.target.name, event.target.value);
        json['errors'] = {}
        json.errors = this.state.errors;
        if (val) {
            json.errors[event.target.name] = val[event.target.name];
        } else {
            json.errors[event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        json[event.target.name] = event.target.value ? event.target.value : '';
        this.setState(json);
    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return { [targetName]: this.state.formName[targetName] + ' is required.' };
        }
    }

    render() {
        const { fullScreen, open, onClose } = this.props;
        return (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={onClose}
                aria-labelledby="createTaskId"
            >
                <div className="cloading" style={{ display: 'none' }}>Loading&#8230;</div>
                <DialogTitle id="createTaskId">{this.state.modelTitle}</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
                    </DialogContentText>*/}
                    <form className="signup-form">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Pick a Question <span className='required'>*</span></label>
                                    <select name='question'
                                        onChange={this.ChangeEvent.bind(this)}
                                        style={{ background: 'rgba(0, 0, 0, 0.1)' }}
                                        className={"form-control"}
                                        value={this.state.question}
                                    >
                                        <option value={`Hello! Do you remember who captured the photo?`}>{'Hello! Do you remember who captured the photo?'}</option>
                                        <option value={`This is a picture of the day! Do you remember anyone from the pic?`}>{`This is a picture of the day! Do you remember anyone from the pic?`}</option>
                                        {/* <option value={`When was this picture taken?`}>{`When was this picture taken?`}</option>
                                        <option value={`Do you know who took this picture?`}>{`Do you know who took this picture?`}</option>
                                        <option value={`Name a funny thing that happened during the time of this picture!`}>{`Name a funny thing that happened during the time of this picture!`}</option>
                                        <option value={`Add a comment to this picture!`}>{`Add a comment to this picture!`}</option> */}
                                    </select>
                                    {this.state.errors.countryCode &&
                                        <span className='error'>{this.state.errors.countryCode}</span>}
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Type Question <span className='required'>*</span></label>
                                    <input name="question" type="text" placeholder="Type Question"
                                        value={this.state.question || ''}
                                        maxLength="50"
                                        onFocus={this.ChangeEvent.bind(this)}
                                        onChange={this.ChangeEvent.bind(this)}
                                        className={"form-control" + (this.state.errors.question ? ' error-border' : '')} />
                                    {this.state.errors.question &&
                                        <span className='error'>{this.state.errors.question}</span>}
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Add Image <span className='required'>*</span></label>
                                    {/* <button onClick={() => window.$('#addPicture').click() }>
                                        <img src="/assets/images/camera.png" />
                                        <div>Add Picture</div>
                                    </button> */}
                                    <input
                                        id="imageFile"
                                        onChange={this.UploadImage.bind(this)}
                                        name="imageFile"
                                        type="file"
                                        accept="image/x-png,image/gif,image/jpeg"
                                    // style={{ display: 'none' }} 
                                    />
                                    {this.state.errors.imageFile &&
                                        <span className='error'>{this.state.errors.imageFile}</span>}
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-12">
                                <p>Choose which medium you want to use to notify</p>
                            </div>

                            <div className="col-md-12">
                                <input
                                    type='checkbox'
                                    checked={this.state.smsChecked}
                                    onClick={() => {
                                        // this.setState({ smsChecked: val.target.checked })
                                        this.setState({ smsChecked: !this.state.smsChecked })
                                    }}
                                />
                                <label
                                    onClick={() => {
                                        this.setState({ smsChecked: !this.state.smsChecked })
                                    }}
                                    style={{ marginLeft: 10 }}
                                >SMS</label>
                            </div>
                            <div className="col-md-12">
                                <input
                                    type='checkbox'
                                    checked={this.state.whatsappChecked}
                                    onClick={() => {
                                        // this.setState({ smsChecked: val.target.checked })
                                        this.setState({ whatsappChecked: !this.state.whatsappChecked })
                                    }}
                                />
                                <label
                                    onClick={() => {
                                        this.setState({ whatsappChecked: !this.state.whatsappChecked })
                                    }}
                                    style={{ marginLeft: 10 }}
                                >WHATSAPP</label>
                            </div>
                        </div> */}
                    </form>
                </DialogContent>
                <DialogActions className="actions">
                    <button
                        onClick={this.submitForm.bind(this)}
                        className={"createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}
                        style={{ marginTop: 0, marginBottom: 10 }}
                    >
                        Submit
                    </button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default AddQuestionImageModal;
