import React, { Component } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
/*import DialogContentText from "@material-ui/core/DialogContentText";*/
import DialogActions from "@material-ui/core/DialogActions";
/*import Button from "@material-ui/core/Button";*/
import Dialog from "@material-ui/core/Dialog";
import { HTTPRequest } from "../noauth/common.service";
import Constant from "../constants";
import { AddEvents, Initialize } from "../AnalyticsEvent";

class UpdateQuestionModal extends Component {
    state = {
        question: this.props.questionObjToUpdate ? this.props.questionObjToUpdate.question : '',
        errors: {},
        modelTitle: 'Update Question',
        formName: {
            question: 'Question',
        },
    };

    componentDidMount() {
        debugger
        Initialize();
        if (this.props.questionObjToUpdate) {
            this.setState({ question: this.props.questionObjToUpdate.question })
        }
    }

    Validate() {
        var flag = false;
        for (let key in this.state.formName) {
            if (key) {
                if (!this.state[key]) {
                    const json = {};
                    json.errors = this.state.errors;
                    json.errors[key] = this.state.formName[key] + ' is required.';
                    this.setState(json);
                    flag = true;
                }
            }
        }
        return flag;
    }

    async submitForm(event) {
        if (this.Validate()) {
            return;
        }
        try {
            debugger
            let body = {
                question: this.state.question,
                question_id: this.props.question_id,
                id: this.props.q_id
            }
            await this.updateQuestion(body)
            this.props.onSuccess(this.state.question)

            // this.props.onSuccess(this.state.question)
            // this.setState({ question: '' })
            this.props.onClose()
        } catch (e) {
            debugger
            var messsage = e.message ? e.message : "Something went wrong!";
            window.$('#snackbar').html(messsage);
            // Get the snackbar DIV
            var x = document.getElementById("snackbar");
            // Add the "show" class to DIV
            x.className = "show";
            // After 3 seconds, remove the show class from DIV
            setTimeout(() => {
                x.className = x.className.replace("show", "");
            }, 3000);

        }
    }

    updateQuestion(body) {
        return HTTPRequest(Constant.BASE_URL + '/care-taker/update-question', 'application/json', 'post', JSON.stringify(body));
    }

    componentWillUnmount() {
        if (window.$) {
            window.$('#root').css('filter', 'none')
        }
    }

    ChangeEvent(event) {
        let json = {};
        const val = this.CheckErrors(event.target.name, event.target.value);
        json['errors'] = {}
        json.errors = this.state.errors;
        if (val) {
            json.errors[event.target.name] = val[event.target.name];
        } else {
            json.errors[event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        if (event.target.name === 'dueDate') {
            window.setTimeout(() => {
                console.log(this.state)
                json['dueDate'] = window.$('input[name=dueDate]').val();
                if (json.dueDate) {
                    json.errors.dueDate = null;
                }
                /*if(event.target.name === 'dueDate') {
                    
                    json[event.target.name] = window.$(event.target).val();
                }*/
                this.setState(json);
            }, 200);
        }
        json[event.target.name] = event.target.value ? event.target.value : '';
        this.setState(json);
    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return { [targetName]: this.state.formName[targetName] + ' is required.' };
        }
    }

    render() {
        const { fullScreen, open, onClose } = this.props;
        console.log('this.props.questionObjToUpdate', this.props.questionObjToUpdate)
        return (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={onClose}
                aria-labelledby="createTaskId"
            >
                <div className="cloading" style={{ display: 'none' }}>Loading&#8230;</div>
                <DialogTitle id="createTaskId">{this.state.modelTitle}</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
                    </DialogContentText>*/}
                    <form className="signup-form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Type Question <span className='required'>*</span></label>
                                    <input name="question" type="text" placeholder="Type Question"
                                        value={this.state.question || ''}
                                        maxLength="50"
                                        onFocus={this.ChangeEvent.bind(this)}
                                        onChange={this.ChangeEvent.bind(this)}
                                        className={"form-control" + (this.state.errors.question ? ' error-border' : '')} />
                                    {this.state.errors.question &&
                                        <span className='error'>{this.state.errors.question}</span>}
                                </div>
                            </div>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions className="actions">
                    <button
                        onClick={this.submitForm.bind(this)}
                        className={"createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}
                        style={{
                            marginBottom: 20
                        }}
                    >
                        Submit
                    </button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default UpdateQuestionModal;
