import React, {Component} from 'react';
import {CommonMethod, HTTPRequest} from './common.service'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';
import Radio from '@material-ui/core/Radio';
import LinearProgress from '@material-ui/core/LinearProgress';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Constant from '../constants';
import {Link} from "@reach/router";


class PaymentComponent extends Component {
    state = {
        planType: 'Classic',
        cardType: '',
        cardNumber: '',
        cardExp: '',
        cardCvv: '',
        promocode: '',
        errors: {
            planType: '',
            cardType: '',
            cardNumber: '',
            cardExp: '',
            cardCvv: '',
            promocode: ''
        },
        formName: {
            planType: 'Plan Type',
            cardType: 'Card Type',
            cardNumber: 'Card Number',
            cardExp: 'Card Expiration',
            cardCvv: 'Card CVV',
            promocode: 'Promo Code'
        },
        apiError: null,
        apiSuccess: null,
        style: null,
        isCompleted: false,
        isLoading: false,
        redirect: null
    };

    async SubmitForm(event) {
        event.preventDefault();
        this.setState({isLoading: true})
        window.$('.cloading').fadeIn();
        event.target.blur()
        try {
            
            if (!this.state.promocode) {
                let {token} = await this.props.stripe.createToken({name: 'Sahaaya'});
                let response = await this.PaymentService(JSON.stringify({token:token.id,card_id: token.card.id, planType: this.state.planType, promocode: this.state.promocode}));
                // if (response.ok) console.log('Purchase Complete!');
                let account;
                if (this.state.redirect) {
                    
                    window.alert('Your plan has been changed!');
                    this.props.navigate('/auth/view/profile');
                    if (response.code === 200) {
                        account = JSON.parse(window.localStorage.getItem('account_details'));
                        account.planType = this.state.planType
                    }
                    window.localStorage.setItem('account_details', JSON.stringify(account));
                } else {
                    window.alert('Your Registration has been completed!');
                    // window.localStorage.clear();
                    this.props.navigate('/profile-setup');
                }
            } else {
                let response = null;
                try {
                    response = await this.PromoCodeService(JSON.stringify({
                        planType: this.state.planType,
                        promocode: this.state.promocode
                    }));
                } catch (e) {
                    window.$('#snackbar').html('Promocode is incorrect.');
                    // Get the snackbar DIV
                    var x = document.getElementById("snackbar");

                    // Add the "show" class to DIV
                    x.className = "show";

                    // After 3 seconds, remove the show class from DIV
                    setTimeout(() => {
                        x.className = x.className.replace("show", "");
                    }, 3000);
                    return;
                }
                if (this.state.redirect) {
                    
                    window.alert('Your plan has been changed!');
                    this.props.navigate('/auth/view/profile');
                    let account = {};
                    if (response.code === 200) {
                        account = JSON.parse(window.localStorage.getItem('account_details'));
                        account.planType = this.state.planType
                    }
                    window.localStorage.setItem('account_details', JSON.stringify(account));
                } else {
                    window.alert('Your Registration has been completed!');
                    // window.localStorage.clear();
                    let accounts = JSON.parse(window.localStorage.getItem('account_details'));
                    accounts.payment = true;
                    window.localStorage.setItem('account_details_backup', JSON.stringify(accounts));
                    this.props.navigate('/profile-setup');
                }
            }
            window.$('.cloading').fadeOut();
            this.setState({isLoading: false})
            if (window.ga) {
                window.ga('send', 'event', 'Payment', 'success', 'No-Auth');
            }
        } catch (e) {
            window.$('.cloading').fadeOut();
            this.setState({isLoading: false})
            if (window.ga) {
                window.ga('send', 'event', 'Payment', 'error', 'No-Auth');
            }
        }

    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
        // console.log('Query variable %s not found', variable);
    }

    componentDidMount() {
        
        console.log(this.getQueryVariable('redirect'))
        var redirect = this.getQueryVariable('redirect');
        if (redirect) {
            this.setState({
                redirect
            })
        }
        this.state.redirect = redirect;
        if (window.localStorage.getItem('token')) {
            window.$('.cloading').fadeOut();
            CommonMethod();
            var style = {
                base: {
                    color: '#32325d',
                    fontFamily: '`Helvetica Neue`, Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            };

            this.setState(style)
        } else {
            setTimeout(() => {
                if (this.state.redirect) {
                    this.props.navigate('/auth/care-taker/view/profile');
                } else {
                    this.props.navigate('/');
                }
            }, 200)
        }
    }

    ResetForm() {
        this.setState({
            planType: '',
            cardType: '',
            cardNumber: '',
            cardExp: '',
            cardCvv: '',
            promocode: ''
        })
    }

    handleCardChange(event) {
        if (event.brand) {
            this.setState({
                cardType: event.brand.toUpperCase()
            })
        }
        var errors = this.state.errors;
        if (event.error && event.error.message) {
            errors.cardNumber = event.error.message;
            this.setState({
                errors
            });
        } else {
            errors.cardNumber = '';
            this.setState({
                errors
            });
        }
    };

    handleCardExpChange(event) {
        var errors = this.state.errors;
        if (event.error && event.error.message) {
            errors.cardExp = event.error.message;
            this.setState({
                errors
            });
        } else {
            errors.cardExp = '';
            this.setState({
                errors
            });
        }
    };

    handleCardCvvChange(event) {
        var errors = this.state.errors;
        if (event.error && event.error.message) {
            errors.cardCvv = event.error.message;
            this.setState({
                errors
            });
        } else {
            errors.cardCvv = '';
            this.setState({
                errors
            });
        }
    };

    RadioChange(event) {
        this.setState({planType: event.target.value})
    }
    ChangeEvent(event) {
        const json = {};
        json[event.target.name] = event.target.value;
        this.setState(json);
    }
    render() {
        return (
            <div className='row'>
                <div className='signup-block'>
                    {!this.state.redirect && <div className="row" style={{marginLeft: 0, marginRight: 0}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <div className="stepwizard">
                                <div className="stepwizard-row stepwizard-row3 setup-panel">
                                    <div className="stepwizard-step">
                                        <a href="javascript:void(0)" type="button" className="btn btn-primary btn-circle" disabled="disabled">1</a>
                                    </div>
                                    <div className="stepwizard-step">
                                        <a href="javascript:void(0)" type="button" className="btn btn-primary btn-circle"
                                           disabled="disabled">2</a>
                                    </div>
                                    <div className="stepwizard-step">
                                        <Link to='/payment' type="button" className="btn btn-primary btn-circle">3</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>}
                    <div>
                        <div className='col-md-3'></div>
                        <div className='col-sm-12 col-md-6'>
                            <div className='signup-form-cat'>
                                {this.state.isLoading && <LinearProgress />}
                                <form className='signup-form'>
                                    {this.state.apiError &&
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='alert alert-danger' role='alert'>
                                                {this.state.apiError}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {this.state.apiSuccess &&
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='alert alert-success' role='alert'>
                                                {this.state.apiSuccess}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <h2 className='text-center' style={{color: '#004265'}}>Payment</h2>
                                        </div>
                                    </div>
                                    <h3 className='primary-color'>Select Plan</h3>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <FormControl component='fieldset'>
                                                {/*<FormLabel component='legend'>Gender</FormLabel>*/}
                                                <RadioGroup
                                                    aria-label='gender'
                                                    name='gender2'
                                                    value={this.state.planType}
                                                    onChange={this.RadioChange.bind(this)}
                                                >
                                                    <FormControlLabel
                                                        value='Classic'
                                                        control={<Radio color='primary'/>}
                                                        label='Classic ($10)'
                                                    />
                                                    <FormControlLabel
                                                        disabled={true}
                                                        value='Deluxe'
                                                        control={<Radio color='primary'/>}
                                                        label='Deluxe ($25)'
                                                    />
                                                    <FormControlLabel
                                                        disabled={true}
                                                        value='Premium'
                                                        control={<Radio color='primary'/>}
                                                        label='Premium ($100)'
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <h3 className='primary-color'>Select Payment Method</h3>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='form-group'>
                                                <label>Cary Type</label>
                                                <input type='text' placeholder='VISA' name='cardType'
                                                       value={this.state.cardType}
                                                       readOnly
                                                       className='form-control'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='form-group'>
                                                <label>Card Number <span className='required'>*</span></label>
                                                <CardNumberElement onChange={this.handleCardChange.bind(this)}/>
                                                {this.state.errors.cardNumber &&
                                                <span className='error'>{this.state.errors.cardNumber}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xs-6 col-sm-6 col-md-6'>
                                            <div className='form-group'>
                                                <label>Expiration <span className='required'>*</span></label>
                                                <CardExpiryElement onChange={this.handleCardExpChange.bind(this)}/>
                                                {this.state.errors.cardExp &&
                                                <span className='error'>{this.state.errors.cardExp}</span>}
                                            </div>
                                        </div>
                                        <div className='col-xs-6 col-sm-6 col-md-6'>
                                            <div className='form-group'>
                                                <label>CVV <span className='required'>*</span></label>
                                                <CardCVCElement onChange={this.handleCardCvvChange.bind(this)}/>
                                                {this.state.errors.cardCvv &&
                                                <span className='error'>{this.state.errors.cardCvv}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='form-group'>
                                                <label>Promo code</label>
                                                <input type='text' placeholder='Apply' className='form-control'
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       value={this.state.promocode}
                                                       name='promocode'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row text-center'>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-12 col-sm-12'>
                                                    <button onClick={this.SubmitForm.bind(this)}
                                                            className='btn btn-default'>Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-md-3'></div>
                    </div>
                    <div className='clearfix'></div>
                </div>
            </div>
        )
    }


    PromoCodeService(data) {
        return HTTPRequest(Constant.BASE_URL + '/care-taker/promocode', 'application/json; charset=UTF-8', 'post', data);
    }

    PaymentService(data) {
        return HTTPRequest(Constant.BASE_URL + '/care-taker/payment', 'application/json; charset=UTF-8', 'post', data);
    }
}

const mapToProps = (props, state) => {
}

export default injectStripe(PaymentComponent, mapToProps);
