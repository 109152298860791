import {Component} from "react";
import React from "react";
import {HTTPRequest} from "../noauth/common.service";
import Constant from "../constants";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "../../../node_modules/@material-ui/icons/Close";
import {withStyles} from "@material-ui/core/styles";
import {AddEvents, Initialize} from "../AnalyticsEvent";

const useStyles = theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none'
    },
});

class CareReceiverMedMinderComponent extends Component {
    state = {
        insightId: 1,
        open: false,
        message: '',
        medMinder: [],
        formName: {
            medicineName: 'Medicine name',
            medicineTime: 'Medicine time',
            cg_notification: 'Care giver',
        },
        errors: []
    }

    componentDidMount() {
        Initialize();
        this.AddMedMinder()
        /*this.setState({
            medMinder: [this.state.defaultMedValues]
        });*/
        window.setTimeout(() => {
            window.$('#timepicker0').datetimepicker({
                format: 'LT'
            });
            // window.$('#timepicker').timepicker();
            // window.$('#timepicker').timepicker('showWidget');
        }, 1000)
        var ac = window.localStorage.getItem('account_details');
        if (ac && ac !== 'undefined') {
            var accountDetails = JSON.parse(window.localStorage.getItem('account_details'));
            
            this.setState({accountDetails})
            if (accountDetails.med_minder && accountDetails.med_minder.length > 0) {
                var medMinder = accountDetails.med_minder;
                var errors = this.state.errors;
                for (let i = 0; i < medMinder.length; i++) {
                    if (!medMinder[i].timezone) {
                        if (Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions().timeZone) {
                            medMinder[i].timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        }
                    }
                    errors.push({});
                    window.setTimeout(() => {
                        window.$('#timepicker' + i).datetimepicker({
                            format: 'LT'
                        });
                        window.$('#timepicker' + i).on('dp.change', this.ChangeEvent.bind(this, i))

                        // window.$('#timepicker').timepicker();
                        // window.$('#timepicker').timepicker('showWidget');
                    }, 100)
                }
                this.setState({medMinder, errors})
            }
        }
    }

    AddMedMinder() {
        var medMinder = this.state.medMinder;
        var errors = this.state.errors;
        errors.push({});
        
        var timezone = '';
        if (Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions().timeZone) {
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        medMinder.push({
            medicineTime: '',
            medicineName: '',
            cg_notification: false,
            timezone
        })
        this.setState({medMinder, errors})
        window.setTimeout(() => {
            window.$('#timepicker' + (this.state.medMinder.length - 1)).datetimepicker({
                format: 'LT'
            });
            window.$('#timepicker' + (this.state.medMinder.length - 1)).on('dp.change', this.ChangeEvent.bind(this, this.state.medMinder.length - 1))

            // window.$('#timepicker').timepicker();
            // window.$('#timepicker').timepicker('showWidget');
        }, 100)
    }

    async SetMedMinder() {
        var medMinder = this.state.medMinder;
        var errors = this.state.errors;
        var flag = false;
        for (let i = 0; i < errors.length; i++) {
            
            if (errors[i].medicineTime || errors[i].medicineName) {
                return;
            }
            if (medMinder[i] && (!medMinder[i].medicineTime || !medMinder[i].medicineName)) {
                if (!medMinder[i].medicineTime) {
                    flag = true;
                    errors[i] = this.CheckErrors('medicineTime', '');
                    this.setState({errors})
                }
                if (!medMinder[i].medicineName) {
                    flag = true;
                    errors[i] = this.CheckErrors('medicineName', '');
                    this.setState({errors})
                }
            }
        }
        if (flag) {
            return;
        }
        this.setState({medMinder})
        var setMedMinder = await HTTPRequest(Constant.BASE_URL + '/care-receiver/set-med-minder', 'application/json; charset=UTF-8', 'post', JSON.stringify(medMinder));
        
        var accountDetails = this.state.accountDetails;
        accountDetails.med_minder = setMedMinder.data.med_minder;
        accountDetails.medicine_minder = setMedMinder.data.medicine_minder;
        window.localStorage.setItem('account_details', JSON.stringify(accountDetails))
        this.setState({accountDetails})
        this.props.navigate('/auth/dashboard')
    }

    RemoveMedMinder(index) {
        var medMinder = this.state.medMinder;
        medMinder.splice(index, 1);
        var errors = this.state.errors;
        errors.splice(index, 1);
        this.setState({medMinder, errors})
    }

    ChangeEvent(index, event) {
        
        const json = this.state;
        const val = this.CheckErrors(event.target.name, event.target.value);
        if (val) {
            json.errors = this.state.errors;
            json.errors[index][event.target.name] = val[event.target.name];
        } else {
            json.errors = this.state.errors;
            json.errors[index][event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        if (event.target.name === 'cg_notification_true') {
            json.medMinder[index]['cg_notification'] = event.target.checked;
        } else if (event.target.name === 'cg_notification_false') {
            json.medMinder[index]['cg_notification'] = !event.target.checked;
        } else {
            json.medMinder[index][event.target.name] = event.target.value;
        }
        if (event.target.name === 'medicineTime') {
            var target = event.target;
            // setTimeout(() => {
                const val = this.CheckErrors('medicineTime', window.$(target).val());
                if (val) {
                    json.errors = this.state.errors;
                    json.errors[index]['medicineTime'] = val['medicineTime'];
                } else {
                    json.errors = this.state.errors;
                    json.errors[index]['medicineTime'] = null;
                }
                json.medMinder[index]['medicineTime'] = window.$(target).val();
                this.setState(json);
                console.log(window.$(target).val())
                console.log(json)
            // }, 200);
        }
        this.setState(json);
    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return {[targetName]: this.state.formName[targetName] + ' is required.'};
        }
    }

    render() {
        const {close, paper} = this.props.classes;
        return (
            <div>
                {this.state.open && <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.open}
                    onClose={this.handleClose.bind(this)}
                    autoHideDuration={4000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span className="snackBar">{this.state.message}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={close}
                            onClick={this.handleClose.bind(this)}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />}
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 center-block" style={{paddingTop: 20}}>
                        {/*<div className="row text-center" style={{paddingBottom: 20}}>
                            <div className="col-md-3 col-sm-6 col-xs-6">
                                <div className="">
                                    <label>Medicine Time</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-6">
                                <div className="">
                                    <label>Time <span className='required'>*</span></label>
                                    <label>Medicine Name</label>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-4 col-xs-4 text-center">
                                <label>Care Giver SMS Alert</label>
                            </div>
                        </div>*/}
                        {this.state.medMinder.map((item, index) => {
                                return (
                                    <form className="" style={{marginBottom: 20, boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 12px'}} key={index}>
                                        <div className="row">
                                            <div className="col-md-3 col-sm-6 col-xs-6">
                                                <div className="form-group input-group bootstrap-timepicker custom-form-group">

                                                    {/*<label>Time <span className='required'>*</span></label>*/}
                                                    <input id={'timepicker' + index} name='medicineTime' type="text"
                                                           placeholder="Time"
                                                           value={this.state.medMinder[index].medicineTime}
                                                           onFocus={this.ChangeEvent.bind(this, index)}
                                                           onChange={this.ChangeEvent.bind(this, index)}
                                                           className={"form-control"}/>
                                                    {this.state.errors[index].medicineTime &&
                                                    <span className='error'>{this.state.errors[index].medicineTime}</span>}
                                                    <div onClick={() => {
                                                        
                                                        window.$('#timepicker' + index).data("DateTimePicker").show()
                                                    }} className="input-group-addon">
                                                        <i className="glyphicon glyphicon-time"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xs-6">
                                                <div className="form-group custom-form-group custom-form-group">
                                                    {/*<label>Time <span className='required'>*</span></label>*/}
                                                    <input name='medicineName' type="text" placeholder="Enter Medicine Name"
                                                           value={this.state.medMinder[index].medicineName}
                                                           onFocus={this.ChangeEvent.bind(this, index)}
                                                           onChange={this.ChangeEvent.bind(this, index)}
                                                           className={"form-control"}/>
                                                    {this.state.errors[index].medicineName &&
                                                    <span className='error'>{this.state.errors[index].medicineName}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-5 col-sm-4 col-xs-4 text-center desktop-display">
                                                <div className='form-group inline-block custom-form-group'>
                                                    <label className="pure-material-checkbox" style={{fontWeight: 'normal'}}>
                                                        {this.state.medMinder[index].cg_notification &&
                                                        <input type="checkbox" name="cg_notification_true"
                                                               onChange={this.ChangeEvent.bind(this, index)} checked
                                                               style={{margin: 0}}/>}
                                                        {!this.state.medMinder[index].cg_notification &&
                                                        <input type="checkbox" name="cg_notification_true"
                                                               onChange={this.ChangeEvent.bind(this, index)}
                                                               style={{margin: 0}}/>}
                                                        <span>Care Giver SMS Alert</span>
                                                    </label>
                                                </div>
                                                {/*<div className='form-group inline-block custom-form-group'>
                                                    <label className="pure-material-checkbox">
                                                        {!this.state.medMinder[index].cg_notification &&
                                                        <input type="checkbox" name="cg_notification_false"
                                                               onChange={this.ChangeEvent.bind(this, index)} checked
                                                               style={{margin: 0}}/>}
                                                        {this.state.medMinder[index].cg_notification &&
                                                        <input type="checkbox" name="cg_notification_false"
                                                               onChange={this.ChangeEvent.bind(this, index)}
                                                               style={{margin: 0}}/>}
                                                        <span>No</span>
                                                    </label>
                                                </div>*/}
                                                <div className='form-group inline-block custom-form-group'>
                                                    <a onClick={this.RemoveMedMinder.bind(this, index)} className='btn'>
                                                        <i className="glyphicon glyphicon-trash" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-8 col-xs-8 mobile-display text-center">
                                                <div className='pure-material-checkbox custom-form-group'
                                                     style={{marginTop: 10, marginBottom: 10}}>
                                                    {/*<label className="pure-material-checkbox">
                                                        <input type="checkbox" style={{margin: 0}}/>
                                                        <span>Yes</span>
                                                    </label>*/}
                                                    <label className="pure-material-checkbox" style={{fontWeight: 'normal', marginTop: 10, marginBottom: 10}}>
                                                        {this.state.medMinder[index].cg_notification &&
                                                        <input type="checkbox" name="cg_notification_true"
                                                               onChange={this.ChangeEvent.bind(this, index)} checked
                                                               style={{margin: 0}}/>}
                                                        {!this.state.medMinder[index].cg_notification &&
                                                        <input type="checkbox" name="cg_notification_true"
                                                               onChange={this.ChangeEvent.bind(this, index)}
                                                               style={{margin: 0}}/>}
                                                        <span>Care Giver SMS Alert</span>
                                                    </label>
                                                    {/*{this.state.medMinder[index].cg_notification &&
                                                    <input type="checkbox" name="cg_notification_true"
                                                           onChange={this.ChangeEvent.bind(this, index)} checked
                                                           style={{margin: 0}}/>}
                                                    {!this.state.medMinder[index].cg_notification &&
                                                    <input type="checkbox" name="cg_notification_true"
                                                           onChange={this.ChangeEvent.bind(this, index)}
                                                           style={{margin: 0}}/>}
                                                    <span>Care Giver SMS Alert</span>*/}
                                                </div>
                                            </div>
                                            {/*<div className="col-md-2 col-sm-8 col-xs-8 mobile-display text-center">
                                                <div className='pure-material-checkbox' style={{marginTop: 10, marginBottom: 10}}>
                                                    <label className="pure-material-checkbox">
                                                        <input type="checkbox" style={{margin: 0}}/>
                                                        <span>No</span>
                                                    </label>
                                                    {!this.state.medMinder[index].cg_notification &&
                                                    <input type="checkbox" name="cg_notification_false"
                                                           onChange={this.ChangeEvent.bind(this, index)} checked
                                                           style={{margin: 0}}/>}
                                                    {this.state.medMinder[index].cg_notification &&
                                                    <input type="checkbox" name="cg_notification_false"
                                                           onChange={this.ChangeEvent.bind(this, index)}
                                                           style={{margin: 0}}/>}
                                                    <span>No</span>
                                                </div>
                                            </div>*/}
                                            <div className="col-md-1 col-sm-4 col-xs-4 mobile-display text-center">
                                                <a onClick={this.RemoveMedMinder.bind(this, index)} className='btn'
                                                   style={{marginTop: 10, marginBottom: 10}}>
                                                    <i className="glyphicon glyphicon-trash" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                        </div>
                                    </form>
                                );
                            }
                        )}
                    </div>
                    <div className="row text-center desktop-display" style={{paddingBottom: 20}}>
                        <div className="col-md-3 col-sm-3 col-xs-3 text-center"></div>
                        <div className="col-md-3 col-sm-3 col-xs-3 text-center">
                            <button onClick={this.AddMedMinder.bind(this)} type="submit"
                                    className="btn navigation-btn">Add More
                            </button>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-3 text-center">
                            <button onClick={this.SetMedMinder.bind(this)} type="submit"
                                    className="btn navigation-btn">Save Changes
                            </button>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-3 text-center"></div>
                    </div>
                    <div className="row text-center mobile-display" style={{paddingBottom: 20}}>
                        <div className="col-md-6 col-sm-6 col-xs-6 text-center">
                            <button onClick={this.AddMedMinder.bind(this)} type="submit"
                                    className="btn navigation-btn">Add More
                            </button>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6 text-center">
                            <button onClick={this.SetMedMinder.bind(this)} type="submit"
                                    className="btn navigation-btn">Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(CareReceiverMedMinderComponent);
