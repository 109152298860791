import React, { Component } from 'react';
import { Link } from '@reach/router'
import firebase from 'firebase';
import { HTTPRequest } from "../../noauth/common.service";
import Constant from "../../constants";
import { AddEvents } from "../../AnalyticsEvent";

class AuthTemplateComponent extends Component {
    state = {
        page: 'home',
        user_type: '',
        open: true,
        message: 'asdsad'
    }

    componentWillUpdate() {
        window.setTimeout(() => {
            if (this.props.location.pathname === '/auth/care-taker/insight' && this.state.page !== 'insight') {
                this.setState({
                    page: 'insight'
                })
            }
            if (this.state.page !== 'care-receiver' && this.props.location.pathname === '/auth/care-taker/care-receivers' && this.state.page !== 'care-receivers') {
                this.setState({
                    page: 'care-receivers'
                })
            }
            if (this.state.page !== 'care-receiver' && this.props.location.pathname === '/auth/care-admin/care-receivers' && this.state.page !== 'ca-care-receivers') {
                this.setState({
                    page: 'ca-care-receivers'
                })
            }
            if (this.state.page !== 'edit-care-receiver' && this.props.location.pathname === '/auth/care-taker/edit-care-receiver' && this.state.page !== 'care-receivers') {
                this.setState({
                    page: 'edit-care-receiver'
                })
            }
            if (this.state.page !== 'health' && this.props.location.pathname === '/auth/health') {
                this.setState({
                    page: 'health'
                })
            }
            if (this.state.page !== 'profile' && this.props.location.pathname === '/auth/edit/profile') {
                this.setState({
                    page: 'profile'
                })
            }
            if (this.state.page !== 'view-profile' && this.props.location.pathname === '/auth/view/profile') {
                this.setState({
                    page: 'view-profile'
                })
            }
            if (this.state.page !== 'home' && this.props.location.pathname === '/auth/care-taker/dashboard' || this.props.location.pathname === '/auth/dashboard') {
                this.setState({
                    page: 'home'
                })
            }
            if (this.state.page !== 'add-care-receiver' && this.props.location.pathname === '/auth/care-taker/add-care-receiver') {
                this.setState({
                    page: 'add-care-receiver'
                })
            }
            if (this.state.page !== 'payment' && this.props.location.pathname === '/auth/care-taker/payment') {
                this.setState({
                    page: 'payment'
                })
            }
            if (this.state.page !== 'insight' && this.props.location.pathname === '/auth/care-receiver/insight') {
                this.setState({
                    page: 'insight'
                })
            }
            if (this.state.page !== 'medminder' && this.props.location.pathname === '/auth/med-minder') {
                this.setState({
                    page: 'medminder'
                })
            }
            if (this.state.page !== 'progressive-dashboard' && this.props.location.pathname === '/auth/progressive-dashboard') {
                this.setState({
                    page: 'progressive-dashboard'
                })
            }
            if (this.state.page !== 'ca-edit-cr' && this.props.location.pathname === '/auth/care-admin/edit-care-receiver') {
                this.setState({
                    page: 'ca-edit-cr'
                })
            }
            if (this.state.page !== 'ca-manage-cr' && this.props.location.pathname === '/auth/care-admin/manage-care-receivers') {
                this.setState({
                    page: 'ca-manage-cr'
                })
            }
            if (this.state.page !== 'ca-cr-profile-setup' && this.props.location.pathname === '/auth/care-admin/cr-profile-setup') {
                this.setState({
                    page: 'ca-cr-profile-setup'
                })
            }
            if (this.state.page !== 'ct-cr-profile-setup' && this.props.location.pathname === '/auth/care-taker/cr-profile-setup') {
                this.setState({
                    page: 'ct-cr-profile-setup'
                })
            }
            if (this.state.page !== 'ca-assigned-cr-by-cg' && this.props.location.pathname === '/auth/care-taker/choose-ca') {
                this.setState({
                    page: 'ca-assigned-cr-by-cg'
                })
            }
        }, 100);
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    }

    async EmergencyCallService() {
        try {
            /*window.ga('send', 'event', {
                eventCategory: 'Care Receiver',
                eventAction: 'click',
                eventLabel: 'Call emergency'
            });*/

            AddEvents('Care Receiver', 'emergency call click', 'Call emergency');

            await HTTPRequest(Constant.BASE_URL + '/care-receiver/emergency', null, 'get', null);
        } catch (e) {
            console.log(e);
        }
    }
    /* async EmergencyCallService() {
         try {
             await HTTPRequest(Constant.BASE_URL + '/care-receiver/emergency', null, 'get', null);
         } catch (e) {
             console.log(e);
         }
     }
 */
    async signOut() {
        try {
            AddEvents(this.state.user_type, 'Signout click', 'Sign out');
            await HTTPRequest(Constant.BASE_URL + '/logout', null, 'get', null);
        } catch (e) {
            console.log(e)
        }
        window.localStorage.clear();
        this.props.navigate('/');
    }

    componentDidMount() {
        if (window.$) {
            window.setTimeout(() => {
                window.$(".loading").fadeOut();
            }, 1000);
        }
        if (!window.localStorage.getItem('account_details')) {
            window.setTimeout(() => {
                this.props.navigate('/');
            }, 200);
            return;
        }
        var account = JSON.parse(window.localStorage.getItem('account_details') ? window.localStorage.getItem('account_details') : '{}');
        this.setState({
            user_type: account.account_type,
            phoneNumber: account.care_taker_account ? account.care_taker_account.phoneNumber : ''
        })
        window.setTimeout(() => {

            if (account.account_type !== 'Care Admin' && account.account_type !== 'Care Receiver' && !account.payment) {
                window.localStorage.clear()
                this.props.navigate('/')
            }
        }, 1000)
        if (this.props.location.pathname === '/auth/care-taker/dashboard' || this.props.location.pathname === '/auth/care-admin/dashboard' || this.props.location.pathname === '/auth/dashboard') {
            this.setState({
                page: 'home'
            })
        }
        if (this.props.location.pathname === '/auth/care-taker/insight' || this.props.location.pathname === '/auth/care-admin/insight') {
            this.setState({
                page: 'insight'
            })
        }
        if (this.props.location.pathname === '/auth/care-taker/care-receivers') {
            this.setState({
                page: 'care-receivers'
            })
        }
        if (this.props.location.pathname === '/auth/care-admin/care-receivers') {
            this.setState({
                page: 'ca-care-receivers'
            })
        }
        if (this.props.location.pathname === '/auth/care-taker/add-care-receivers' || this.props.location.pathname === '/auth/care-admin/add-care-receivers') {
            this.setState({
                page: 'add-care-receiver'
            })
        }
        if (this.props.location.pathname === '/auth/edit/profile') {
            this.setState({
                page: 'profile'
            })
        }
        if (this.props.location.pathname === '/auth/view/profile') {
            this.setState({
                page: 'view-profile'
            })
        }
        if (this.props.location.pathname === '/auth/care-taker/payment' || this.props.location.pathname === '/auth/care-admin/payment') {
            this.setState({
                page: 'payment'
            })
        }
        if (this.props.location.pathname === '/auth/care-receiver/insight') {
            this.setState({
                page: 'insight'
            })
        }
        if (this.props.location.pathname === '/auth/med-minder') {
            this.setState({
                page: 'medminder'
            })
        }
        if (this.props.location.pathname === '/auth/progressive-dashboard') {
            this.setState({
                page: 'progressive-dashboard'
            })
        }
        if (this.props.location.pathname === '/auth/care-admin/edit-care-receiver') {
            this.setState({
                page: 'ca-edit-cr'
            })
        }
        if (this.props.location.pathname === '/auth/care-admin/manage-care-receivers') {
            this.setState({
                page: 'ca-manage-cr'
            })
        }
        if (this.props.location.pathname === '/auth/care-admin/cr-profile-setup') {
            this.setState({
                page: 'ca-cr-profile-setup'
            })
        }
        if (this.props.location.pathname === '/auth/care-taker/cr-profile-setup') {
            this.setState({
                page: 'ct-cr-profile-setup'
            })
        }
        if (this.props.location.pathname === '/auth/care-taker/choose-ca') {
            this.setState({
                page: 'ca-assigned-cr-by-cg'
            })
        }
        window.setTimeout(() => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/firebase-messaging-sw.js')
                    .then((registration) => {
                        navigator.geolocation.getCurrentPosition((position) => {

                            var pos = {
                                coords: {
                                    accuracy: position.coords.accuracy,
                                    altitude: position.coords.altitude,
                                    altitudeAccuracy: position.altitudeAccuracy,
                                    heading: position.coords.heading,
                                    latitude: position.coords.latitude,
                                    longitude: position.coords.longitude,
                                    speed: position.coords.speed
                                }, timestamp: position.timestamp
                            }
                            console.log(position)
                            this.setState({ position: pos })
                            HTTPRequest(Constant.BASE_URL + '/save-token', 'application/json; charset=UTF-8', 'post', JSON.stringify({
                                'position': pos
                            })).then((response) => {
                            }).catch(e => {
                                if (e === 'Token is expired. Please login to continue.' || e === 'Please login to continue.') {
                                    this.props.navigate('/?error=Your token is expired.')
                                    this.signOut();
                                }
                            });
                        });
                        var firebaseConfig = {
                            messagingSenderId: "640013362865"
                        };
                        // Initialize Firebase
                        firebase.initializeApp(firebaseConfig);
                        var messaging = firebase.messaging();
                        messaging.useServiceWorker(registration);
                        /*initializePush(messaging)*/
                        Notification.requestPermission().then((permission) => {
                            if (permission === 'granted') {
                                console.log('Notification permission granted.');
                                AddEvents(this.state.user_type, 'Notification granted', 'Notification');
                                messaging.getToken().then((currentToken, err) => {
                                    if (currentToken) {
                                        console.log(currentToken)
                                        HTTPRequest(Constant.BASE_URL + '/save-token', 'application/json; charset=UTF-8', 'post', JSON.stringify({
                                            'device_token': currentToken
                                        })).then((response) => {
                                        }).catch(e => {
                                            if (e === 'Token is expired. Please login to continue.' || e === 'Please login to continue.') {
                                                this.props.navigate('/?error=Your token is expired.')
                                                this.signOut();
                                            }
                                        });
                                        // alert(currentToken)
                                        // sendTokenToServer(currentToken);
                                        // updateUIForPushEnabled(currentToken);
                                    } else {
                                        AddEvents(this.state.user_type, 'No notification token available', 'Notification');
                                        // Show permission request.
                                        console.log('No Instance ID token available. Request permission to generate one.');
                                        // Show permission UI.
                                        // updateUIForPushPermissionRequired();
                                        // setTokenSentToServer(false);
                                    }
                                }).catch((err) => {
                                    AddEvents(this.state.user_type, 'Notification', 'Notification declined');
                                    console.log('An error occurred while retrieving token. ', err);
                                    // showToken('Error retrieving Instance ID token. ', err);
                                    // setTokenSentToServer(false);
                                });
                                messaging.onMessage((data) => {
                                    AddEvents(this.state.user_type, 'Notification message show', 'Notification');

                                    console.log(data)
                                    const title = data.data.title;
                                    const options = {
                                        body: JSON.parse(data.data.body).message,
                                        vibrate: [500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110, 170, 40, 500],
                                        icon: data.data.icon,
                                    };
                                    if (data.data.click_action) {
                                        options['click_action'] = data.data.click_action;
                                    }
                                    /*this.setState({
                                        open: true,
                                        message: JSON.parse(data.data.body).message
                                    })*/
                                    window.$('#snackbar').html(JSON.parse(data.data.body).message);
                                    // Get the snackbar DIV
                                    var x = document.getElementById("snackbar");

                                    // Add the "show" class to DIV
                                    x.className = "show";

                                    // After 3 seconds, remove the show class from DIV
                                    setTimeout(() => {
                                        x.className = x.className.replace("show", "");
                                    }, 3000);
                                    if (JSON.parse(data.data.body).task) {
                                        try {
                                            var bc = new BroadcastChannel('sahaaya_channel');
                                            bc.postMessage(JSON.parse(data.data.body).message);
                                        } catch (e) {
                                            console.log(e);
                                        }
                                    }
                                    registration.showNotification(title, options);
                                }, (error) => {


                                })
                            } else {
                                AddEvents(this.state.user_type, 'Notification error', 'Notification');
                                console.log('Unable to get permission to notify.');
                            }
                        }).catch(e => {

                        });

                        console.log("This service worker is registered")
                    }).catch((err) => {
                        if (err && err.message && err.code && err.code === 'messaging/unsupported-browser') {
                            AddEvents(this.state.user_type, 'Browser does not support', 'Notification');
                            window.$('#snackbar').html('Your browser does not support notification.');
                            // Get the snackbar DIV
                            var x = document.getElementById("snackbar");

                            // Add the "show" class to DIV
                            x.className = "show";

                            // After 3 seconds, remove the show class from DIV
                            setTimeout(() => {
                                x.className = x.className.replace("show", "");
                            }, 3000);
                        }
                        console.log('Service worker registration failed, error:', err);
                    });
            }
        }, 1000);
    }

    render() {
        window.$('[data-toggle="tooltip"]').tooltip();
        return (
            <div>
                <div id="snackbar">Some text some message..</div>
                <div className="clearfix"></div>
                <div className="overlay-fix loading">
                    <div className="loader-img">
                        <img src="/assets/images/logo.png" alt="Sahaaya" width="250px" className="img-responsive" />
                    </div>
                </div>
                <nav className="navbar navbar-default innerpage-navigation">
                    <div className="container" style={{ paddingTop: 5 }}>
                        <div className="container-fluid">
                            <div className="navbar-header" style={{ width: '100%', marginLeft: 0, marginRight: 0 }}>
                                {this.state.user_type === "Care Taker" &&
                                    <div className="row">
                                        <div className="col-md-2 col-sm-2 col-xs-2" style={{ paddingTop: 10 }}
                                            onClick={() => {
                                                this.setState({ page: 'home' })
                                            }}>
                                            {this.state.page !== 'home' && this.state.page === 'insight' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'care-receivers' && this.props.location.pathname === '/auth/care-taker/care-receivers' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}

                                            {this.state.page !== 'home' && this.state.page === 'ca-care-receivers' && this.props.location.pathname === '/auth/care-admin/care-receivers' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}

                                            {this.state.page !== 'home' && (this.state.page === 'care-receivers' || this.state.page === 'add-care-receiver' || this.state.page === 'edit-care-receiver') && this.props.location.pathname !== '/auth/care-taker/care-receivers' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/care-receivers'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'health' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'profile' &&
                                                <Link className=" main-header-content" to='/auth/view/profile'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'view-profile' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'payment' &&
                                                <Link className=" main-header-content" to='/auth/view/profile'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'medminder' &&
                                                <Link className=" main-header-content" to='/auth/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'progressive-dashboard' &&
                                                <Link className=" main-header-content" to={this.state.user_type === 'Care Taker' ? '/auth/care-taker/dashboard' : '/auth/dashboard'}>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'ca-edit-cr' &&
                                                <Link className=" main-header-content" to={'/auth/care-admin/edit-care-receiver'}>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'ca-manage-cr' &&
                                                <Link className=" main-header-content" to={'/auth/care-admin/manage-care-receivers'}>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'ct-cr-profile-setup' &&
                                                <Link className=" main-header-content" to={'/auth/care-taker/care-receivers'}>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'ca-assigned-cr-by-cg' &&
                                                <Link className=" main-header-content" to={'/auth/care-taker/care-receivers'}>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                        </div>
                                        <div className="col-md-8 col-sm-8 col-xs-8 text-center" style={{ paddingTop: 10 }}>
                                            {this.state.page === 'home' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/dashboard'>
                                                    Home

                                        </Link>}
                                            {/*{this.state.page === 'insight' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/insight'
                                                    onClick={() => {
                                                        this.setState({ page: 'insight' })
                                                    }}>
                                                    Alerts
                                        </Link>}*/}
                                            {(this.state.page === 'care-receivers' && this.props.location.pathname === '/auth/care-taker/care-receivers') &&
                                                <Link className=" main-header-content" to='/auth/care-taker/care-receivers'
                                                    onClick={() => {
                                                        this.setState({ page: 'care-receivers' })
                                                    }}>
                                                    Care Receiver(s)
                                        </Link>}
                                            {this.state.page === 'add-care-receiver' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/add-care-receiver'
                                                    onClick={() => {
                                                        this.setState({ page: 'add-care-receiver' })
                                                    }}>
                                                    Care Receiver(s) Add
                                        </Link>}
                                            {(this.state.page === 'edit-care-receiver' || this.props.location.pathname === '/auth/care-taker/edit-care-receiver') &&
                                                <Link className=" main-header-content" to='/auth/care-taker/care-receivers'
                                                    onClick={() => {
                                                        this.setState({ page: 'care-receivers' })
                                                    }}>
                                                    Care Receiver(s) edit
                                        </Link>}
                                            {(this.state.page === 'health' || this.props.location.pathname === '/auth/health') &&
                                                <Link className=" main-header-content" to='/auth/health'
                                                    onClick={() => {
                                                        this.setState({ page: 'health' })
                                                    }}>
                                                    Activity
                                        </Link>}
                                            {(this.state.page === 'profile' || this.props.location.pathname === '/auth/edit/profile') &&
                                                <Link className=" main-header-content" to='/auth/edit/profile'
                                                    onClick={() => {
                                                        this.setState({ page: 'profile' })
                                                    }}>
                                                    Profile
                                        </Link>}
                                            {(this.state.page === 'view-profile' || this.props.location.pathname === '/auth/view/profile') &&
                                                <Link className=" main-header-content" to='/auth/view/profile'
                                                    onClick={() => {
                                                        this.setState({ page: 'view-profile' })
                                                    }}>
                                                    Profile
                                        </Link>}
                                            {(this.state.page === 'payment' || this.props.location.pathname === '/auth/care-taker/payment') &&
                                                <Link className=" main-header-content" to='/auth/care-taker/payment?redirect=profile'
                                                    onClick={() => {
                                                        this.setState({ page: 'payment' })
                                                    }}>
                                                    Payment
                                        </Link>}
                                            {this.state.page === 'progressive-dashboard' &&
                                                <Link className=" main-header-content" to='/auth/progressive-dashboard'
                                                    onClick={() => {
                                                        this.setState({ page: 'progressive-dashboard' })
                                                    }}>
                                                    See History
                                                </Link>}
                                            {this.state.page === 'ct-cr-profile-setup' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/cr-profile-setup'
                                                    onClick={() => {
                                                        this.setState({ page: 'ct-cr-profile-setup' })
                                                    }}>
                                                    Manage Care Receiver's Account
                                                </Link>}
                                            {this.state.page === 'ca-assigned-cr-by-cg' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/choose-ca'
                                                    onClick={() => {
                                                        this.setState({ page: 'ca-assigned-cr-by-cg' })
                                                    }}>
                                                    Assign Care Admin
                                                </Link>}
                                            {/* {this.state.page === 'ca-edit-cr' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/edit-care-receiver'
                                                      onClick={() => {
                                                          this.setState({ page: 'ca-edit-cr' })
                                                      }}>
                                                    Edit Care Receiver(s)
                                                </Link>} */}

                                        </div>
                                        <div className="col-md-2 col-sm-2 col-xs-2 text-right" style={{ paddingTop: 10 }}>
                                            {/*{this.state.page === 'home' &&
                                                <Link className=" main-header-content text-right cust-a"
                                                    onClick={() => {
                                                        this.setState({ page: 'insight' })
                                                    }}
                                                    to='/auth/care-taker/insight'>
                                                    <img src='/assets/images/alert.png' className="insight-image" />
                                                    <div className="mini-image-font">ALERTS</div>
                                                </Link>}*/}
                                            {this.props.location.pathname === '/auth/care-taker/care-receivers' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/add-care-receiver'
                                                    onClick={() => {
                                                        this.setState({ page: 'add-care-receiver' })
                                                    }}>
                                                    <img src='/assets/images/plus.png' className="insight-image" />
                                                </Link>}
                                        </div>
                                    </div>}
                                {this.state.user_type === "Care Receiver" &&
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4 col-xs-4" style={{ paddingTop: 10 }}
                                            onClick={() => {
                                                this.setState({ page: 'home' })
                                            }}>
                                            {this.state.page !== 'home' && this.state.page !== 'profile' &&
                                                <Link className=" main-header-content" to='/auth/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'profile' &&
                                                <Link className=" main-header-content" to='/auth/view/profile'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-4 text-center" style={{ paddingTop: 10 }}>
                                            {this.state.page === 'home' &&
                                                <Link className=" main-header-content" to='/auth/dashboard'>
                                                    Home

                                          {/* <div className={"col-md-5 col-sm-2 " + (window.innerWidth < 450 ? 'col-xs-2' : 'col-xs-3')}>
                                                <a onClick={this.EmergencyCallService.bind(this)} className="simple-button"
                                                   href={'tel:' + this.state.phoneNumber}>
                                                    <img src="/assets/images/call.png"/>
                                                </a>
                                            </div>*/}
                                                </Link>}
                                            {/* {this.state.page === 'insight' &&
                                                <Link className=" main-header-content" to='/auth/care-taker/insight'
                                                    onClick={() => {
                                                        this.setState({ page: 'insight' })
                                                    }}>
                                                    Alerts
                                                </Link>}*/}
                                            {this.state.page === 'health' &&
                                                <Link className=" main-header-content" to='/auth/health'
                                                    onClick={() => {
                                                        this.setState({ page: 'health' })
                                                    }}>
                                                    Activity
                                                </Link>}
                                            {this.state.page === 'profile' &&
                                                <Link className=" main-header-content" to='/auth/edit/profile'
                                                    onClick={() => {
                                                        this.setState({ page: 'profile' })
                                                    }}>
                                                    Profile
                                                </Link>}
                                            {this.state.page === 'view-profile' &&
                                                <Link className=" main-header-content" to='/auth/view/profile'
                                                    onClick={() => {
                                                        this.setState({ page: 'view-profile' })
                                                    }}>
                                                    Profile
                                                </Link>}
                                            {this.state.page === 'medminder' &&
                                                <Link className=" main-header-content" to='/auth/med-minder'
                                                    onClick={() => {
                                                        this.setState({ page: 'medminder' })
                                                    }}>
                                                    Med Minder
                                                </Link>}
                                            {this.state.page === 'progressive-dashboard' &&
                                                <Link className=" main-header-content" to='/auth/progressive-dashboard'
                                                    onClick={() => {
                                                        this.setState({ page: 'progressive-dashboard' })
                                                    }}>
                                                    See History
                                                </Link>}
                                            {this.state.page === 'ca-edit-cr' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/edit-care-receiver'
                                                    onClick={() => {
                                                        this.setState({ page: 'ca-edit-cr' })
                                                    }}>
                                                    Edit Care Receiver
                                                </Link>}
                                            {this.state.page === 'ca-manage-cr' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/manage-care-receivers'
                                                    onClick={() => {
                                                        this.setState({ page: 'ca-manage-cr' })
                                                    }}>
                                                    Edit Care Receiver(s)
                                                </Link>}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-4 text-right">
                                            {this.state.page === 'home' &&
                                                <>
                                                    <div className="col-md-8 col-sm-8 col-xs-6 text-right" />
                                                    <div className="col-md-4 col-sm-4 col-xs-6 text-right">
                                                        <a onClick={this.EmergencyCallService.bind(this)} className="simple-button"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="Clicking S.O.S will call and message your Care Giver and Trust Circle. It does not call Emergency services."
                                                            href={'tel:' + this.state.phoneNumber} style={{ marginRight: 7, backgroundColor: 'red' }}>
                                                            {/*<img src="/assets/images/call.png" />*/}
                                                            <div className={'sos-btn'}>
                                                                S.O.S
                                                        </div>
                                                        </a>
                                                    </div></>}
                                            {/*{this.state.page === 'home' &&
                                                <div className="col-md-2 col-sm-2 col-xs-6 text-right" style={{ paddingTop: 10 }}>
                                                    <Link className=" main-header-content text-right cust-a" style={{ paddingLeft: 6 }}
                                                        onClick={() => {
                                                            this.setState({ page: 'insight' })
                                                        }}
                                                        to='/auth/care-receiver/insight'>
                                                        <img src='/assets/images/alert.png' className="insight-image" />
                                                        <div className="mini-image-font">ALERTS</div>
                                                    </Link>
                                                </div>}*/}
                                        </div>
                                    </div>
                                }
                                {this.state.user_type === "Care Admin" &&
                                    <div className="row">
                                        <div className="col-md-2 col-sm-2 col-xs-2" style={{ paddingTop: 10 }}
                                            onClick={() => {
                                                this.setState({ page: 'home' })
                                            }}>
                                            {this.state.page !== 'home' && this.state.page === 'insight' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'ca-care-receivers' && this.props.location.pathname === '/auth/care-admin/care-receivers' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && (this.state.page === 'care-receivers' || this.state.page === 'add-care-receiver' || this.state.page === 'edit-care-receiver') && this.props.location.pathname !== '/auth/care-admin/care-receivers' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/care-receivers'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'health' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'profile' &&
                                                <Link className=" main-header-content" to='/auth/view/profile'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'view-profile' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'payment' &&
                                                <Link className=" main-header-content" to='/auth/view/profile'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'medminder' &&
                                                <Link className=" main-header-content" to='/auth/dashboard'>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'progressive-dashboard' &&
                                                <Link className=" main-header-content" to={this.state.user_type === 'Care Admin' ? '/auth/care-admin/dashboard' : '/auth/dashboard'}>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'ca-edit-cr' &&
                                                <Link className=" main-header-content" to={'/auth/care-admin/manage-care-receivers'}>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'ca-manage-cr' &&
                                                <Link className=" main-header-content" to={'/auth/care-admin/dashboard'}>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                            {this.state.page !== 'home' && this.state.page === 'ca-cr-profile-setup' &&
                                                <Link className=" main-header-content" to={'/auth/care-admin/manage-care-receivers'}>
                                                    {/*&lt;*/}
                                                    <img src='/assets/images/back-btn.png' width="35px" />
                                                </Link>}
                                        </div>
                                        <div className="col-md-8 col-sm-8 col-xs-8 text-center" style={{ paddingTop: 10 }}>
                                            {this.state.page === 'home' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/dashboard'>
                                                    Home

                                        </Link>}
                                            {/*{this.state.page === 'insight' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/insight'
                                                    onClick={() => {
                                                        this.setState({ page: 'insight' })
                                                    }}>
                                                    Alerts
                                        </Link>}*/}
                                            {(this.state.page === 'care-receivers') &&
                                                <Link className=" main-header-content" to='/auth/care-admin/care-receivers'
                                                    onClick={() => {
                                                        this.setState({ page: 'care-receivers' })
                                                    }}>
                                                    Manage Care Receivers
                                        </Link>}
                                            {(this.state.page === 'ca-care-receivers') &&
                                                <Link className=" main-header-content" to='/auth/care-admin/care-receivers'
                                                    onClick={() => {
                                                        this.setState({ page: 'ca-care-receivers' })
                                                    }}>
                                                    Manage Request
                                        </Link>}
                                            {this.state.page === 'add-care-receiver' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/add-care-receiver'
                                                    onClick={() => {
                                                        this.setState({ page: 'add-care-receiver' })
                                                    }}>
                                                    Care Receiver(s) Add
                                        </Link>}
                                            {(this.state.page === 'edit-care-receiver' || this.props.location.pathname === '/auth/care-admin/edit-care-receiver') &&
                                                <Link className=" main-header-content" to='/auth/care-admin/care-receivers'
                                                    onClick={() => {
                                                        this.setState({ page: 'care-receivers' })
                                                    }}>
                                                    Care Receiver(s) edit
                                        </Link>}
                                            {(this.state.page === 'health' || this.props.location.pathname === '/auth/health') &&
                                                <Link className=" main-header-content" to='/auth/health'
                                                    onClick={() => {
                                                        this.setState({ page: 'health' })
                                                    }}>
                                                    Activity
                                        </Link>}
                                            {(this.state.page === 'profile' || this.props.location.pathname === '/auth/edit/profile') &&
                                                <Link className=" main-header-content" to='/auth/edit/profile'
                                                    onClick={() => {
                                                        this.setState({ page: 'profile' })
                                                    }}>
                                                    Profile
                                        </Link>}
                                            {(this.state.page === 'view-profile' || this.props.location.pathname === '/auth/view/profile') &&
                                                <Link className=" main-header-content" to='/auth/view/profile'
                                                    onClick={() => {
                                                        this.setState({ page: 'view-profile' })
                                                    }}>
                                                    Profile
                                        </Link>}
                                            {(this.state.page === 'payment' || this.props.location.pathname === '/auth/care-admin/payment') &&
                                                <Link className=" main-header-content" to='/auth/care-admin/payment?redirect=profile'
                                                    onClick={() => {
                                                        this.setState({ page: 'payment' })
                                                    }}>
                                                    Payment
                                        </Link>}
                                            {this.state.page === 'progressive-dashboard' &&
                                                <Link className=" main-header-content" to='/auth/progressive-dashboard'
                                                    onClick={() => {
                                                        this.setState({ page: 'progressive-dashboard' })
                                                    }}>
                                                    See History
                                                </Link>}
                                            {this.state.page === 'ca-manage-cr' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/manage-care-receivers'
                                                    onClick={() => {
                                                        this.setState({ page: 'ca-manage-cr' })
                                                    }}>
                                                    Manage Care Receiver(s)
                                                </Link>}
                                            {this.state.page === 'ca-cr-profile-setup' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/cr-profile-setup'
                                                    onClick={() => {
                                                        this.setState({ page: 'ca-cr-profile-setup' })
                                                    }}>
                                                    Manage Care Receiver's Account
                                                </Link>}
                                            {/* {this.state.page === 'ca-edit-cr' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/edit-care-receiver'
                                                      onClick={() => {
                                                          this.setState({ page: 'ca-edit-cr' })
                                                      }}>
                                                    Edit Care Receiver(s)
                                                </Link>} */}

                                        </div>
                                        <div className="col-md-2 col-sm-2 col-xs-2 text-right" style={{ paddingTop: 10 }}>
                                            {/*{this.state.page === 'home' &&
                                                <Link className=" main-header-content text-right cust-a"
                                                    onClick={() => {
                                                        this.setState({ page: 'insight' })
                                                    }}
                                                    to='/auth/care-admin/insight'>
                                                    <img src='/assets/images/alert.png' className="insight-image" />
                                                    <div className="mini-image-font">ALERTS</div>
                                                </Link>}*/}
                                            {/* {this.props.location.pathname === '/auth/care-admin/care-receivers' &&
                                                <Link className=" main-header-content" to='/auth/care-admin/add-care-receiver'
                                                    onClick={() => {
                                                        this.setState({ page: 'add-care-receiver' })
                                                    }}>
                                                    <img src='/assets/images/plus.png' className="insight-image" />
                                                </Link>} */}
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="clearfix"></div>
                <div className="container">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default AuthTemplateComponent;
