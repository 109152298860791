import React, {Component} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
/*import DialogContentText from "@material-ui/core/DialogContentText";*/
import DialogActions from "@material-ui/core/DialogActions";
/*import Button from "@material-ui/core/Button";*/
import Dialog from "@material-ui/core/Dialog";
import {HTTPRequest} from "../noauth/common.service";
import Constant from "../constants";
import {AddEvents, Initialize} from "../AnalyticsEvent";

class CreateTaskModal extends Component {
    state = {
        message: '',
        category: 'Health',
        dueDate: '',
        errors: {},
        taskId: '',
        modelTitle: 'CREATE TASK',
        formName: {
            message: 'Message',
            category: 'Category',
            dueDate: 'Due Date'
        },
    };

    componentDidMount() {
        Initialize();
        console.log(this.props)
        console.log(this.state)
        if (this.props.task) {
            this.setState({
                taskId: this.props.task.id,
                message: this.props.task.message,
                dueDate: this.props.task.dueDate,
                category: this.props.task.category,
                modelTitle: 'EDIT TASK'
            });
        }
        if (window.$) {
            window.setTimeout(() => {
                window.$('.sandbox-container input').datepicker({
                    autoclose: true,
                    startDate: (new Date())
                });
            }, 200);
            window.$('#root').css('filter', 'blur(5px)')
        }
    }

    Validate() {
        var flag = false;
        for (let key in this.state.formName) {
            if (key) {
                if (!this.state[key]) {
                    const json = {};
                    json.errors = this.state.errors;
                    json.errors[key] = this.state.formName[key] + ' is required.';
                    this.setState(json);
                    flag = true;
                }
            }
        }
        return flag;
    }

    async submitForm(event) {
        if (this.Validate()) {
            return;
        }
        var accountsDetails = JSON.parse(window.localStorage.getItem('account_details'));
        try {
            if (accountsDetails.account_type === 'Care Taker' || accountsDetails.account_type === 'Care Admin') {
                /*window.ga('send', 'event', {
                    eventCategory: 'Care Taker',
                    eventAction: 'click',
                    eventLabel: 'Task ' + (this.state.taskId ? 'update' : 'create')
                });*/
                AddEvents('Care Taker', 'Task ' + (this.state.taskId ? 'update' : 'create'), 'Task ' + (this.state.taskId ? 'update' : 'create'));
                await HTTPRequest(Constant.BASE_URL + '/care-taker/tasks/create', 'application/json', 'post', JSON.stringify({
                    category: this.state.category,
                    message: this.state.message,
                    dueDate: this.state.dueDate,
                    id: this.state.taskId,
                    care_receiver_id: accountsDetails.selected_care_receiver ? accountsDetails.selected_care_receiver.id : (accountsDetails.care_receiver_account.length > 1 ? accountsDetails.care_receiver_account[0].id : accountsDetails.care_receiver_account.id),
                }));
            } else {
                AddEvents('Care Receiver', 'Task ' + (this.state.taskId ? 'update' : 'create'), 'Task ' + (this.state.taskId ? 'update' : 'create'));
                /*window.ga('send', 'event', {
                               eventCategory: 'Care Receiver',
                               eventAction: 'click',
                               eventLabel: 'Task ' + (this.state.taskId ? 'update' : 'create')
                           });
                           });*/
                await HTTPRequest(Constant.BASE_URL + '/care-receiver/tasks/create', 'application/json', 'post', JSON.stringify({
                    category: this.state.category,
                    message: this.state.message,
                    dueDate: this.state.dueDate,
                    id: this.state.taskId
                }))
            }
        } catch (e) {
            console.log(e)
        }
        if (this.state.taskId) {
            this.props.onClose('Task has been updated.')
        } else {
            this.props.onClose('Task has been created.')
        }
    }

    componentWillUnmount() {
        if (window.$) {
            window.$('#root').css('filter', 'none')
        }
    }

    ChangeEvent(event) {
        let json = {};
        const val = this.CheckErrors(event.target.name, event.target.value);
        json['errors'] = {}
        json.errors = this.state.errors;
        if (val) {
            json.errors[event.target.name] = val[event.target.name];
        } else {
            json.errors[event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        if (event.target.name === 'dueDate') {
            window.setTimeout(() => {
                console.log(this.state)
                json['dueDate'] = window.$('input[name=dueDate]').val();
                if (json.dueDate) {
                    json.errors.dueDate = null;
                }
                /*if(event.target.name === 'dueDate') {
                    
                    json[event.target.name] = window.$(event.target).val();
                }*/
                this.setState(json);
            }, 200);
        }
        json[event.target.name] = event.target.value ? event.target.value : '';
        this.setState(json);
    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return {[targetName]: this.state.formName[targetName] + ' is required.'};
        }
    }

    render() {
        const {fullScreen, open, onClose} = this.props;
        return (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={onClose}
                aria-labelledby="createTaskId"
            >
                <div className="cloading" style={{display: 'none'}}>Loading&#8230;</div>
                <DialogTitle id="createTaskId">{this.state.modelTitle}</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
                    </DialogContentText>*/}
                    <form className="signup-form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="control-label">Select Category <span className='required'>*</span></label>
                                    <div className="select-style">
                                        <select name="category" style={{background: 'rgba(0,0,0,0.1)'}}
                                                onChange={this.ChangeEvent.bind(this)}
                                                value={this.state.category}
                                                className={"form-control" + (this.state.errors.category ? ' error-border' : '')}>
                                            <option value="Health">Wellness</option>
                                            <option value="Food">Food</option>
                                            <option value="Finance">Money</option>
                                            <option value="Home">Home</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Type Message <span className='required'>*</span></label>
                                    <input name="message" type="text" placeholder="Type Message"
                                           value={this.state.message || ''}
                                           maxLength="50"
                                           onFocus={this.ChangeEvent.bind(this)}
                                           onChange={this.ChangeEvent.bind(this)}
                                           className={"form-control" + (this.state.errors.message ? ' error-border' : '')}/>
                                    {this.state.errors.message &&
                                    <span className='error'>{this.state.errors.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Due Date <span className='required'>*</span></label>
                                    <div className="sandbox-container">
                                        {/* <DatePicker
                                            name="dueDate"
                                            placeholderText="Due Date"
                                            className={"form-control" + (this.state.errors.dueDate ? ' error-border' : '')}
                                            selected={this.state.dueDate}
                                            onFocus={this.ChangeEvent.bind(this)}
                                            onBlur={this.ChangeEvent.bind(this)}
                                            onChange={this.ChangeEvent.bind(this)}
                                        />*/}
                                        <input name="dueDate" type="text" placeholder="Due Date"
                                               value={this.state.dueDate || ''}
                                               maxLength="15"
                                               onBlur={this.ChangeEvent.bind(this)}
                                               onFocus={this.ChangeEvent.bind(this)}
                                               onChange={this.ChangeEvent.bind(this)}
                                               className={"form-control" + (this.state.errors.dueDate ? ' error-border' : '')}/>
                                    </div>
                                    {this.state.errors.dueDate &&
                                    <span className='error'>{this.state.errors.dueDate}</span>}
                                </div>
                            </div>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions className="actions">
                    {/*<Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onClose} color="primary">
                        Save
                    </Button>*/}
                    <button onClick={this.submitForm.bind(this)}
                            className={"createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}>
                        Submit
                    </button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default CreateTaskModal;
