import React, {Component} from 'react';
import {Link} from '@reach/router'
import {CommonMethod} from "./common.service";
import Constant from "../constants";

class TrustedCircleComponent extends Component {
    state = {
        email: '',
        errors: {
            required: {},
            other: {}
        },
        formName: {
            email: 'Email'
        },
        apiError: null,
        apiSuccess: null,
        name: 'Sahaaya Care Receiver'
    };

    AcceptDeclineAction(value, event) {
        event.preventDefault();
        var data = {
            trusted_circle_id: this.props.trusted_circle_id,
            care_receiver_id: this.props.care_receiver_id,
            value,
        }
        this.AcceptDeclineService(JSON.stringify(data)).then((data2) => {
            this.setState({
                apiSuccess: data.value ? 'You have successfully accepted the invitation.' : 'Thanks you for your answer. You can change your mind anytime.'
            });
            // this.ResetForm();
            console.log(data2);
            if (window.ga) {
                window.ga('send', 'event', 'Trusted Circle', value, 'No-Auth');
            }
        }).catch(err => {
            this.setState({
                apiError: err.message
            });
            console.log(err.message);
            if (window.ga) {
                window.ga('send', 'event', 'Trusted Circle', 'error', 'No-Auth');
            }
        });
    }

    componentDidMount() {
        CommonMethod();
        var data = {
            trusted_circle_id: this.props.trusted_circle_id,
            care_receiver_id: this.props.care_receiver_id
        }
        
        this.CareReceiverService(JSON.stringify(data)).then(dta => {
            
            this.setState({name: dta.data.firstName + ' ' + dta.data.lastName});
        }).catch(e => {
            this.setState({name: 'Sahaaya Care Receiver'});
        })
    }


    render() {
        return (
            <div className="row">
                <div className="signup-block">
                    <div className="col-md-4"></div>
                    <div className="col-sm-12 col-md-4">
                        <div className="signup-form-cat">
                            <form className="signup-form">
                                {this.state.apiError &&
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.apiError}
                                        </div>
                                    </div>
                                </div>
                                }
                                {this.state.apiSuccess &&
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="alert alert-success" role="alert">
                                            {this.state.apiSuccess}
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="row">
                                    <div className='col-md-12'>
                                        <h2 className='text-center' style={{color: '#004265'}}>Accept/Decline</h2>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-md-12'>
                                        <p className='text-center' style={{color: '#004265'}}>
                                            {this.state.name} has added your number as an emergency contact in the Sahaaya app. In the event {this.state.name} uses the Emergency features, you may receive an SMS alert.
                                            {/*Sahaaya wants to send emergency SMS alerts.
                                            Please select Accept if you want to get SMS alerts.*/}
                                        </p>
                                        <p className='text-center' style={{color: '#004265'}}>
                                            Please Accept / Decline their request.
                                        </p>
                                    </div>
                                </div>

                                <div className="row center-block">
                                    <div className="col-md-6 col-sm-6 col-xs-6 custombutton">
                                        <button style={{backgroundColor: '#298523'}} onClick={this.AcceptDeclineAction.bind(this, true)}
                                                className="btn full-button">Accept
                                        </button>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-6 custombutton">
                                        <button style={{backgroundColor: '#c61717'}} onClick={this.AcceptDeclineAction.bind(this, false)}
                                                className="btn full-button">Decline
                                        </button>
                                    </div>
                                </div>
                                {/*<p className="text-center">Already have an account? &nbsp; &nbsp;
                                    <Link to='/login'>
                                        Login
                                    </Link>
                                </p>*/}
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

    CareReceiverService(data) {
        return fetch(Constant.BASE_URL + '/cr-name', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: data
        }).then(res => res.json()).then(response => {
            if (response.code >= 200 && response.code < 300) {
                return Promise.resolve(response)
            } else {
                return Promise.reject(new Error(response.error))
            }
        })
    }

    AcceptDeclineService(data) {
        return fetch(Constant.BASE_URL + '/approve-decline-trusted-circle', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: data
        }).then(res => res.json()).then(response => {
            if (response.code >= 200 && response.code < 300) {
                return Promise.resolve(response)
            } else {
                return Promise.reject(new Error(response.error))
            }
        })
            /*.then((data) => {
            this.setState({
                apiSuccess: 'Successfully login'
            });
            console.log(data);
        })*/;
    }
}

export default TrustedCircleComponent;
