import React, {Component} from 'react';
import {CommonMethod, HTTPRequest} from './common.service'
import Constant from '../constants';
import {Initialize} from "../AnalyticsEvent";
import CountryCode from "../country.code";


class ProfileSetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account_detail: {},
            daily_checkup_frequency: [{
                name: 'SMS',
                time: '06:00 AM',
                frequency: 'Daily',
                trustCircle: false,
                day: '',
                value: false,
            }, {
                name: 'Phone',
                time: '06:00 AM',
                frequency: 'Daily',
                trustCircle: false,
                day: '',
                value: false,
            }, {
                name: 'WhatsApp',
                time: '06:00 AM',
                frequency: 'Daily',
                trustCircle: false,
                day: '',
                value: false,
            },],
            formName: {
                old_password: 'Old Password',
                new_password: 'New Password',
                c_password: 'Confirm New Password',
                name: 'Name',
                phoneNumber: 'Phone number',
                email: 'Email',
            },
            trustedCircle: [],
            trustedCErrors: [],
            selected_care_receiver: {},
            parentControl: [],
            errors: {},
            showAddCAButton: false
        };
    }

    updateDimensions() {
        this.setState({
            deviceWidth: window.innerWidth,
            deviceHeight: window.innerHeight,
        });
    }

    async InitParentControl(account) {
        let planData = await HTTPRequest(Constant.BASE_URL + '/myplan', 'application/json', 'post', JSON.stringify({planName: account.plan_type,account_type: 'Care Receiver'}));
        
        let myavailableplans = [];
        if (planData) {
            myavailableplans = planData.data.apps;
        }
        this.setState({myavailableplans})
        if (account.account_type === 'Care Receiver') {
        } else {
            this.state.parentControl = [
                {name: 'Task', displayName: 'To Do List', allow: true, disable: !myavailableplans.includes('To Do List')},
                {name: 'My Offers', displayName: 'My Offers', allow: true, disable: !myavailableplans.includes('My Offers')},
                {name: 'Insights', displayName: 'Tips & Insights', allow: false, disable: !myavailableplans.includes('Tips & Insights')},
                {name: 'Reading Corner', displayName: 'Reading Corner', allow: false, disable: !myavailableplans.includes('Reading Corner')},
                {name: 'Medicine', displayName: 'Meds Minder', allow: false, disable: !myavailableplans.includes('Meds Minder')},
                {name: 'Memory Bucket', allow: false, displayName: 'Memory Bucket', disable: !myavailableplans.includes('Memory Bucket')},
                {name: 'Activity', displayName: 'Activity Tracker', allow: false, disable: !myavailableplans.includes('Activity Tracker')},
                {name: 'Community Insights', displayName: 'Get Social', allow: false, disable: !myavailableplans.includes('Get Social')},
                {name: 'Trust Circle', displayName: 'Trust Circle', allow: false, disable: !myavailableplans.includes('Trust Circle')}
            ];
        }
        
        if (!account.parent_control) {
            account.parent_control = this.state.parentControl;
            this.setState({parentControl: this.state.parentControl});
            /*for (let i = 0; i < account.parent_control.length; i++) {
                        account.parent_control[i].allow = true;
                    }*/
        } else {
            for (var i = 0; i < account.parent_control.length; i++) {
                for (var j = 0; j < this.state.parentControl.length; j++) {
                    if (account.parent_control[i].name === this.state.parentControl[j].name) {
                        account.parent_control[i].displayName = this.state.parentControl[j].displayName;
                        this.state.parentControl[j].name = account.parent_control[i].name;
                        this.state.parentControl[j].displayName = account.parent_control[i].displayName;
                        if (this.state.parentControl[j].disable) {
                            this.state.parentControl[j].allow = false;
                        } else {
                            this.state.parentControl[j].allow = account.parent_control[i].allow;
                        }
                        /*this.state.parentControl[j].disable = account.parent_control[i].disable;*/
                        break;
                    }
                }
            }
            this.setState({parentControl: this.state.parentControl});
        }

        var parent_control = {}
        if (this.state.parentControl) {
            for (let i = 0; i < this.state.parentControl.length; i++) {
                parent_control[this.state.parentControl[i].name] = this.state.parentControl[i].allow
            }
            
            this.state.parent_control = parent_control;
            this.setState({parent_control});
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        Initialize();
        CommonMethod();
        this.updateDimensions();

        if (!(window.localStorage.getItem('account_details') || window.localStorage.getItem('token'))) {
            window.setTimeout(() => {
                this.props.navigate('/');
            }, 200);
            return;
        }
        var account = JSON.parse(window.localStorage.getItem('account_details'));
        account.pulse_check = true;
        /*if (account.account_type === 'Care Receiver') {
        } else {
            this.state.parentControl = [
                {name: 'Task', displayName: 'To Do List', allow: true},
                {name: 'My Offers', displayName: 'My Offers', allow: true},
                {name: 'Insights', displayName: 'Tips & Insights', allow: false},
                {name: 'Reading Corner', displayName: 'Reading Corner', allow: false},
                {name: 'Medicine', displayName: 'Meds Reminder', allow: false},
                {name: 'Memory Bucket', allow: false, displayName: 'Memory Bucket'},
                {name: 'Activity', displayName: 'Activity Tracker', allow: false},
                {name: 'Community Insights', displayName: 'Get Social', allow: false},
                {name: 'Trust Circle', displayName: 'Trust Circle', allow: false},
            ]
        }
        if (!account.parent_control) {
            account.parent_control = this.state.parentControl;
            // for (let i = 0; i < account.parent_control.length; i++) {
                // account.parent_control[i].allow = true;
            // }
        } else {
            for (var i = 0; i < account.parent_control.length; i++) {
                for (var j = 0; j < this.state.parentControl.length; j++) {
                    if (account.parent_control[i].name === this.state.parentControl[j].name) {
                        account.parent_control[i].displayName = this.state.parentControl[j].displayName;
                        this.state.parentControl[j] = account.parent_control[i];
                        break;
                    }
                }
            }
            this.setState({parentControl: this.state.parentControl})
        }*/
        this.InitParentControl(account);
        if (!account.daily_checkup_frequency) {
            account.daily_checkup_frequency = this.state.daily_checkup_frequency;
        } else {
            this.state.daily_checkup_frequency = account.daily_checkup_frequency;
            this.setState({daily_checkup_frequency: this.state.daily_checkup_frequency})
        }
        if (account.trustedCircle) {
            for (let i = 0; i < account.trustedCircle.length; i++) {
                this.AddTrustedCircle(account.trustedCircle[i]);
            }
            this.setState({
                isCareReceiver: true,
                account_detail: account,
                trustedCircle: account.trustedCircle,
            });
        } else {
            this.AddTrustedCircle();
            this.setState({
                isCareReceiver: true,
                account_detail: account,
            });
        }
        if (account.care_receiver_account.length > 0) {
            account.selected_care_receiver = account.care_receiver_account[0];
            this.setState({selected_care_receiver: account.care_receiver_account[0]})
        }
        
        if(account.care_receiver_account.length == 1) {
            this.setState({ showAddCAButton: true })
        }
        this.setState({account_detail: account})
        window.setTimeout(() => {
            window.$('#smsalerttime').datetimepicker({
                format: 'LT',
                stepping: 15,
                defaultDate: new Date()
            });
            window.$('#smsalerttime').on('dp.change', (event) => {
                for (let i = 0; i < this.state.daily_checkup_frequency.length; i++) {
                    if (this.state.daily_checkup_frequency[i].name === 'SMS') {
                        
                        this.state.daily_checkup_frequency[i].time = event.target.value;
                        this.setState({daily_checkup_frequency: this.state.daily_checkup_frequency})
                        break;
                    }
                }
            })
            window.$('#phonealerttime').datetimepicker({
                format: 'LT',
                stepping: 15,
                defaultDate: new Date()
            });
            window.$('#phonealerttime').on('dp.change', (event) => {
                for (let i = 0; i < this.state.daily_checkup_frequency.length; i++) {
                    if (this.state.daily_checkup_frequency[i].name === 'Phone') {
                        
                        this.state.daily_checkup_frequency[i].time = event.target.value;
                        this.setState({daily_checkup_frequency: this.state.daily_checkup_frequency})
                        break;
                    }
                }
            });
            window.$('#whatsappalerttime').datetimepicker({
                format: 'LT',
                stepping: 15,
                defaultDate: new Date()
            });
            window.$('#whatsappalerttime').on('dp.change', (event) => {
                for (let i = 0; i < this.state.daily_checkup_frequency.length; i++) {
                    if (this.state.daily_checkup_frequency[i].name === 'WhatsApp') {
                        
                        this.state.daily_checkup_frequency[i].time = event.target.value;
                        this.setState({daily_checkup_frequency: this.state.daily_checkup_frequency})
                        break;
                    }
                }
            });

        }, 200)
    }

    RemoveTrustedCircle(index, event) {
        var trustedCircle = this.state.trustedCircle;
        trustedCircle.splice(index, 1);
        var trustedCErrors = this.state.trustedCErrors;
        trustedCErrors.splice(index, 1);
        this.setState({trustedCircle, trustedCErrors})
    }

    PulseCheckBlock() {
        return (
            <div className="row myProfile" style={{paddingBottom: 30, marginLeft: 0, marginRight: 0}}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left'}}>
                    <p className="myProfileText">Pulse Check Settings</p>
                    <p className="myProfileDescriptionText">Pulse Check is a powerful tool that will check-in with
                        your loved ones on a regular basis to ensure they are doing OK. You can setup Sahaaya to
                        connect with them via a phone call, text message or both! Please set up your preferences
                        below:</p>
                    <hr/>
                </div>

                {this.state.daily_checkup_frequency.map((item, index) => {
                    return (
                        <div className='row' key={index} style={{textAlign: 'center', margin: 30}}>
                            <div className="" style={{marginBottom: 20}}>
                                <div className="col-md-3 col-sm-6 col-xs-6 text-left">
                                    <div
                                        className="form-group">
                                        <label>{item.name} Frequency</label>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-6 col-xs-6 text-left">
                                    <div className="form-group">
                                        <label className="switch">
                                            <input type="checkbox" name='checkbox'
                                                   checked={this.state.daily_checkup_frequency[index].value}
                                                   onChange={this.PhoneSmsFrequency.bind(this, index)}
                                            />
                                            <span className="slider-profile round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-6 col-xs-6 text-left">
                                    <div
                                        className="form-group">
                                        <label><input name='careGiver' type='checkbox' disabled={true} checked={true}/> Care Giver</label>
                                        <label><input name='trustCircle' type='checkbox'
                                                      checked={this.state.daily_checkup_frequency[index].trustCircle}
                                            // value={this.state.daily_checkup_frequency[index].trustCircle}
                                            // onFocus={this.PhoneSmsFrequency.bind(this, index)}
                                                      onChange={this.PhoneSmsFrequency.bind(this, index)}
                                                      disabled={!this.state.daily_checkup_frequency[index].value}/> Trust Circle</label>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <select name='frequency' type="text"
                                                placeholder="Frequency"
                                                disabled={!this.state.daily_checkup_frequency[index].value}
                                                value={this.state.daily_checkup_frequency[index].frequency}
                                                onFocus={this.PhoneSmsFrequency.bind(this, index)}
                                                onChange={this.PhoneSmsFrequency.bind(this, index)}
                                                className={"form-control " + (this.state.errors.smsAlert && 'error-border')}>
                                            <option>Daily</option>
                                            <option>Two Days</option>
                                            <option>Three Days</option>
                                            <option>Weekly</option>
                                        </select>
                                        {this.state.errors.smsAlert &&
                                        <span className='error'>{this.state.errors.smsAlert}</span>}
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <input name='time' id={item.name.toLowerCase() + 'alerttime'} type="text"
                                               placeholder="Time"
                                               value={this.state.daily_checkup_frequency[index].time}
                                               readOnly={!this.state.daily_checkup_frequency[index].value}
                                               onFocus={this.PhoneSmsFrequency.bind(this, index)}
                                               onChange={this.PhoneSmsFrequency.bind(this, index)}
                                               className={"form-control " + (this.state.errors.smsAlert && 'error-border')}/>
                                        {this.state.errors.smsAlert &&
                                        <span className='error'>{this.state.errors.smsAlert}</span>}
                                    </div>
                                </div>
                                {item.value && item.frequency === 'Weekly' &&
                                <div className="col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <select name='day' type="text"
                                                placeholder="Day"
                                                value={this.state.daily_checkup_frequency[index].day}
                                                onFocus={this.PhoneSmsFrequency.bind(this, index)}
                                                onChange={this.PhoneSmsFrequency.bind(this, index)}
                                                className={"form-control " + (this.state.errors.smsAlert && 'error-border')}>
                                            <option>Monday</option>
                                            <option>Tuesday</option>
                                            <option>Wednesday</option>
                                            <option>Thursday</option>
                                            <option>Friday</option>
                                            <option>Saturday</option>
                                            <option>Sunday</option>
                                        </select>
                                        {this.state.errors.smsAlert &&
                                        <span className='error'>{this.state.errors.smsAlert}</span>}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    )
                })}
                <div className="row" style={{marginTop: 20}}>
                    <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center "}
                         style={{textAlign: 'center'}}>
                        <button
                            onClick={this.SavePhoneSmsFrequency.bind(this, null)}
                            className={"btn-margin12 createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}>
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    async SavePhoneSmsFrequency() {
        var daily_checkup_frequency = this.state.daily_checkup_frequency;
        var date = new Date();
        for (let i = 0; i < daily_checkup_frequency.length; i++) {
            var dt = date.getFullYear() + '/' + (date.getMonth() + 1) + "/" + date.getDate();
            var utc_hour = new Date(dt + " " + daily_checkup_frequency[i].time).getUTCHours();
            var utc_min = new Date(dt + " " + daily_checkup_frequency[i].time).getUTCMinutes();
            
            var utc_time = utc_hour + ":" + utc_min;
            daily_checkup_frequency[i].utc_time = utc_time;
            daily_checkup_frequency[i].utc_hour = utc_hour;
            daily_checkup_frequency[i].utc_min = utc_min;
            for (let key in daily_checkup_frequency[i]) {
                if (key && !daily_checkup_frequency[i][key] && key !== 'value' && key !== 'trustCircle') {
                    delete daily_checkup_frequency[i][key];
                }
            }
            if (!daily_checkup_frequency[i].utc_min) {
                daily_checkup_frequency[i].utc_min = 0
            }
            /*if (!daily_checkup_frequency[i].time) {
                delete daily_checkup_frequency[i].time;
            }*/
            var dt2 = date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + "/" + date.getUTCDate();
            daily_checkup_frequency[i].utc_date = new Date(date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + "/" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes()).getTime();
        }
        this.state.account_detail.daily_checkup_frequency = daily_checkup_frequency;
        
        /*var timezone = '';
        if (Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions().timeZone) {
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }*/
        var data = {};
        data.data = daily_checkup_frequency
        data.care_receiver_id = this.state.selected_care_receiver.id
        daily_checkup_frequency = await HTTPRequest(Constant.BASE_URL + '/frequency-control', 'application/json; charset=UTF-8', 'post', JSON.stringify(data));
        this.setState({account_detail: this.state.account_detail});
        window.localStorage.setItem('account_details', JSON.stringify(this.state.account_detail));
    }

    async PhoneSmsFrequency(index, event) {
        if (!this.state.account_detail.daily_checkup_frequency) {
            this.state.account_detail.daily_checkup_frequency = this.state.daily_checkup_frequency;
        }

        if (event.target.name === 'careGiver') {
            this.state.daily_checkup_frequency[index].careGiver = event.target.checked;
        } else  if (event.target.name === 'trustCircle') {
            this.state.daily_checkup_frequency[index].trustCircle = event.target.checked;
        } else  if (event.target.name === 'checkbox') {
            this.state.account_detail.daily_checkup_frequency[index].value = event.target.checked;
            if (event.target.checked) {
                try {
                    window.$("#" + index ? "smsalerttime" : "phonealerttime").focus();
                    window.$("#" + index ? "smsalerttime" : "phonealerttime")[0].focus();
                } catch (e) {

                }
                // this.state.account_detail.daily_checkup_frequency[index]['time'] = '00:00 PM';
            }
        } else {
            this.state.account_detail.daily_checkup_frequency[index][event.target.name] = event.target.value;
        }
        this.setState({daily_checkup_frequency: this.state.account_detail.daily_checkup_frequency});
    }

    TrustCircle() {
        return (
            <div className="row myProfile" style={{paddingBottom: 30, marginLeft: 0, marginRight: 0}}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left'}}>
                    <p className="myProfileText">Trust Circle</p>
                    <hr/>
                </div>
                <div className='' style={{margin: 30}}>
                    {this.state.trustedCircle.map((item, index) => {
                        return (
                            <form className=""
                                  style={{marginBottom: 20}}
                                  key={index}>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div
                                            className="form-group">

                                            {/*<label>Time <span className='required'>*</span></label>*/}
                                            <input name='name' type="text"
                                                   placeholder="Name"
                                                   value={this.state.trustedCircle[index].name}
                                                   onFocus={this.TrustedCircleChangeEvent.bind(this, index)}
                                                   onChange={this.TrustedCircleChangeEvent.bind(this, index)}
                                                   className={"form-control " + (this.state.trustedCErrors[index].name && 'error-border')}/>
                                            {this.state.trustedCErrors[index].name &&
                                            <span className='error'>{this.state.trustedCErrors[index].name}</span>}
                                        </div>
                                    </div>
                                    {/*<div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Time <span className='required'>*</span></label>
                                            <input name='email' type="text" placeholder="Email"
                                                   value={this.state.trustedCircle[index].email}
                                                   disabled={this.state.trustedCircle[index].alertEnable}
                                                   onFocus={this.TrustedCircleChangeEvent.bind(this, index)}
                                                   onChange={this.TrustedCircleChangeEvent.bind(this, index)}
                                                   className={"form-control " + (this.state.trustedCErrors[index].email && 'error-border')}/>
                                            {this.state.trustedCErrors[index].email &&
                                            <span className='error'>{this.state.trustedCErrors[index].email}</span>}
                                        </div>
                                    </div>*/}
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            {/*<label>Time <span className='required'>*</span></label>*/}
                                            <input name='email' type="text" placeholder="Email"
                                                   value={this.state.trustedCircle[index].email}
                                                   disabled={this.state.trustedCircle[index].alertEnable}
                                                   onFocus={this.TrustedCircleChangeEvent.bind(this, index)}
                                                   onChange={this.TrustedCircleChangeEvent.bind(this, index)}
                                                   className={"form-control " + (this.state.trustedCErrors[index].email && 'error-border')}/>
                                            {this.state.trustedCErrors[index].email &&
                                            <span className='error'>{this.state.trustedCErrors[index].email}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-sm-3 col-xs-3 text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                        <div className="form-group">
                                            {/*<label>Country Code <span className='required'>*</span></label>*/}
                                            <select name="countryCode" onChange={this.TrustedCircleChangeEvent.bind(this, index)}
                                                    style={{background: 'rgba(0, 0, 0, 0.1)'}}
                                                    value={this.state.trustedCircle[index].countryCode}
                                                    className={"form-control" + (this.state.trustedCErrors[index].countryCode ? ' error-border' : '')}>
                                                {
                                                    CountryCode.map((item, index) => {
                                                        return (<option key={index}
                                                                        value={item.dial_code}>{item.dial_code}</option>)
                                                    })
                                                }
                                            </select>
                                            {this.state.trustedCErrors[index].countryCode &&
                                            <span className='error'>{this.state.trustedCErrors[index].countryCode}</span>}
                                        </div>
                                    </div>
                                    {/*<div className="col-md-4 col-sm-12 col-xs-12 text-center">*/}
                                    <div className="col-md-3 col-sm-9 col-xs-9 text-center">
                                        <div className="form-group">
                                            {/*<label>Time <span className='required'>*</span></label>*/}
                                            <input name='phoneNumber' type="text" placeholder="Phone Number"
                                                   value={this.state.trustedCircle[index].phoneNumber}
                                                   disabled={this.state.trustedCircle[index].alertEnable}
                                                   onFocus={this.TrustedCircleChangeEvent.bind(this, index)}
                                                   onChange={this.TrustedCircleChangeEvent.bind(this, index)}
                                                   className={"form-control " + (this.state.trustedCErrors[index].phoneNumber && 'error-border')}/>
                                            {this.state.trustedCErrors[index].phoneNumber &&
                                            <span
                                                className='error'>{this.state.trustedCErrors[index].phoneNumber}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-sm-12 col-xs-12 text-center">
                                        <div className='form-group desktop-display'>
                                            <a onClick={this.RemoveTrustedCircle.bind(this, index)} className='btn'>
                                                <i className="glyphicon glyphicon-trash" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xs-12 mobile-display text-center">
                                        <a onClick={this.RemoveTrustedCircle.bind(this, index)} className='btn'
                                           style={{marginTop: 10, marginBottom: 10}}>
                                            <i className="glyphicon glyphicon-trash" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </form>);
                    })}
                    {this.state.trustedCircle.length === 0 &&
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <h3><b>Please add trust circle to your list.</b></h3>
                    </div>}
                </div>
                <div className="row" style={{marginTop: 20}}>
                    <div className={"col-lg-6 col-md-6 col-sm-6 col-xs-6"} style={{textAlign: 'center'}}>
                        <button
                            onClick={this.AddTrustedCircle.bind(this, null)}
                            className={"btn-margin12 createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}>
                            <span style={{paddingLeft: 10}}>Add Trust Circle</span>
                        </button>
                    </div>
                    {<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{textAlign: 'center'}}>
                        <button
                            onClick={this.SaveTrustedCircle.bind(this)}
                            className={"btn-margin12 createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}>
                            <span style={{paddingLeft: 10}}>Set Trust Circle</span>
                        </button>
                    </div>}
                </div>
            </div>
        );
    }

    AddTrustedCircle(defaultValue, event) {
        if (!defaultValue) {
            defaultValue = {id: '', name: '', email: '', phoneNumber: '', countryCode: '+1', alertEnable: false}
        }
        var trustedCircle = this.state.trustedCircle;
        trustedCircle.push(defaultValue)
        var trustedCErrors = this.state.trustedCErrors;
        trustedCErrors.push({email: '', phoneNumber: '', name: ''})
        this.setState({trustedCircle, trustedCErrors})
    }

    TrustedCircleChangeEvent(index, event) {
        const json = this.state;
        const val = this.CheckErrors(event.target.name, event.target.value);
        if (val) {
            json.trustedCErrors = this.state.trustedCErrors;
            json.trustedCErrors[index][event.target.name] = val[event.target.name];
        } else {
            json.trustedCErrors = this.state.trustedCErrors;
            json.trustedCErrors[index][event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        json.trustedCircle[index][event.target.name] = event.target.value;
        this.setState(json);
    }

    async SaveTrustedCircle() {
        var trustedCircle = this.state.trustedCircle;
        var trustedCErrors = this.state.trustedCErrors;
        var flag = false;
        
        for (let i = 0; i < trustedCErrors.length; i++) {
            /*if (trustedCErrors[i].name || trustedCErrors[i].phoneNumber || trustedCErrors[i].email) {
                return;
            }*/
            if (trustedCircle[i] && (!trustedCircle[i].name || !trustedCircle[i].phoneNumber || !trustedCircle[i].email || !trustedCircle[i].countryCode)) {
                flag = true;
                var name = this.CheckErrors('name', '');
                var phone = this.CheckErrors('phoneNumber', '')
                var email = this.CheckErrors('email', '')
                var countryCode = this.CheckErrors('countryCode', '')
                trustedCErrors[i].name = name.name;
                trustedCErrors[i].phoneNumber = phone.phoneNumber;
                trustedCErrors[i].countryCode = countryCode.countryCode;
                trustedCErrors[i].email = email.email;
                this.setState({trustedCErrors})
                
            }
        }
        if (flag) {
            return;
        }
        this.setState({trustedCircle: trustedCircle})
        var data = {};
        data.data = trustedCircle
        data.care_receiver_id = this.state.selected_care_receiver.id
        var setMedMinder = await HTTPRequest(Constant.BASE_URL + '/care-receiver/set-trusted-circle', 'application/json; charset=UTF-8', 'post', JSON.stringify(data));
        var messsage = "We have sent SMS to trust circle person(s) to accept the invitation.";
        window.$('#snackbar').html(messsage);
        // Get the snackbar DIV
        var x = document.getElementById("snackbar");
        // Add the "show" class to DIV
        x.className = "show";
        // After 3 seconds, remove the show class from DIV
        setTimeout(() => {
            x.className = x.className.replace("show", "");
        }, 3000);
        var account_detail = this.state.account_detail;
        account_detail.trustedCircle = setMedMinder.data;
        window.localStorage.setItem('account_details', JSON.stringify(account_detail))
        this.setState({account_detail})
        // this.props.navigate('/auth/dashboard')
    }

    render() {
        return (
            <>
                <p className="myProfileText text-center">Set Details for Care Receiver</p>
                {this.CareReceiverIdentitySection()}
                <div className="row myProfile" style={{marginLeft: 0, marginRight: 0}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left'}}>
                        <div className="col-md-8 col-lg-8">
                            <p className="myProfileText">Select Apps</p>
                        </div>
                        <div className="col-md-4 col-lg-4 pulse-check" style={{textAlign: 'right'}}>
                            <div style={{display: 'inline-block'}}>
                                <label style={{
                                    paddingRight: 20,
                                    paddingTop: 18,
                                    verticalAlign: 'middle'
                                }}>{'Pulse Check'}</label>
                            </div>
                            <div className={'pulse-check-switch'} style={{display: 'inline-block'}}>
                                <label className="switch">
                                    <input type="checkbox"
                                       disabled={this.state.myavailableplans && !this.state.myavailableplans.includes('Pulse Check')}
                                       checked={this.state.account_detail.pulse_check ? this.state.account_detail.pulse_check : false}
                                       onChange={(event) => {
                                           var account_detail = this.state.account_detail;
                                           
                                           account_detail.pulse_check = event.target.checked;
                                           this.setState({
                                               account_detail
                                           });
                                           window.localStorage.setItem('account_details', JSON.stringify(account_detail));
                                           this.SetMedMinderSelectionService(JSON.stringify({
                                               type: 'pulse_check',
                                               allow: event.target.checked,
                                               care_receiver_id : this.state.selected_care_receiver.id
                                           }))
                                       }
                                       }
                                    />
                                    <span className="slider-profile round"></span>
                                </label>
                            </div>
                        </div>
                        <div className={'clearfix'}/>
                        <hr/>
                    </div>
                    <div className='row' style={{textAlign: 'center', margin: 30}}>
                        {this.state.parentControl.map((item, index) => {
                            return (
                                <div className="" key={index} style={{marginBottom: 20}}>
                                    <div className="col-md-3 col-sm-6 col-xs-6 text-left">
                                        <div
                                            className="form-group">
                                            <label>{item.displayName}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-6 text-left">
                                        <div className="form-group">
                                            <label className="switch">
                                                <input type="checkbox"
                                                       disabled={this.state.parentControl[index].disable}
                                                       checked={this.state.parentControl[index].allow}
                                                       onChange={this.ChangeParentControl.bind(this, index)}/>
                                                {this.state.parentControl[index].disable && <span className="slider-profile round" style={{cursor: 'not-allowed'}}></span>}
                                                {!this.state.parentControl[index].disable && <span className="slider-profile round"></span>}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    <hr/>
                    {this.renderOtherAlerts()}
                </div>
                {this.PulseCheckBlock()}
                {this.TrustCircle()}
                <div style={{textAlign: 'center', paddingBottom: 30}}>
                    {this.state.showAddCAButton && <button
                        onClick={() => this.Continue(false)}
                        className={"btn-margin12 createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}>
                        <span>Add Care Admin</span>
                    </button>}
                    <button
                        onClick={() => this.Continue(true)}
                        style={{ marginLeft: 10 }}
                        className={"btn-margin12 createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}>
                        <span>Continue</span>
                    </button>

                </div>
            </>
        )
    }

    CareReceiverIdentitySection() {
        var account_detail = this.state.selected_care_receiver;
        return (
            <div className="row myProfile" style={{marginLeft: 0, marginRight: 0, paddingTop: 15, paddingBottom: 30}}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left'}}>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{textAlign: 'left', paddingTop: 15}}>
                        <b>Nick Name : </b> {account_detail && account_detail.nickName ? account_detail.nickName : '-'}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{textAlign: 'left', paddingTop: 15}}>
                        <b>First Name
                            : </b> {account_detail && account_detail.firstName ? account_detail.firstName : '-'}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{textAlign: 'left', paddingTop: 15}}>
                        <b>Last Name : </b> {account_detail && account_detail.lastName ? account_detail.lastName : '-'}
                    </div>
                </div>
            </div>
        );
    }

    Continue(isContinue) {
        var account = JSON.parse(window.localStorage.getItem('account_details'));
        
        if (account.account_type === 'Care Receiver') {
        } else {
            var careReceivers = account.care_receiver_account;
            for (let i = 0; i < careReceivers.length; i++) {
                if (careReceivers[i].id === this.state.selected_care_receiver.id) {
                    careReceivers.splice(i, 1);
                    account.care_receiver_account = careReceivers;
                    window.localStorage.setItem('account_details', JSON.stringify(account));
                    break;
                }
            }
            if(account.care_receiver_account.length == 1) {
                this.setState({ showAddCAButton: true })
            }
            if (account.care_receiver_account.length > 0) {
                this.setState({selected_care_receiver: account.care_receiver_account[0]})
                var account = JSON.parse(window.localStorage.getItem('account_details'));
                this.state.parentControl = [
                    {name: 'Task', displayName: 'To Do List', allow: true},
                    {name: 'My Offers', displayName: 'My Offers', allow: true},
                    {name: 'Insights', displayName: 'Tips & Insights', allow: false},
                    {name: 'Reading Corner', displayName: 'Reading Corner', allow: false},
                    {name: 'Medicine', displayName: 'Meds Minder', allow: false},
                    {name: 'Memory Bucket', allow: false, displayName: 'Memory Bucket'},
                    {name: 'Activity', displayName: 'Activity Tracker', allow: false},
                    {name: 'Community Insights', displayName: 'Get Social', allow: false}]
                account.parent_control = this.state.parentControl;
                /*for (let i = 0; i < account.parent_control.length; i++) {
                    account.parent_control[i].allow = true;
                }*/
                account.trustedCircle = [];
                // account.med_minder = false;
                account.medicine_minder = false;
                account.trusted_circle_control = false;
                this.state.trustedCircle = []
                this.state.trustedCErrors = []
                this.state.daily_checkup_frequency = [{
                    name: 'SMS',
                    time: '06:00 AM',
                    frequency: 'Daily',
                    day: '',
                    value: false,
                }, {
                    name: 'Phone',
                    time: '06:00 AM',
                    frequency: 'Daily',
                    day: '',
                    value: false,
                }, {
                    name: 'WhatsApp',
                    time: '06:00 AM',
                    frequency: 'Daily',
                    day: '',
                    value: false,
                },]
                account.daily_checkup_frequency = this.state.daily_checkup_frequency;
                var defaultValue = {id: '', name: '', email: '', phoneNumber: '', countryCode: '+1', alertEnable: false}
                var trustedCircle = [];
                trustedCircle.push(defaultValue)
                var trustedCErrors = [];
                trustedCErrors.push({email: '', phoneNumber: '', name: ''})
                // this.setState({trustedCircle, trustedCErrors})
                this.setState({account_detail: account,
                    daily_checkup_frequency: this.state.daily_checkup_frequency,
                    parentControl: account.parent_control,
                    parent_control: null,
                    trustedCircle, trustedCErrors})
                window.localStorage.setItem('account_details', JSON.stringify(account))
                CommonMethod();
                // this.componentDidMount();
            } else {
                
                // window.localStorage.clear();
                window.$('#snackbar').html('You have successfully updated your care receiver(s) basic settings');
                // Get the snackbar DIV
                var x = document.getElementById("snackbar");

                // Add the "show" class to DIV
                x.className = "show";
                // login-by-token
                // After 3 seconds, remove the show class from DIV
                setTimeout(() => {
                    x.className = x.className.replace("show", "");
                }, 3000);
                let backup_accounts = JSON.parse(window.localStorage.getItem('account_details_backup'));
                let account_details = JSON.parse(window.localStorage.getItem('account_details'));
                backup_accounts.parent_control = account_details.parent_control;
                if(isContinue) {
                    this.LoginByToken();
                } else {
                    this.props.navigate('/cr-add-ca');
                }
                // window.localStorage.setItem('account_details', JSON.stringify(backup_accounts))
            }
        }
    }

    async LoginByToken() {
        var accounts = await HTTPRequest(Constant.BASE_URL + '/login-by-token', 'application/json; charset=UTF-8', 'get');
        
        window.localStorage.setItem('account_details', JSON.stringify(accounts.data));
        if (accounts.data.token) {
            window.localStorage.setItem('token', accounts.data.token);
        }
        this.props.navigate('/auth/care-taker/dashboard');

    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return {[targetName]: this.state.formName[targetName] + ' is required.'};
        }
    }

    async ChangeParentControl(index, event) {
        debugger
        if (!this.state.account_detail.selected_care_receiver.parent_control) {
            this.state.account_detail.selected_care_receiver.parent_control = this.state.parentControl;
        }
        this.state.parentControl[index].allow = event.target.checked;
        this.state.account_detail.selected_care_receiver.parent_control = this.state.parentControl;
        this.setState({
            account_detail: this.state.account_detail,
            parentControl: this.state.parentControl
        });
        var data = {};
        data.data = this.state.account_detail.selected_care_receiver.parent_control;
        data.care_receiver_id = this.state.selected_care_receiver.id;
        var parent_control = await HTTPRequest(Constant.BASE_URL + '/parent-control', 'application/json; charset=UTF-8', 'post', JSON.stringify(data));
        
        this.state.account_detail.parent_control = parent_control.data;
        this.setState({account_detail: this.state.account_detail, parentControl: this.state.parentControl});
        window.localStorage.setItem('account_details', JSON.stringify(this.state.account_detail));
    }

    SetMedMinderSelectionService(body) {
        return HTTPRequest(Constant.BASE_URL + '/medicine-parent-control', 'application/json; charset=UTF-8', 'post', body);
    }

    renderOtherAlerts() {
        return (
            <div className="row" style={{textAlign: 'center', margin: 30}}>
                <div className="col-md-12 col-sm-12 col-xs-12 mobile-display" style={{paddingLeft: 0, paddingRight: 0}}>
                    {this.otherAlertContents()}
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 mobile-display" style={{paddingLeft: 0, paddingRight: 0}}>
                    {this.otherMedicineContens()}
                </div>
                {this.otherAlertContents(true)}
                {this.otherMedicineContens(true)}
            </div>
        );
    }

    otherAlertContents(isDesktop) {
        return (
            <>
                <div className={"col-md-3 col-sm-6 col-xs-6 text-left " + (isDesktop ? "desktop-display" : "")}>
                    <div
                        className="form-group">
                        <label>Alert if you don't update Meds Minder on time</label>
                    </div>
                </div>
                <div className={"col-md-3 col-sm-6 col-xs-6 text-left " + (isDesktop ? "desktop-display" : "")}>
                    <div className="form-group">
                        <label className="switch">
                            <input type="checkbox"
                                   checked={this.state.account_detail.medicine_minder ? this.state.account_detail.medicine_minder : false}
                                   onChange={(event) => {
                                       var account_detail = this.state.account_detail;
                                       
                                       account_detail.medicine_minder = event.target.checked;
                                       this.setState({
                                           account_detail
                                       })
                                       if (account_detail.med_minder) {
                                           for (let i = 0; i < account_detail.med_minder.length; i++) {
                                               account_detail.med_minder[i].cg_notification = event.target.checked;
                                           }
                                       }
                                       window.localStorage.setItem('account_details', JSON.stringify(account_detail));
                                       this.SetMedMinderSelectionService(JSON.stringify({
                                           type: 'med_minder',
                                           allow: event.target.checked,
                                           care_receiver_id: this.state.selected_care_receiver.id
                                       }))
                                   }
                                   }
                            />
                            <span className="slider-profile round"></span>
                        </label>
                    </div>
                </div>
            </>
        );
    }

    otherMedicineContens(isDesktop) {
        return (
            <>
                <div className={"col-md-3 col-sm-6 col-xs-6 text-left " + (isDesktop ? "desktop-display" : "")}>
                    <div
                        className="form-group">
                        <label>Alert your Trust Circle</label>
                    </div>
                </div>
                <div className={"col-md-3 col-sm-6 col-xs-6 text-left " + (isDesktop ? "desktop-display" : "")}>
                    <div className="form-group">
                        <label className="switch">
                            <input type="checkbox"
                                   checked={this.state.account_detail.trusted_circle_control ? this.state.account_detail.trusted_circle_control : false}
                                   onChange={(event) => {
                                       var account_detail = this.state.account_detail;
                                       
                                       account_detail.trusted_circle_control = event.target.checked;
                                       this.setState({
                                           account_detail
                                       })
                                       window.localStorage.setItem('account_details', JSON.stringify(account_detail));
                                       this.SetMedMinderSelectionService(JSON.stringify({
                                           type: 'trusted_circle',
                                           allow: event.target.checked,
                                           care_receiver_id: this.state.selected_care_receiver.id
                                       }))
                                   }
                                   }
                            />
                            <span className="slider-profile round"></span>
                        </label>
                    </div>
                </div>
            </>
        );
    }
}

export default ProfileSetup;
