import React, {Component} from 'react';
/*import {Link} from '@reach/router'*/
import {CommonMethod, HTTPRequest} from "../noauth/common.service";
import Constant from "../constants";
import {Link} from "@reach/router";
// import ReactGA from "react-ga";
import {Initialize, AddEvents} from '../AnalyticsEvent';
import CountryCode from "../country.code";
class AddCareReceiverInsideComponent extends Component {
    state = {
        nickName: '',
        email: '',
        firstName: '',
        lastName: '',
        countryCode: '',
        phoneNumber: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        password: '',
        c_password: '',
        deviceWidth: 0,
        deviceHeight: 0,
        errors: {},
        formName: {
            nickName: 'Nick Name',
            email: 'Email',
            firstName: 'First Name',
            lastName: 'Last Name',
            countryCode: 'Country Code',
            phoneNumber: 'Phone Number',
            /*address: 'Address',
            city: 'City',
            state: 'State',
            country: 'Country',
            zipCode: 'Zip Code',*/
            password: 'Password',
            c_password: 'Confirm Password',
        },
        apiError: null,
        apiSuccess: null,
    };

    Validate() {
        var flag = false;
        for (let key in this.state.formName) {
            if (key) {
                if (this.state.errors[key] !== null) {
                    const json = {};
                    json.errors = this.state.errors;
                    json.errors[key] = this.state.formName[key] + ' is required.';
                    this.setState(json);
                    flag = true;
                }
            }
        }
        return flag;
    }

    AddCareAnotherReceiver(event) {
        this.CommonAddCareReceiver(event);
    }


    AddCareReceiver(event) {
        this.CommonAddCareReceiver(event, '/auth/care-taker/care-receivers');
    }

    updateDimensions() {
        console.log(window.innerWidth)
        this.setState({
            deviceWidth: window.innerWidth,
            deviceHeight: window.innerHeight,
        })
    }

    CommonAddCareReceiver(event, redirectURL) {
        event.preventDefault();
        if (this.Validate()) {
            return;
        }
        if (this.state.password !== this.state.c_password) {
            return;
        }
        var body = {
            nickName: this.state.nickName,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            address: this.state.address,
            phoneNumber: this.state.countryCode.trim() + this.state.phoneNumber.trim(),
            realPhone: this.state.phoneNumber,
            countryCode: this.state.countryCode,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            zipCode: this.state.zipCode,
            password: this.state.password,
        }
        this.AddCareReceiverService(JSON.stringify(body)).then((data) => {
            this.setState({
                apiSuccess: data.message
            });
            this.setState({
                nickName: '',
                email: '',
                firstName: '',
                lastName: '',
                countryCode: '',
                phoneNumber: '',
                address: '',
                city: '',
                state: '',
                country: '',
                zipCode: '',
                password: ''
            });
            var careTakerAccount = JSON.parse(window.localStorage.getItem('account_details'));
            careTakerAccount.care_receiver_account = data.data.care_receiver_account;
            window.localStorage.setItem('account_details', JSON.stringify(careTakerAccount));
            this.setState({care_receiver: careTakerAccount.care_receiver_account, careTakerAccount})
            console.log(data);
            window.$('#snackbar').html('Care Receiver has been created.');
            // Get the snackbar DIV
            var x = document.getElementById("snackbar");
            // Add the "show" class to DIV
            x.className = "show";
            // After 3 seconds, remove the show class from DIV
            setTimeout(() => {
                x.className = x.className.replace("show", "");
            }, 3000);
            if (redirectURL) {
                this.props.navigate(redirectURL);
            } else {
                CommonMethod();
            }

        }).catch(err => {
            CommonMethod();
            this.setState({
                apiError: err.message ? err.message : err
            });
            console.log(err.message ? err.message : err);
        });
    }

    ChangeEvent(event) {
        const json = {};
        const val = this.CheckErrors(event.target.name, event.target.value);
        if (val) {
            json['errors'] = {}
            json.errors = this.state.errors;
            json.errors[event.target.name] = val[event.target.name];
        } else {
            json['errors'] = {}
            json.errors = this.state.errors;
            json.errors[event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        if (event.target.name === 'c_password' || event.target.name === 'password') {
            if (event.target.value !== this.state.password) {
                json.errors['c_password'] = 'Password and confirm password must match.';
            }
        }
        if (event.target.name === 'phoneNumber' && event.target.value) {
            if (isNaN(event.target.value)) {
                json.errors[event.target.name] = 'Please provide proper phonenumber.';
            } else {
                event.target.value = parseInt(event.target.value);
            }
        }
        if (event.target.name === 'email' && event.target.value) {
            if (!this.ValidateEmail(event.target.value)) {
                json.errors[event.target.name] = 'Please provide proper email.';
            }
        }
        json[event.target.name] = event.target.value;
        this.setState(json);
    }

    ValidateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return {[targetName]: this.state.formName[targetName] + ' is required.'};
        }
    }

    componentDidMount() {
        Initialize()
        if (!(window.localStorage.getItem('account_details') || window.localStorage.getItem('token'))) {
            window.setTimeout(() => {
                this.props.navigate('/');
            }, 200);
            return;
        }
        CommonMethod();
        this.FetchLocation();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        var input = window.document.getElementById('phoneNumber');
        input.onkeydown = (e) => {
            var k = e.which;
            /* numeric inputs can come from the keypad or the numeric row at the top */
            if ( (k < 48 || k > 66) && (k < 96 || k > 105) && k !== 8 && k !== 17 && k !== 37 && k !== 39) {
                e.preventDefault();
                return false;
            }
        }
    }



    async FetchLocation() {
        var locationJSON = await HTTPRequest(Constant.BASE_URL + '/location', '', 'get');
        locationJSON = locationJSON.Countries;
        var countries = [];
        var cities = [];
        var states = [];
        /*var citylist = 0;*/
        for (let i = 0; i < locationJSON.length; i++) {
            countries.push(locationJSON[i].CountryName);
            var state = locationJSON[i].States;
            for (let j = 0; j < state.length; j++) {
                states.push(state[j].StateName);
                var city = state[j].Cities;
                if (city.length === 0) {
                    locationJSON[i].States[j].Cities.push(state[j].StateName);
                    cities.push(state[j].StateName);
                } else {
                    for (let k = 0; k < city.length; k++) {
                        cities.push(city[k]);
                        /*citylist++;*/
                    }
                }
            }
        }
        this.setState({countries, cities, states, locationJSON});
        this.autocomplete(window.document.getElementById('country'), this.state.countries)
        this.autocomplete(window.document.getElementById('city'), this.state.cities)
        this.autocomplete(window.document.getElementById('state'), this.state.states)
    }

    SetState() {
        if (this && this.state && this.state.locationJSON) {
            for (let i = 0; i < this.state.locationJSON.length; i++) {
                var state = this.state.locationJSON[i].States;
                for (let j = 0; j < state.length; j++) {
                    var city = state[j].Cities;
                    for (let k = 0; k < city.length; k++) {
                        if (city[k] === this.state.city) {
                            this.setState({state: state[j].StateName, country: this.state.locationJSON[i].CountryName});
                            return true;
                        }
                    }
                }
            }
        }
    }


    render() {
        return (
            <div className="row">
                <div className="signup-block">

                    <div>
                        <div className="col-md-3"></div>
                        <div className="col-sm-12 col-md-6">
                            <div className="signup-form-cat">
                                <form className="signup-form">
                                    {this.state.apiError &&
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.apiError}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {this.state.apiSuccess &&
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className="alert alert-success" role="alert">
                                                {this.state.apiSuccess}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <h2 className='text-center' style={{color: '#004265'}}>Add Care
                                                Receiver</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Nick Name <span className='required'>*</span></label>
                                                <input name="nickName" type="text"
                                                       value={this.state.nickName}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.nickName ? ' error-border' : '')}
                                                       placeholder="Nick Name"/>
                                                {this.state.errors.nickName &&
                                                <span className='error'>{this.state.errors.nickName}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>First Name <span className='required'>*</span></label>
                                                <input name="firstName" type="text" placeholder="First Name"
                                                       value={this.state.firstName}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.firstName ? ' error-border' : '')}/>
                                                {this.state.errors.firstName &&
                                                <span className='error'>{this.state.errors.firstName}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Last Name <span className='required'>*</span></label>
                                                <input name="lastName" type="text" placeholder="Last Name"
                                                       value={this.state.lastName}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.lastName ? ' error-border' : '')}/>
                                                {this.state.errors.lastName &&
                                                <span className='error'>{this.state.errors.lastName}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Email <span className='required'>*</span></label>
                                                <input name="email" type="email" placeholder="mail@email.com"
                                                       value={this.state.email}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.email ? ' error-border' : '')}/>
                                                {this.state.errors.email &&
                                                <span className='error'>{this.state.errors.email}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Country Code <span className='required'>*</span></label>
                                                <select name='countryCode' onChange={this.ChangeEvent.bind(this)}  style={{background: 'rgba(0, 0, 0, 0.1)'}} className={"form-control" + (this.state.errors.countryCode ? ' error-border' : '')}>
                                                    {
                                                        CountryCode.map((item, index) => {
                                                            return (<option key={index} value={item.dial_code}>{item.name}  {item.dial_code}</option>)
                                                        })
                                                    }
                                                </select>
                                                {this.state.errors.countryCode &&
                                                <span className='error'>{this.state.errors.countryCode}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Phone Number <span className='required'>*</span></label>
                                                <input name="phoneNumber" type="number" placeholder="Phone number"
                                                       id="phoneNumber"
                                                       value={this.state.phoneNumber}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.phoneNumber ? ' error-border' : '')}/>
                                                {this.state.errors.phoneNumber &&
                                                <span className='error'>{this.state.errors.phoneNumber}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Password <span className='required'>*</span></label>
                                                <input name="password" type="password" placeholder="Password"
                                                       value={this.state.password}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.password ? ' error-border' : '')}/>
                                                {this.state.errors.password &&
                                                <span className='error'>{this.state.errors.password}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Confirm Password <span className='required'>*</span></label>
                                                <input name="c_password" type="password" placeholder="Confirm Password"
                                                       value={this.state.c_password}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.c_password ? ' error-border' : '')}/>
                                                {this.state.errors.c_password &&
                                                <span className='error'>{this.state.errors.c_password}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <input name="address" type="text" placeholder="Address"
                                                       value={this.state.address}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control"}/>
                                                {/*className={"form-control" + (this.state.errors.address ? ' error-border' : '')}/>*/}
                                                {/*{this.state.errors.address &&*/}
                                                {/*<span className='error'>{this.state.errors.address}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>City</label>
                                                <input id="city" name="city" type="text" placeholder="City"
                                                       value={this.state.city}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       onBlur={this.SetState.bind(this)}
                                                       className={"form-control"}/>
                                                {/*className={"form-control" + (this.state.errors.city ? ' error-border' : '')}/>*/}
                                                {/*{this.state.errors.city &&*/}
                                                {/*<span className='error'>{this.state.errors.city}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>State</label>
                                                <input id="state" name="state" type="text" placeholder="State"
                                                       value={this.state.state}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control"}/>
                                                {/*className={"form-control" + (this.state.errors.state ? ' error-border' : '')}/>*/}
                                                {/*{this.state.errors.state &&*/}
                                                {/*<span className='error'>{this.state.errors.state}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Country</label>
                                                <input id="country" name="country" type="text" placeholder="Country"
                                                       value={this.state.country}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control"}/>
                                                {/*className={"form-control" + (this.state.errors.country ? ' error-border' : '')}/>*/}
                                                {/*{this.state.errors.country &&*/}
                                                {/*<span className='error'>{this.state.errors.country}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Zip code</label>
                                                <input name="zipCode" type="text" placeholder="Zip code"
                                                       value={this.state.zipCode}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control"}/>
                                                {/*className={"form-control" + (this.state.errors.zipCode ? ' error-border' : '')}/>*/}
                                                {/*{this.state.errors.zipCode &&*/}
                                                {/*<span className='error'>{this.state.errors.zipCode}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        {(this.state.deviceWidth <= 1199 && this.state.deviceWidth >= 990) &&
                                        (
                                            <>
                                                {/*   <div className="col-md-6 col-sm-6 col-xs-6">
                                                    <button onClick={this.AddCareAnotherReceiver.bind(this)}
                                                            type="submit"
                                                            className="btn navigation-btn">Add Care Receiver(s)
                                                    </button>
                                                </div>*/}
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <button onClick={this.AddCareReceiver.bind(this)} type="submit"
                                                            className="btn navigation-btn">Submit
                                                    </button>
                                                </div>
                                            </>
                                        )
                                        }
                                        {(!(this.state.deviceWidth <= 1199 && this.state.deviceWidth >= 990)) && (
                                            <>
                                                <div className="col-md-2 col-sm-2 col-xs-2"></div>
                                                {/*<div className="col-md-4 col-sm-4 col-xs-6">*/}
                                                {/*<button onClick={this.AddCareAnotherReceiver.bind(this)}*/}
                                                {/*type="submit"*/}
                                                {/*className="btn navigation-btn">Add Care Receiver(s)*/}
                                                {/*</button>*/}
                                                {/*</div>*/}
                                                <div className="col-md-8 col-sm-8 col-xs-8">
                                                    <button onClick={this.AddCareReceiver.bind(this)} type="submit"
                                                            className="btn navigation-btn">Submit
                                                    </button>
                                                </div>
                                                <div className="col-md-2 col-sm-2 col-xs-2"></div>
                                            </>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

    AddCareReceiverService(data) {
       /* window.ga('send', 'event', {
            eventCategory: 'Care Taker',
            eventAction: 'click',
            eventLabel: 'Add Care Receiver'
        });*/
        AddEvents('Care Taker','click','Add Care Receiver');
        return HTTPRequest(Constant.BASE_URL + '/care-taker/add-receiver', 'application/json; charset=UTF-8', 'post', data);
    }

    GetProfileService() {
        return HTTPRequest(Constant.BASE_URL + '/care-taker/my-profile', 'application/json; charset=UTF-8', 'get');
    }


    autocomplete(inp, arr) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) {
                return false;
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = window.document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = window.document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                        inp.focus();
                        window.setTimeout(() => {
                            inp.blur();
                        }, 10)
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = window.document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode === 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode === 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode === 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }

        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }

        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = window.document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt !== x[i] && elmnt !== inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }

        /*execute a function when someone clicks in the document:*/
        window.document.addEventListener('click', function (e) {
            closeAllLists(e.target);
        });
    }
}

export default AddCareReceiverInsideComponent;
