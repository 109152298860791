import React, { Component } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { HTTPRequest } from "../noauth/common.service";
import Constant from "../constants";
import { withStyles } from '@material-ui/core/styles';


class SharedPicturesModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tabValue: 0,
            category: '',
            dueDate: '',
            errors: {},
            formName: {
                message: 'Message',
                category: 'Category',
                dueDate: 'Due Date'
            },
            old: [],
            latest: [],
            admin: [],
            modalTitle: 'SHOW PICTURE',
            load: false
        }
    }

    componentDidMount() {
        console.log(this.props)
        this.FetchPhotos();
        if (window.$) {
            window.setTimeout(() => {
                window.$('.sandbox-container input').datepicker({
                    autoclose: true
                });
            }, 200);

            window.$('#root').css('filter', 'blur(5px)')
        }
    }

    componentWillUnmount() {
        if (window.$) {
            window.$('#root').css('filter', 'none')
            window.$(".filter-button").click(function () {
                var value = window.$(this).attr('data-filter');
                value = value === "all" ? "latest" : value;

                if (value === "all") {
                    window.$('.filter').show('1000');
                } else {
                    window.$(".filter").not('.' + value).hide('3000');
                    window.$('.filter').filter('.' + value).show('3000');
                }
            });
            if (window.$(".filter-button").removeClass("active")) {
                window.$(this).removeClass("active");
            }
            window.$(this).addClass("active");

        }
    }

    async FetchPhotos() {
        try {
            this.setState({ load: true })
            var careTakerAccount = JSON.parse(window.localStorage.getItem('account_details'));
            //for care taker side
            if (careTakerAccount.selected_care_receiver != null && Object.keys(careTakerAccount.selected_care_receiver).length > 0) {
                console.log(careTakerAccount.selected_care_receiver);
                var care_receiver_account = careTakerAccount.selected_care_receiver;
                var data = await HTTPRequest(Constant.BASE_URL + '/care-receiver/imagesall?care_receiver_id=' + care_receiver_account.id, null, 'get', null)
            }
            //for care receiver side
            else {
                console.log(careTakerAccount.care_taker_account);
                var care_taker_account = careTakerAccount.care_taker_account;
                var data = await HTTPRequest(Constant.BASE_URL + '/care-receiver/imagesall?care_taker_id=' + care_taker_account.id, null, 'get', null)
            }
            this.setState({
                old: data.data.care_taker,
                latest: data.data.care_receiver,
                admin: data.data.care_admin
            })
            var newValue = 0;
            window.$(".filter").not(newValue !== 0 ? (newValue === 1 ? '.old' : '.admin') : '.latest').hide('3000');
            window.$('.filter').filter(newValue !== 0 ? (newValue === 1 ? '.old' : '.admin') : '.latest').show('3000');
            this.setState({ load: false })
        } catch (e) {
            console.log(e)
        }
    }

    handleChange(newValue, event) {
        debugger
        window.$(".filter").not(newValue !== 0 ? (newValue === 1 ? '.old' : '.admin') : '.latest').hide('3000');
        window.$('.filter').filter(newValue !== 0 ? (newValue === 1 ? '.old' : '.admin') : '.latest').show('3000');
        this.setState({
            modalTitle: newValue === 0 ? 'SHOW PICTURE' : 'SHOW PICTURE'
        })
        this.setState({ tabValue: newValue });
    }

    render() {
        const { fullScreen, open, onClose, admin, old, latest } = this.props;
        return (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={onClose}
                aria-labelledby="sharedPictures"
            >
                <DialogTitle id="sharedPictures">{this.state.modalTitle}</DialogTitle>
                <DialogContent>
                    {this.state.load ?
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            marginBottom: 50
                        }}>
                            <i class="fa fa-spinner fa-spin" style={{ marginRight: 10 }}></i>
                            Please wait ...
                        </div>
                        :
                        <form className="signup-form">
                            <ul className="nav nav-pills center-block" style={{ maxWidth: 300 }}>
                                <li className="active" style={{ width: '31.30%' }}>
                                    <a href="javascript:void(0)" data-filter="latest"
                                        onClick={this.handleChange.bind(this, 0)}
                                        data-toggle="tab">Care Receiver</a>
                                </li>
                                <li style={{ width: '31.30%' }}><a href="javascript:void(0)" data-filter="old" onClick={this.handleChange.bind(this, 1)}
                                    data-toggle="tab">Care Giver</a>
                                </li>
                                <li style={{ width: '31.30%' }}><a href="javascript:void(0)" data-filter="admin" onClick={this.handleChange.bind(this, 2)}
                                    data-toggle="tab">Care Admin</a>
                                </li>
                            </ul>
                            <div className="tab-content clearfix ">
                                <div className={"tab-pane " + (this.state.tabValue === 0 ? 'active' : 'active')}>
                                    <ul id="lightgallery" className="list-unstyled row">
                                        {this.state.latest.map((data, index) => {
                                            return (
                                                <div key={index}
                                                    className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter latest">
                                                    <a href={data.image_url} target='_blank' align="center">
                                                        <img src={data.image_url} style={{ backgroundColor: '#C4C4C4', height: 140, width: 140, objectFit: 'cover' }} className="img-responsive" />
                                                    </a>
                                                    {data.question &&
                                                        <>
                                                            <p style={{
                                                                color: '#000',
                                                                fontWeight: '600',
                                                                marginTop: 10
                                                            }}>{data.question}</p>
                                                            <p>{data.answer ? data.answer : "No answer provided"}</p>
                                                        </>
                                                    }

                                                </div>
                                            )
                                        })}
                                        {this.state.old.map((data, index) => {
                                            return (
                                                <div key={index}
                                                    className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter old" style={{ display: 'none' }}>
                                                    <a href={data.image_url} target='_blank' align="center">
                                                        <img src={data.image_url} style={{ backgroundColor: '#C4C4C4', height: 140, width: 140, objectFit: 'cover' }} className="img-responsive" />
                                                    </a>
                                                    {data.question &&
                                                        <>
                                                            <p style={{
                                                                color: '#000',
                                                                fontWeight: '600',
                                                                marginTop: 10
                                                            }}>{data.question}</p>
                                                            <p>{data.answer ? data.answer : "No answer provided"}</p>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        })}
                                        {this.state.admin.map((data, index) => {
                                            return (
                                                <div key={index}
                                                    className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter admin" style={{ display: 'none' }}>
                                                    <a href={data.image_url} target='_blank' align="center">
                                                        <img src={data.image_url} style={{ backgroundColor: '#C4C4C4', height: 140, width: 140, objectFit: 'cover' }} className="img-responsive" />
                                                    </a>
                                                    {data.question &&
                                                        <>
                                                            <p style={{
                                                                color: '#000',
                                                                fontWeight: '600',
                                                                marginTop: 10
                                                            }}>{data.question}</p>
                                                            <p>{data.answer ? data.answer : "No answer provided"}</p>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </form>
                    }
                </DialogContent>
                {/*<DialogActions className="actions">
                    <button onClick={this.submitForm.bind(this)}
                            className={"createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')}>
                        Close
                    </button>
                </DialogActions>*/}
            </Dialog>
        )
    }
}

const useStyles = (theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

}));


export default withStyles(useStyles)(SharedPicturesModal);
/**
 *
 * https://bootsnipp.com/snippets/D0RR1
 * */
