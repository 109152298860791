import React, {Component} from 'react';
import {Link} from '@reach/router'

class NoAuthTemplateComponent extends Component {
    componentDidMount() {
        if (window.$) {
            window.setTimeout(() => {
                window.$(".loading").fadeOut();
            }, 1000);
        }
        if (window.ga) {
            window.ga('config', 'UA-148894428-1');
        }
        if (window.localStorage.getItem('account_details')) {
            var accountDetails = JSON.parse(window.localStorage.getItem('account_details'));
            
            if (accountDetails.account_type === 'Care Receiver') {
                window.setTimeout(() => {
                    this.props.navigate('/auth/dashboard');
                }, 100);
            } else if (accountDetails.account_type === 'Care Taker' && accountDetails.payment) {
                window.setTimeout(() => {
                    this.props.navigate('/auth/care-taker/dashboard');
                }, 100);
            } else if (accountDetails.account_type === 'Care Admin') {
                window.setTimeout(() => {
                    this.props.navigate('/auth/care-admin/dashboard');
                }, 100);
            }
        }
    }

    render() {
        return (
            <div>
                <div id="snackbar">Some text some message..</div>
                <div className="clearfix"></div>
                <div className="overlay-fix loading">
                    <div className="loader-img">
                        <img src="/assets/images/logo.png" alt="Sahaaya" width="250px" className="img-responsive"/>
                    </div>
                </div>
                <div className="container logo-container">
                    <div className="row">
                        <Link to='/'>
                            <img className="center-block" src="/assets/images/logo_small.png" width="200px"/>
                        </Link>
                    </div>
                </div>
                <div className="container">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default NoAuthTemplateComponent;
