import React, {Component} from 'react';
import {Link} from '@reach/router';
import {CommonMethod, HTTPRequest} from './common.service';
import Constant from '../constants';
// import Snackbar from 'react-native-snackbar';

class LoginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: {
                required: {},
                other: {}
            },
            formName: {
                email: 'Email',
                password: 'Password'
            },
            apiError: null,
            apiSuccess: null,
        };
    }
    Validate() {
        var flag = false;
        for (let key in this.state.formName) {
            if (key) {
                if (!this.state[key]) {
                    const json = {errors: {}};
                    json.errors.required = this.state.errors.required;
                    json.errors.required[key] = this.state.formName[key] + ' is required.';
                    this.setState(json);
                    flag = true;
                }
            }
        }
        return flag;
    }

    ResetForm() {
        this.setState({
            email: '',
            password: ''
        })
    }

    LoginMeIn(event) {
        event.preventDefault();
        if (this.Validate()) {
            return;
        }
        this.LoginService(JSON.stringify(this.state)).then((data) => {
            this.setState({
                apiSuccess: 'Successfully login'
            });
            if (data.code === 402 || data.code === 400) {
                this.setState({
                    apiError: data.message
                });
                
                window.localStorage.setItem('token', data.data.token);
                this.props.navigate('/payment');
                return
            }
            this.ResetForm();
            window.localStorage.setItem('account_details', JSON.stringify(data.data));
            window.localStorage.setItem('token', data.data.token);
            
            if (data.data.account_type && data.data.account_type === 'Care Taker') {
                if (data.data.care_receiver_added && data.data.care_receiver_added === true) {
                    if (data.data.payment) {
                        this.props.navigate('/auth/care-taker/dashboard');
                    } else {
                        this.props.navigate('/payment');
                    }
                } else {
                    this.props.navigate('/add-care-receiver');
                }
            } else if(data.data.account_type && data.data.account_type === 'Care Admin') {
                this.props.navigate('/auth/care-admin/dashboard');
            } else {
                this.props.navigate('/auth/dashboard');
            }
            console.log(data);
            if (window.ga) {
                window.ga('send', 'event', 'Login', 'success', 'No-Auth');
            }
        }).catch(err => {
            
            if (err && err.message && err.message === 'Failed to fetch') {
                this.setState({
                    apiError: 'Failed to connect with server.'
                });
            } else if (err && err.message && err.message === 'Your plan is expired. Please make payment to continue.') {
                this.setState({
                    apiError: err.message
                });
                window.localStorage.setItem('token', err.data.token);
                this.props.navigate('/payment');
            } else {
                this.setState({
                    apiError: err && err.message ? err.message : err
                });
            }
            /*this.setState({
                apiError: err.message ? err.message : err
            });*/
            console.log(err ? err.message : err);
            if (window.ga) {
                window.ga('send', 'event', 'Login', 'error', 'No-Auth');
            }
        });
    }

    componentDidMount() {
        
        /*setTimeout(() => {
            this.props.navigate('/profile-setup');
        }, 500)*/
       /* Snackbar.show({
            title: 'Hello world',
            duration: Snackbar.LENGTH_SHORT,
        });*/
        var query = window.location.search.substring(1);
        if (query === 'error=Your%20token%20is%20expired.') {
            this.setState({
                apiError: 'Your token is expired. Please login to continue'
            });
            window.localStorage.clear();
        }
        CommonMethod();
    }

    ChangeEvent(event) {
        const json = {};
        const val = this.CheckErrors(event.target.name, event.target.value);
        if (val) {
            json['errors'] = {}
            json.errors.required = this.state.errors.required;
            json.errors.required[event.target.name] = val[event.target.name];
        } else {
            json['errors'] = {}
            json.errors.required = this.state.errors.required;
            json.errors.required[event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        json[event.target.name] = event.target.value;
        this.setState(json);
    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return {[targetName]: this.state.formName[targetName] + ' is required.'};
        }
    }

    render() {
        return (
            <div className="row">
                <div className="signup-block">
                    <div className="col-md-4"></div>
                    <div className="col-sm-12 col-md-4">
                        <div className="signup-form-cat">
                            <form className="signup-form">
                                <div className="row">
                                    <div className='col-md-12'>
                                        <h2 className='text-center' style={{color: '#004265'}}>Login</h2>
                                    </div>
                                </div>
                                {this.state.apiError &&
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.apiError}
                                        </div>
                                    </div>
                                </div>
                                }
                                {this.state.apiSuccess &&
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="alert alert-success" role="alert">
                                            {this.state.apiSuccess}
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Email Address <span className='required'>*</span></label>
                                            <input name='email' type="email" placeholder="mail@email.com"
                                                   value={this.state.email}
                                                   onFocus={this.ChangeEvent.bind(this)}
                                                   onChange={this.ChangeEvent.bind(this)}
                                                   className={"form-control" + (this.state.errors.required.email ? ' error-border' : '')}/>
                                            {this.state.errors.required.email &&
                                            <span className='error'>{this.state.errors.required.email}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Password <span className='required'>*</span></label>
                                            <input name='password' type="password" placeholder="Password"
                                                   value={this.state.password}
                                                   onFocus={this.ChangeEvent.bind(this)}
                                                   onChange={this.ChangeEvent.bind(this)}
                                                   className={"form-control" + (this.state.errors.required.password ? ' error-border' : '')}/>
                                            {this.state.errors.required.password &&
                                            <span className='error'>{this.state.errors.required.password}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row right">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <Link to="/forgot-password">Forgot Password</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row center-block">
                                    <div className="col-md-12 col-sm-12 col-xs-12 custombutton">
                                        <button onClick={this.LoginMeIn.bind(this)}
                                                className="btn full-button">Login
                                        </button>
                                    </div>
                                </div>
                                <p className="text-center">New to Sahaaya? &nbsp; &nbsp;
                                    {/*<Link to='/signup/1832'>
                                        Register New Account
                                    </Link>*/}
                                    <a href='https://www.sahaayaworld.com/product/sahaaya-classic/'>Purchase New Account</a>
                                </p>
                                <p className="text-center">
                                    <Link to='/signup-care-admin'>
                                        Sign Up &nbsp;
                                    </Link>
                                    to Become a Care Admin
                                </p>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

    LoginService(data) {
        return HTTPRequest(Constant.BASE_URL + '/login', 'application/json; charset=UTF-8', 'post', data);
    }
}

export default LoginComponent;
