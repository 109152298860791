// exports.BASE_URL = 'http://localhost:8181';
// exports.BASE_URL = 'https://9226c5f5a7a2.ngrok.io';
exports.BASE_URL = 'https://app.sahaayaworld.com/api';

exports.CLIENT_ID = '22DP2T';
exports.CLIENT_SECRET = 'ef9532724ed3dd63439bee60bdd6f45f';
exports.GOOGLE_ANALYTICS_TRACKING_TAG = 'UA-148894428-1';
exports.FIT_BIT_REDIRECT_URL = 'https://app.sahaaya.us/auth/dashboard';
// exports.FIT_BIT_REDIRECT_URL = 'https://app.sahaaya.us/auth/dashboard';
exports.FIT_BIT_BUTTON_LINK = 'https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=22DP2T&redirect_uri=https%3A%2F%2Fapp.sahaayaworld.us%2Fauth%2Fdashboard&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800&prompt=login%20consent'
