import {Component} from "react";
import React from "react";
import {HTTPRequest} from "../noauth/common.service";
import Constant from "../constants";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "../../../node_modules/@material-ui/icons/Close";
import {withStyles} from "@material-ui/core/styles";
import {AddEvents, Initialize} from "../AnalyticsEvent";

const useStyles = theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
});
class CareReceiverInsightComponent extends Component {
    state = {
        insightId: 1,
        open: false,
        message: '',
        selectedAlerts: [],
        allAlerts: [],
    }

    componentDidMount() {
        Initialize();
        /*var accounts = JSON.parse(localStorage.getItem('account_details'));
        if (accounts.selected_insight) {
            this.setState({
                insightId: accounts.selected_insight
            });
        }*/
        var allAlerts = [];
        var MyAllAlerts = {
            1: "Alert me when care giver has added a new task.",
            2: "Alert me when care giver has updated a task.",
            3: "Alert me when care giver has completed a task.",
            4: "Alert me when care giver has updated the Memory Bucket.",
        };
        for (let key in MyAllAlerts) {
            if (key) {
                var first = {
                    id: key,
                    sms: false,
                    email: false,
                    push: false,
                    text: MyAllAlerts[key]
                };
                allAlerts.push(first)
            }
        }
        this.setState({allAlerts, selectedAlerts: allAlerts})
        var accounts = JSON.parse(localStorage.getItem('account_details'));
        if (accounts.alerts) {
            var allApplicableSelecetedAlerts = [];
            for (let i = 0; i < allAlerts.length; i++) {
                var flag = false;
                for (let j = 0; j < accounts.alerts.length; j++) {
                    if (parseInt(accounts.alerts[j].id) === parseInt(allAlerts[i].id)) {
                        flag = true;
                        allApplicableSelecetedAlerts.push(accounts.alerts[j])
                    }
                }
                if (!flag) {
                    allApplicableSelecetedAlerts.push(allAlerts[i])
                }
            }
            this.setState({
                selectedAlerts: allApplicableSelecetedAlerts
            });
            /*this.setState({
                selectedAlerts: accounts.alerts
            });*/
        }
        // this.props.view();
    }

    async RadioChange(id, event) {
        /*this.setState({insightId: id});
        try {
            /!*window.ga('send', 'event', {
                eventCategory: 'Care Receiver',
                eventAction: 'click',
                eventLabel: 'Change alerts'
            });*!/
            AddEvents('Care Receiver', 'alerts', 'Change default alert');
            var data = await HTTPRequest(Constant.BASE_URL + '/care-taker/set-insight', 'application/json', 'post', JSON.stringify({id: id}));
            if (data && data.data) {
                var accountDetails = JSON.parse(window.localStorage.getItem('account_details'));
                accountDetails.selected_insight = id;
                window.localStorage.setItem('account_details', JSON.stringify(accountDetails));
            }
            if (data && data.message) {
                this.setState({
                    message: data.message,
                    open: true
                })
            }
        } catch (e) {
            console.log(e)
        }*/
        if (!this.AlertContains(id)) {
            for (let i = 0; i < this.state.allAlerts.length; i++) {
                if (parseInt(id) === parseInt(this.state.allAlerts[i].id)) {
                    var selectedAlerts = this.state.selectedAlerts;
                    selectedAlerts.push(this.state.allAlerts[i]);
                    this.setState({selectedAlerts});
                }
            }
        } else {
            for (let y = 0; y < this.state.selectedAlerts.length; y++) {
                if (parseInt(this.state.selectedAlerts[y].id) === parseInt(id)) {
                    this.state.selectedAlerts.splice(y, 1);
                    this.setState({selectedAlerts: this.state.selectedAlerts})
                }
            }
        }
    }

    async SetAlerts() {
        try {
            
            for (let i =0;i<this.state.selectedAlerts.length;i++) {
                this.state.selectedAlerts[i].id = parseInt(this.state.selectedAlerts[i].id);
            }
            var data = await HTTPRequest(Constant.BASE_URL + '/care-taker/set-insight', 'application/json', 'post', JSON.stringify(this.state.selectedAlerts));
            
            if (data && data.data) {
                var accountDetails = JSON.parse(window.localStorage.getItem('account_details'));
                accountDetails.alerts = data.data.alerts;
                window.localStorage.setItem('account_details', JSON.stringify(accountDetails));
                this.props.navigate('/auth/dashboard')
            }
            if (data && data.message) {
                window.$('#snackbar').html(data.message);
                // Get the snackbar DIV
                var x = document.getElementById("snackbar");

                // Add the "show" class to DIV
                x.className = "show";

                // After 3 seconds, remove the show class from DIV
                setTimeout(() => {
                    x.className = x.className.replace("show", "");
                }, 3000);
                this.setState({
                    message: data.message,
                    open: true
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    async CheckBoxClick(id, type, event) {
        if (this.AlertContains(id)) {
            for (let y = 0; y < this.state.selectedAlerts.length; y++) {
                if (parseInt(this.state.selectedAlerts[y].id) === parseInt(id)) {
                    this.state.selectedAlerts[y][type] = event.currentTarget.checked;
                    this.setState({selectedAlerts: this.state.selectedAlerts})
                }
                /*if (this.state.selectedAlerts[y].id === id) {
                    this.state.selectedAlerts.splice(y, 1);
                    this.setState({selectedAlerts: this.state.selectedAlerts})
                }*/
            }
        }
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false})
    }

    AlertContains(alertId) {
        // for (let i = 0; i < this.state.allAlerts.length; i++) {
        for (let y = 0; y < this.state.selectedAlerts.length; y++) {
            if (parseInt(this.state.selectedAlerts[y].id) === parseInt(alertId)) {
                return true;
            }
        }
        return false;
        // }
    }

    IsChecked(alertId, type) {
        for (let y = 0; y < this.state.selectedAlerts.length; y++) {
            if (parseInt(this.state.selectedAlerts[y].id) === parseInt(alertId)  && this.state.selectedAlerts[y][type]) {
                return true;
            }
        }
        return false;
    }

    render() {
        const {close, paper} = this.props.classes;
        return (
            <div>
                {this.state.open && <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.open}
                    onClose={this.handleClose.bind(this)}
                    autoHideDuration={4000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span className="snackBar">{this.state.message}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={close}
                            onClick={this.handleClose.bind(this)}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />}
                <div className="">
                    <div className="col-md-12 col-sm-12 col-xs-12 center-block">
                        {this.state.allAlerts.map((item, index) => {
                                return (
                                    <div key={index} className="md-radio">
                                        {this.AlertContains(item.id) &&
                                        <input id={item.id} type="radio" value={item.id} checked
                                               onChange={() => {}}
                                               onClick={this.RadioChange.bind(this, item.id)}
                                               name={item.id}/>}
                                        {!this.AlertContains(item.id) &&
                                        <input id={item.id} type="radio" value={item.id} onClick={this.RadioChange.bind(this, item.id)}
                                               name={item.id}/>}
                                        <label htmlFor={item.id}>{item.text}</label>
                                        <span className="checkboxes">
                                            <div className="md-checkbox">
                                                <input type='checkbox' checked={this.IsChecked(item.id, 'sms')}
                                                       disabled={!this.AlertContains(item.id)}
                                                       onChange={() => {}}
                                                       onClick={this.CheckBoxClick.bind(this, item.id, 'sms')}
                                                       id={"sms" + item.id}/> <label
                                                htmlFor={"sms" + item.id}>SMS</label>
                                            </div>
                                            <div className="md-checkbox">
                                                <input type='checkbox' checked={this.IsChecked(item.id, 'push')}
                                                       disabled={!this.AlertContains(item.id)}
                                                       onChange={() => {}}
                                                       onClick={this.CheckBoxClick.bind(this, item.id, 'push')}
                                                       id={"push"+item.id}/> <label
                                                htmlFor={"push" + item.id}>WHATSAPP</label>
                                            </div>
                                            <div className="md-checkbox">
                                                <input type='checkbox' checked={this.IsChecked(item.id, 'email')}
                                                       disabled={!this.AlertContains(item.id)}
                                                       onChange={() => {}}
                                                       onClick={this.CheckBoxClick.bind(this, item.id, 'email')}
                                                       id={"email"+item.id}/> <label
                                                htmlFor={"email" + item.id}>EMAIL</label>
                                            </div>
                                        </span>
                                    </div>
                                );
                            }
                        )}
                    </div>
                    <div className="row text-center" style={{paddingBottom: 20}}>
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                            <button onClick={this.SetAlerts.bind(this)} type="submit"
                                    className="btn navigation-btn">Set Notifications
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(CareReceiverInsightComponent);
