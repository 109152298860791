import ReactGA from "react-ga";
import Constants from './constants';

export const Initialize = () => {
    ReactGA.initialize(Constants.GOOGLE_ANALYTICS_TRACKING_TAG);
};

export const AddEvents = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
};