import React, {Component} from 'react'
import {AXIOS, HTTPRequest} from "../noauth/common.service"
import Constant from "../constants";
import {Doughnut, Bar, Line, Pie} from 'react-chartjs-2';

export class ProgressiveDashboardComponent extends Component {

    constructor(props) {
        super(props)
        var today = new Date();
        var before30Days = new Date(today.getTime() - (86400000 * 30));
        var endDate= ((today.getMonth() + 1 > 9 ? (1 + today.getMonth()) : ('0' + (today.getMonth() + 1)))) + '/' +
            (today.getDate() > 9 ? (today.getDate()) : ('0' + today.getDate())) + "/" +today.getFullYear();
        var startDate = ((before30Days.getMonth() + 1 > 9 ? (1 + before30Days.getMonth()) : ('0' + (before30Days.getMonth() + 1)))) + '/' +
            (before30Days.getDate() > 9 ? (before30Days.getDate()) : ('0' + before30Days.getDate())) + "/" +before30Days.getFullYear();
        this.state = {
            startDate,
            endDate,
            chartData: [],
            homeChart: {
                labels: ['Good', 'Bad', 'Great', 'NA'],
                datasets: [{
                    label: '# of Daily progress',
                    data: [0, 0, 0, 0],
                    backgroundColor: [
                        '#c19421',
                        '#dc000f',
                        '#089636',
                        '#313634',
                    ],
                    borderColor: [
                        '#c19421',
                        '#dc000f',
                        '#089636',
                        '#313634',
                    ],
                    borderWidth: 1
                }]
            },
            wellnessChart: {
                labels: ['Good', 'Bad', 'Great', 'NA'],
                datasets: [{
                    label: '# of Daily progress',
                    data: [0, 0, 0, 0],
                    backgroundColor: [
                        '#c19421',
                        '#dc000f',
                        '#089636',
                        '#313634',
                    ],
                    borderColor: [
                        '#c19421',
                        '#dc000f',
                        '#089636',
                        '#313634',
                    ],
                    borderWidth: 1
                }]
            },
            moneyChart: {
                labels: ['Good', 'Bad', 'Great', 'NA'],
                datasets: [{
                    label: '# of Daily progress',
                    data: [0, 0, 0, 0],
                    backgroundColor: [
                        '#c19421',
                        '#dc000f',
                        '#089636',
                        '#313634',
                    ],
                    borderColor: [
                        '#c19421',
                        '#dc000f',
                        '#089636',
                        '#313634',
                    ],
                    borderWidth: 1
                }]
            },
            foodChart: {
                labels: ['Good', 'Bad', 'Great', 'NA'],
                datasets: [{
                    label: '# of Daily progress',
                    data: [0, 0, 0, 0],
                    backgroundColor: [
                        '#c19421',
                        '#dc000f',
                        '#089636',
                        '#313634',
                    ],
                    borderColor: [
                        '#c19421',
                        '#dc000f',
                        '#089636',
                        '#313634',
                    ],
                    borderWidth: 1
                }]
            },
        }
    }

    ChangeDate(event) {
        var target = event.target;
        window.setTimeout(() => {
            
            // var date = new Date(target.value);
            // var stringDate = target.value.replace("/", "-").replace("/", "-");
            this.setState({[target.name] : target.value})
        }, 200);
    }

    componentDidMount() {
        if (!window.localStorage.getItem('token')) {
            window.setTimeout(() => {
                this.props.navigate('/');
            }, 200);
            return;
        }

        window.$('#startDate').datepicker();
        window.$('#endDate').datepicker();
        // get today's date
        var date = new Date();
        var todayDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        this.state.todayDate = todayDate;
        var is_old_token_exist = false;
        this.GenerateLineChart();

        if (window.localStorage.getItem('account_details')) {

            var account_details = window.localStorage.getItem('account_details');
            var account_details = JSON.parse(window.localStorage.getItem('account_details'))

            let fitbit_token = account_details.fitbit_refresh_token ? account_details.fitbit_refresh_token : '';
            let fitbit_token_exist = false;
            if (account_details.fitbit_refresh_token) {
                fitbit_token_exist = true;
            }
            var startDate = this.state.startDate.replace("/", "-").replace("/", "-")
            var endDate = this.state.endDate.replace("/", "-").replace("/", "-")
            if (account_details.account_type === 'Care Taker' && account_details && account_details.fitbit_refresh_token) {
                fitbit_token_exist = account_details.fitbit_token_exist.id;
                fitbit_token = account_details.fitbit_refresh_token.id;
            }
            this.setState({fitbit_token, fitbit_token_exist})
            this.state.account_details = account_details
            this.FetchTreatmentLogs(startDate, endDate, fitbit_token, fitbit_token_exist);
            // check user type
            if (account_details.account_type) {

                this.setState({
                    userType: account_details.account_type,
                    account_details: account_details
                });
            }
        }
    }

    async FetchTreatmentLogs(fromDate, toDate, fitbit_token, fitbit_token_exist) {

        var analyticsTreatmentData = [];
        var cr_id ='';
        if (this.state.account_details && this.state.account_details.account_type === 'Care Taker') {
            cr_id = this.state.account_details.selected_care_receiver.id;
        }
        var analyticsTreatmentData = await HTTPRequest(Constant.BASE_URL + '/care-receiver/treatment-between-dates', 'application/json; charset=UTF-8', 'post', JSON.stringify({
            'from_date': fromDate,
            'to_date': toDate,
            'fitbit_token': fitbit_token,
            'fitbit_token_exist': fitbit_token_exist,
            'care_receiver_id': cr_id
        }));
        this.drawChart(analyticsTreatmentData.data, null)
        this.GenerateLineChart(analyticsTreatmentData.data);
    }

    drawChart(analyticsTreatmentData, event) {
        var homeChart = this.InitChartData(analyticsTreatmentData, 'Home');
        var wellnessChart = this.InitChartData(analyticsTreatmentData, 'Health');
        var foodChart = this.InitChartData(analyticsTreatmentData, 'Food');
        var moneyChart = this.InitChartData(analyticsTreatmentData, 'Finance');
        this.setState({homeChart, wellnessChart, foodChart, moneyChart})
    }

    InitChartData(analyticsTreatmentData, fetchData) {
        return {
            labels: ['Good', 'Bad', 'Great', 'NA'],
            datasets: [{
                label: '# of Daily progress',
                data: [analyticsTreatmentData[fetchData].GOOD, analyticsTreatmentData[fetchData].BAD, analyticsTreatmentData[fetchData].GREAT, analyticsTreatmentData[fetchData].NA],
                backgroundColor: [
                    '#c19421',
                    '#dc000f',
                    '#089636',
                    '#313634',
                ],
                borderColor: [
                    '#c19421',
                    '#dc000f',
                    '#089636',
                    '#313634',
                ],
                borderWidth: 1
            }]
        }
    }

    GenerateLineChart = (analyticsTreatmentData) => {

        let dates = [];
        let stepData = [];
        let sleepData = [];
        let newDatesData = [];
        let newStepData = [];
        let newSleepData = [];

        if (analyticsTreatmentData && analyticsTreatmentData.step_data.length > 0) {

            for (let i = 0; i < analyticsTreatmentData.step_data.length; i++) {

                // push current date's detail
                dates.push(analyticsTreatmentData.step_data[i].dateTime)
                stepData.push(analyticsTreatmentData.step_data[i].value)
                // stepData.push(randow_num)

                let sleep_data = analyticsTreatmentData.sleep_data.sleep;

                if (sleep_data > 0) {

                    for (let x = 0; x < sleep_data.length; x++) {
                        // check if sleep data is there for current date
                        if (analyticsTreatmentData.step_data[i].dateTime === sleep_data[i]['dateOfSleep']) {
                            // sleep data found | push data
                            sleepData.push(sleep_data[i]['duration'])
                        } else {
                            // sleep data not found
                            sleepData.push(0)
                        }
                    }
                } else {
                    // sleep data not found
                    sleepData.push(0)
                    // sleepData.push(randow_num2)
                }
            }
        } else {
            this.DefaultDrawLineChart(dates, sleepData, stepData)
        }
        newDatesData = ['x'].concat(dates)
        newStepData = ['total step'].concat(stepData)
        newSleepData = ['total sleep'].concat(sleepData)
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

        window.c3.generate({
            bindto: '#chart',
            data: {
                x: 'x',
                columns: [
                    newDatesData,
                    newStepData,
                    newSleepData
                ],
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: function (x) {
                            return (
                                x.getDate() + ' ' + monthNames[x.getMonth()]
                            );
                        },
                    }
                },
            }
        });
    }

    DefaultDrawLineChart(dates, sleepData, stepData) {
        var startDate = this.state.startDate.replace("/", "-").replace("/", "-")
        var endDate = this.state.endDate.replace("/", "-").replace("/", "-")
        var dt = new Date(startDate);
        var ed = new Date(endDate);
        var Difference_In_Time = ed.getTime() - dt.getTime();
        var days = Difference_In_Time / (1000 * 3600 * 24);
        for (let i = 0; i <= days; i++) {
            // 86400000
            var stringDate = dt.getFullYear() + '-'
                + ((dt.getMonth() + 1 > 9 ? (1 + dt.getMonth()) : ('0' + (dt.getMonth() + 1)))) + '-' +
                (dt.getDate() > 9 ? (dt.getDate()) : ('0' + dt.getDate()))
            dt.setTime(dt.getTime() + 86400000);
            dates.push(stringDate)
            stepData.push("0")
            sleepData.push("0")
            // stepData.push(Math.ceil(Math.random(100)*10))
            // sleepData.push(Math.ceil(Math.random(100)*10))
        }
    }

    render() {
        return (
            <>
                <div className={"row"} style={{marginTop: 20}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left'}}>
                        <p className="myProfileText">Select Dates</p>
                        <hr/>
                    </div>
                </div>
                <div className={"row"} style={{marginTop: 0}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left'}}>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>Start Date <span className='required'>*</span></label>
                                <input name='startDate' id="startDate" type="text" placeholder="Start Date"
                                       value={this.state.startDate}
                                    onFocus={this.ChangeDate.bind(this)}
                                    onChange={this.ChangeDate.bind(this)}
                                       className={"form-control"}/>
                                {/*{this.state.errors.required.email &&*/}
                                {/*<span className='error'>{this.state.errors.required.email}</span>}*/}
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>End Date <span className='required'>*</span></label>
                                <input name='endDate' id="endDate" type="text" placeholder="End Date"
                                       value={this.state.endDate}
                                    onFocus={this.ChangeDate.bind(this)}
                                    onChange={this.ChangeDate.bind(this)}
                                       className={"form-control"}/>
                                {/*{this.state.errors.required.email &&*/}
                                {/*<span className='error'>{this.state.errors.required.email}</span>}*/}
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 text-center">
                            <button
                                onClick={() => {
                                    console.log(this.state.startDate)
                                    console.log(this.state.endDate)
                                    var startDate = this.state.startDate.replace("/", "-").replace("/", "-")
                                    var endDate = this.state.endDate.replace("/", "-").replace("/", "-")
                                    this.FetchTreatmentLogs(startDate, endDate, this.state.fitbit_token, this.state.fitbit_token_exist);
                                }}
                                className={"btn-margin12 createTaskButton text-uppercase" + (window.innerWidth < 450 ? ' createTaskButton-special-iphone567' : '')} style={{marginTop: 25}}>
                                <span style={{paddingLeft: 10}}>Search</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={"row"} style={{marginTop: 10}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left', height: 60}}>
                        <p className="myProfileText">Daily Check in</p>
                        <hr/>
                    </div>
                </div>
                <div className='row' style={{marginTop: 0}}>
                    <div className='col-md-6 col-sm-12 col-xs-12' style={{paddingTop: 20}}>
                        <div className='card' style={{paddingTop: 0}}>
                            <div className="pink-gradient" style={{
                                textAlign: 'left',
                                height: 70,
                                borderTopRightRadius: 7,
                                borderTopLeftRadius: 7
                            }}>
                                <div style={{paddingTop: 15, paddingLeft: 20}}>
                                    <img src="/assets/images/icons/home.png" className="main-image"
                                         style={{height: 40}}/>
                                    <span className="myProfileText"
                                          style={{color: 'white', paddingLeft: 30, marginBotton: 0}}>Home</span>
                                </div>
                            </div>
                            <div className="" style={{textAlign: 'left'}}>
                                <div id="healthChart" style={{textAlign: 'center'}}>
                                    <Pie
                                        data={this.state.homeChart}
                                        option={{
                                            maintainAspcetRatio: false
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 col-xs-12' style={{paddingTop: 20}}>
                        <div className='card' style={{paddingTop: 0}}>
                            <div className="blue-gradient" style={{
                                textAlign: 'left',
                                height: 70,
                                borderTopRightRadius: 7,
                                borderTopLeftRadius: 7
                            }}>
                                <div style={{paddingTop: 15, paddingLeft: 20}}>
                                    <img src="/assets/images/icons/health.png" className="main-image"
                                         style={{height: 40}}/>
                                    <span className="myProfileText"
                                          style={{color: 'white', paddingLeft: 30, marginBotton: 0}}>Wellness</span>
                                </div>
                            </div>
                            <div className="" style={{textAlign: 'left'}}>
                                <div id="foodChart" style={{textAlign: 'center'}}>
                                    <div id="healthChart" style={{textAlign: 'center'}}>
                                        <Pie
                                            data={this.state.wellnessChart}
                                            option={{
                                                maintainAspcetRatio: false
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 col-xs-12' style={{paddingTop: 20}}>
                        <div className='card' style={{paddingTop: 0}}>
                            <div className="pitch-gradient" style={{
                                textAlign: 'left',
                                height: 70,
                                borderTopRightRadius: 7,
                                borderTopLeftRadius: 7
                            }}>
                                <div style={{paddingTop: 15, paddingLeft: 20}}>
                                    <img src="/assets/images/icons/finance.png" className="main-image"
                                         style={{height: 40}}/>
                                    <span className="myProfileText"
                                          style={{color: 'white', paddingLeft: 30, marginBotton: 0}}>Money</span>
                                </div>
                            </div>
                            <div className="" style={{textAlign: 'left'}}>
                                <div id="financeChart" style={{textAlign: 'center'}}>
                                    <div id="healthChart" style={{textAlign: 'center'}}>
                                        <Pie
                                            data={this.state.moneyChart}
                                            option={{
                                                maintainAspcetRatio: false
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 col-xs-12' style={{paddingTop: 20}}>
                        <div className='card' style={{paddingTop: 0}}>
                            <div className="orange-gradient" style={{
                                textAlign: 'left',
                                height: 70,
                                borderTopRightRadius: 7,
                                borderTopLeftRadius: 7
                            }}>
                                <div style={{paddingTop: 15, paddingLeft: 20}}>
                                    <img src="/assets/images/icons/food.png" className="main-image"
                                         style={{height: 40}}/>
                                    <span className="myProfileText"
                                          style={{color: 'white', paddingLeft: 30, marginBotton: 0}}>Food</span>
                                </div>
                            </div>
                            <div className="" style={{textAlign: 'left'}}>
                                <div id="homeChart" style={{textAlign: 'center'}}>
                                    <div id="healthChart" style={{textAlign: 'center'}}>
                                        <Pie
                                            data={this.state.foodChart}
                                            option={{
                                                maintainAspcetRatio: false
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"} style={{marginTop: 10}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'left'}}>
                        <p className="myProfileText">Fit bit</p>
                        <hr/>
                    </div>
                </div>
                <div className='row' style={{marginTop: 10, marginBottom: 30}}>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                        <div className='card'>
                            <div id="chart"></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProgressiveDashboardComponent;
