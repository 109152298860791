import React, {Component} from 'react';
import {Link} from '@reach/router'
import {CommonMethod, HTTPRequest} from "./common.service";
import Constant from "../constants";
import CountryCode from '../country.code';

class SignupCareAdminComponent extends Component {
    state = {
        email: '',
        firstName: '',
        lastName: '',
        organization: '',
        address: '',
        city: '',
        state: '',
        phoneNumber: '',
        countryCode: '+1',
        country: '',
        zipCode: '',
        password: '',
        c_password: '',
        deviceWidth: '',
        deviceHeight: '',
        errors: {
            countryCode: null
        },
        locationJSON: [],
        countries: [],
        states: [],
        cities: [],
        formName: {
            email: 'Email',
            firstName: 'Admin First Name',
            lastName: 'Admin Last Name',
            phoneNumber: 'Phone Number',
            countryCode: 'Country Code',
            organization: 'Organization',
            /*address: 'Address',
            city: 'City',
            state: 'State',
            country: 'Country',
            zipCode: 'Zip Code',*/
            password: 'Password',
            c_password: 'Confirm Password'
        },
        apiError: null,
        apiSuccess: null,
    };

    constructor(props) {
        console.log(CountryCode)
        super(props);
        if (window.localStorage.getItem('signUpData')) {
            this.state = JSON.parse(window.localStorage.getItem('signUpData'));
        }
    }

    Validate() {
        var flag = false;
        for (let key in this.state.formName) {
            if (key) {
                
                if (this.state.formName[key] && this.state.errors[key] !== null) {
                    const json = {};
                    json.errors = this.state.errors;
                    json.errors[key] = this.state.formName[key] + ' is required.';
                    this.setState(json);
                    flag = true;
                }
                if (!this.state[key]) {
                    const json = {};
                    json.errors = this.state.errors;
                    json.errors[key] = this.state.formName[key] + ' is required.';
                    this.setState(json);
                    flag = true;
                }
            }
        }
        return flag;
    }

    SaveSignUpData(event) {
        event.preventDefault();
        if (this.Validate()) {
            return;
        }
        window.localStorage.setItem('signUpData', JSON.stringify(this.state));
    }

    SignMeUp(event) {
        event.preventDefault();
        if (this.Validate()) {
            return;
        }
        if (this.state.password !== this.state.c_password) {
            return;
        }
        var body = {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            organization: this.state.organization,
            address: this.state.address,
            phoneNumber: this.state.countryCode + this.state.phoneNumber,
            realPhone: this.state.phoneNumber,
            countryCode: this.state.countryCode,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            zipCode: this.state.zipCode,
            password: this.state.password,
        }
        this.SignUpService(JSON.stringify(body)).then((data) => {
            
            this.setState({
                apiSuccess: data.message
            });
            this.setState({
                email: '',
                firstName: '',
                lastName: '',
                organization: '',
                address: '',
                city: '',
                phoneNumber: '',
                state: '',
                country: '',
                zipCode: '',
                password: ''
            })
            
            if (data.data.token) {
                window.localStorage.setItem('account_details', JSON.stringify(data.data))
                window.localStorage.setItem('token', data.data.token);
            }
            this.props.navigate('/auth/care-admin/dashboard');
            console.log(data);
            if (window.ga) {
                window.ga('send', 'event', 'Signup', 'success', 'No-Auth');
            }
        }).catch(err => {
            
            CommonMethod();
            if (err && err.message && err.message === 'Failed to fetch') {
                this.setState({
                    apiError: 'Failed to connect with server.'
                });
            } else {
                this.setState({
                    apiError: err && err.message ? err.message : err
                });
            }
            console.log(err);
            if (window.ga) {
                window.ga('send', 'event', 'Signup', 'error', 'No-Auth');
            }
        });
    }

    componentDidMount() {
        CommonMethod();
        this.FetchLocation();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        var input = window.document.getElementById('phoneNumber');
        input.onkeydown = (e) => {
            var k = e.which;
            /* numeric inputs can come from the keypad or the numeric row at the top */
            if ( (k < 48 || k > 66) && (k < 96 || k > 105) && k !== 8 && k !== 17 && k !== 37 && k !== 39) {
                e.preventDefault();
                return false;
            }
        }
    }

    updateDimensions() {
        this.setState({
            deviceWidth: window.innerWidth,
            deviceHeight: window.innerHeight,
        })
    }

    ValidateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    CheckPhone(phone) {
        var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return phone.match(phoneNum);
    }

    ChangeEvent(event) {
        const json = {};
        const val = this.CheckErrors(event.target.name, event.target.value);
        if (val) {
            json['errors'] = {}
            json.errors = this.state.errors;
            json.errors[event.target.name] = val[event.target.name];
        } else {
            json['errors'] = {}
            json.errors = this.state.errors;
            json.errors[event.target.name] = null;
        }
        if (this.state.apiError || this.state.apiSuccess) {
            json.apiError = null;
            json.apiSuccess = null;
        }
        /*if (event.target.name === 'phoneNumber' && event.target.value) {
            if (!this.CheckPhone(event.target.value)) {
                json.errors[event.target.name] = 'Please provide proper phonenumber.';
            }
        }*/
        if (event.target.name === 'email' && event.target.value) {
            if (!this.ValidateEmail(event.target.value)) {
                json.errors[event.target.name] = 'Please provide proper email.';
            }
        }
        if (event.target.name === 'phoneNumber' && event.target.value) {
            event.target.value = parseInt(event.target.value);
            var k = event.which;
            /* numeric inputs can come from the keypad or the numeric row at the top */
            if ((k < 48 || k > 57) && (k < 96 || k > 105)) {
                json.errors[event.target.name] = 'Please provide proper phonenumber.';
            }
            /*if (isNaN(event.target.value)) {
                json.errors[event.target.name] = 'Please provide proper phonenumber.';
            }*/
        }
        if (event.target.name === 'c_password' || event.target.name === 'password') {
            if (event.target.value !== this.state.password) {
                json.errors['c_password'] = 'Password and confirm password must match.';
            }
        }
        json[event.target.name] = event.target.value;
        this.setState(json);
    }

    CheckErrors(targetName, targetValue) {
        if (!(targetValue || targetValue === 0)) {
            return {[targetName]: this.state.formName[targetName] + ' is required.'};
        }
    }

    async FetchLocation() {
        var locationJSON = await HTTPRequest(Constant.BASE_URL + '/location', '', 'get');
        locationJSON = locationJSON.Countries;
        var countries = [];
        var cities = [];
        var states = [];
        /*var citylist = 0;*/
        for (let i = 0; i < locationJSON.length; i++) {
            countries.push(locationJSON[i].CountryName);
            var state = locationJSON[i].States;
            for (let j = 0; j < state.length; j++) {
                states.push(state[j].StateName);
                var city = state[j].Cities;
                if (city.length === 0) {
                    locationJSON[i].States[j].Cities.push(state[j].StateName);
                    cities.push(state[j].StateName);
                } else {
                    for (let k = 0; k < city.length; k++) {
                        cities.push(city[k]);
                        /*citylist++;*/
                    }
                }
            }
        }
        this.setState({countries, cities, states, locationJSON});
        this.autocomplete(window.document.getElementById('country'), this.state.countries)
        this.autocomplete(window.document.getElementById('city'), this.state.cities)
        this.autocomplete(window.document.getElementById('state'), this.state.states)
    }

    SetState() {
        if (this && this.state) {
            for (let i = 0; i < this.state.locationJSON.length; i++) {
                var state = this.state.locationJSON[i].States;
                for (let j = 0; j < state.length; j++) {
                    var city = state[j].Cities;
                    for (let k = 0; k < city.length; k++) {
                        if (city[k] === this.state.city) {
                            this.setState({state: state[j].StateName, country: this.state.locationJSON[i].CountryName});
                            return true;
                        }
                    }
                }
            }
        }
    }

    render() {
        return (
            <div className="row">
                <div className="signup-block">
                    <div className="row text-center"
                         style={{marginLeft: 0, marginRight: 0, paddingLeft: 20, paddingRight: 20}}>
                        <div>
                            <h4>Let us now setup your Care Admin Sahaaya Account.</h4>
                        </div>
                        {/* <div>
                            <ul className="center-block">
                                <li>
                                    1. Setup Primary Care Giver Account
                                </li>
                                <li>
                                    2. Add Care Receiver(s)
                                </li>
                                <li>
                                    3. Select Your Preferred Plan
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="row" style={{marginLeft: 0, marginRight: 0}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            {/* <div className="stepwizard">
                                <div className="stepwizard-row setup-panel">
                                    <div className="stepwizard-step">
                                        <Link to='/signup' type="button" className="btn btn-primary btn-circle">1</Link>
                                    </div>
                                    <div className="stepwizard-step">
                                        <a href="javascript:void(0)" type="button"
                                           className="btn btn-default btn-circle"
                                           disabled="disabled">2</a>
                                    </div>
                                    <div className="stepwizard-step">
                                        <a href="javascript:void(0)" type="button"
                                           className="btn btn-default btn-circle"
                                           disabled="disabled">3</a>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="stepwizard">
                                <div className="stepwizard-row setup-panel">
                                    <div className="stepwizard-step">
                                        <span className="float-left">Setup Primary Care Giver Account</span>
                                    </div>
                                    <div className="stepwizard-step">
                                        <span className="float-left">Add Care Receiver(s)</span>
                                    </div>
                                    <div className="stepwizard-step">
                                        <span className="float-left">Select Your Preferred Plan</span>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="">
                        <div className="col-md-4"></div>
                        <div className="col-sm-12 col-md-4">
                            <div className="signup-form-cat">
                                <form className="signup-form">
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <h2 className='text-center' style={{color: '#004265'}}>Sign Up | Care Admin</h2>
                                        </div>
                                    </div>
                                    {this.state.apiError &&
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.apiError}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {this.state.apiSuccess &&
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className="alert alert-success" role="alert">
                                                {this.state.apiSuccess}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Admin First Name <span className='required'>*</span></label>
                                                <input name="firstName" type="text" placeholder="Admin First Name"
                                                       value={this.state.firstName}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.firstName ? ' error-border' : '')}/>
                                                {this.state.errors.firstName &&
                                                <span className='error'>{this.state.errors.firstName}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Admin Last Name <span className='required'>*</span></label>
                                                <input name="lastName" type="text" placeholder="Admin Last Name"
                                                       value={this.state.lastName}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.lastName ? ' error-border' : '')}/>
                                                {this.state.errors.lastName &&
                                                <span className='error'>{this.state.errors.lastName}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Email <span className='required'>*</span></label>
                                                <input name="email" type="email" placeholder="mail@email.com"
                                                       value={this.state.email}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.email ? ' error-border' : '')}/>
                                                {this.state.errors.email &&
                                                <span className='error'>{this.state.errors.email}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Password <span className='required'>*</span></label>
                                                <input name="password" type="password" placeholder="Password"
                                                       value={this.state.password}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.password ? ' error-border' : '')}/>
                                                {this.state.errors.password &&
                                                <span className='error'>{this.state.errors.password}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Confirm Password <span className='required'>*</span></label>
                                                <input name="c_password" type="password" placeholder="Confirm Password"
                                                       value={this.state.c_password}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.c_password ? ' error-border' : '')}/>
                                                {this.state.errors.c_password &&
                                                <span className='error'>{this.state.errors.c_password}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Organization <span className='required'>*</span></label>
                                                <input name="organization" type="text" placeholder="Organization"
                                                       value={this.state.organization}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.organization ? ' error-border' : '')}/>
                                                {this.state.errors.organization &&
                                                <span className='error'>{this.state.errors.organization}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Country Code <span className='required'>*</span></label>
                                                <select name="countryCode" onChange={this.ChangeEvent.bind(this)}
                                                        style={{background: 'rgba(0, 0, 0, 0.1)'}}
                                                        value={this.state.countryCode}
                                                        className={"form-control" + (this.state.errors.countryCode ? ' error-border' : '')}>
                                                    {
                                                        CountryCode.map((item, index) => {
                                                            return (<option key={index}
                                                                            value={item.dial_code}>{item.name} {item.dial_code}</option>)
                                                        })
                                                    }
                                                </select>
                                                {/*<input name="phoneNumber" type="tel" placeholder="Phone Number"
                                                       value={this.state.countryCode}
                                                       maxLength="4"
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control" + (this.state.errors.countryCode ? ' error-border' : '')}/>*/}
                                                {this.state.errors.countryCode &&
                                                <span className='error'>{this.state.errors.countryCode}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Phone Number <span className='required'>*</span></label>
                                                <input name="phoneNumber" type="number" placeholder="Phone Number"
                                                       value={this.state.phoneNumber}
                                                       id='phoneNumber'
                                                       maxLength="15"
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       // onInput={(e) => {
                                                       //     var k = e.which;
                                                       //     if ( (k < 48 || k > 66) && (k < 96 || k > 105) && k !== 8 && k !== 17) {
                                                       //         e.preventDefault();
                                                       //         return false;
                                                       //     }
                                                       //     // e.target.value = Math.max(0, parseFloat(e.target.value)).toString().slice(0, 10)
                                                       // }}
                                                       className={"form-control" + (this.state.errors.phoneNumber ? ' error-border' : '')}/>
                                                {this.state.errors.phoneNumber &&
                                                <span className='error'>{this.state.errors.phoneNumber}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Address </label>
                                                <input name="address" type="text" placeholder="Address"
                                                       value={this.state.address}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                    // className={"form-control" + (this.state.errors.address ? ' error-border' : '')}/>
                                                       className={"form-control"}/>
                                                {/*{this.state.errors.address &&
                                                <span className='error'>{this.state.errors.address}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>City </label>
                                                <input id="city" name="city" type="text" placeholder="City"
                                                       value={this.state.city}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       onBlur={this.SetState.bind(this)}
                                                    // className={"form-control" + (this.state.errors.city ? ' error-border' : '')}/>
                                                       className={"form-control"}/>
                                                {/*{this.state.errors.city &&*/}
                                                {/*<span className='error'>{this.state.errors.city}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>State </label>
                                                <input id="state" name="state" type="text" placeholder="State"
                                                       value={this.state.state}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control"}/>
                                                {/*className={"form-control" + (this.state.errors.state ? ' error-border' : '')}/>*/}
                                                {/*{this.state.errors.state &&*/}
                                                {/*<span className='error'>{this.state.errors.state}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Country </label>
                                                <input id="country" name="country" type="text" placeholder="Country"
                                                       value={this.state.country}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control"}/>
                                                {/*className={"form-control" + (this.state.errors.country ? ' error-border' : '')}/>*/}
                                                {/*{this.state.errors.country &&*/}
                                                {/*<span className='error'>{this.state.errors.country}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Zip code </label>
                                                <input name="zipCode" type="text" placeholder="Zip code"
                                                       value={this.state.zipCode}
                                                       onFocus={this.ChangeEvent.bind(this)}
                                                       onChange={this.ChangeEvent.bind(this)}
                                                       className={"form-control"}/>
                                                {/*className={"form-control" + (this.state.errors.zipCode ? ' error-border' : '')}/>*/}
                                                {/*{this.state.errors.zipCode &&*/}
                                                {/*<span className='error'>{this.state.errors.zipCode}</span>}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row right-block text-center">
                                        {/* <div className="col-md-4 col-sm-4 col-xs-4 custombutton"></div> */}
                                        {/* <div className="col-md-4 col-sm-4 col-xs-4 custombutton">
                                            <button onClick={this.SaveSignUpData.bind(this)} className="btn">Save</button>
                                        </div>*/}
                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                            <button onClick={this.SignMeUp.bind(this)}
                                                    className="btn full-button">Sign Up
                                            </button>
                                            {/*<Link to="/add-care-receiver" className="btn navigation-btn">
                                                Add Care Receiver
                                            </Link>*/}
                                        </div>
                                    </div>
                                    <p className="text-center">Already have an account? &nbsp; &nbsp;
                                        <Link to='/login'>
                                            Login
                                        </Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

    SignUpService(data) {
        return HTTPRequest(Constant.BASE_URL + '/signup/ca', 'application/json; charset=UTF-8', 'post', data);
    }

    autocomplete(inp, arr) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) {
                return false;
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = window.document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = window.document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                        inp.focus();
                        window.setTimeout(() => {
                            inp.blur();
                        }, 10)
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = window.document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode === 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode === 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode === 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }

        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }

        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = window.document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt !== x[i] && elmnt !== inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }

        /*execute a function when someone clicks in the document:*/
        window.document.addEventListener('click', function (e) {
            closeAllLists(e.target);
        });
    }
}

export default SignupCareAdminComponent;
